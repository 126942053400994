import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
// import Container from 'react-bootstrap/Container'
import { language } from '../language';
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
import { updatePayment, showPlayerPaymentFNC, getAgentWorkerListFNC } from '../Api'
import moment from 'moment-timezone';
import { exportActionExcel } from '../Component/jsonToXlsx'
import { tableName, actionName } from '../Component/static'
var timer 

class PlayerConfirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
      AllSelectedIDs: [],
      FilterType: 'All',
      actionListAll: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList: agentList, agentObj }, () => {
        this.getPendingConfirmData()
      });
    }
  }

  async getPendingConfirmData() {
    let getPenCData;
    getPenCData = await showPlayerPaymentFNC(this.props.username, this.props.token);
    console.log('getPenCData', getPenCData)
    if (getPenCData) {
      this.setState({ actionList: getPenCData, loading: false, actionListAll: getPenCData });
    } else {
      this.setState({ actionList: [], loading: false });
    }
  }

  async initPage() {
    // const getActionData = await getActionList(this.props.username, this.props.token, this.state.fromDate, this.state.toDate, this.state.fromAmount, this.state.toAmount);
    // console.log('getActionData', getActionData)
    this.setState({
      actionList: [],
      loading: false,
    });
  }

  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.getPendingConfirmData())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value}, () => { this.getPendingConfirmData(); })
  }

  exportExcel() {
    // if (this.state.filterAgent !== '') {
    //   const filterArray = this.state.actionList.filter((item) => item.User === this.state.filterAgent)
    //   exportActionExcel(filterArray, this.state.agentObj)
    // } else {
    exportActionExcel(this.state.actionList, this.state.agentObj, {fromDate: this.state.fromDate, toDate: this.state.toDate}, this.state.filterAgent)
    // }
  }

  SelectedReAllID(SelectedID, e) {
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    if (SelectedID === 'All') {
      const actionList = this.state.actionList
      let ArrayID = []
      if (e.target.checked) {
        actionList.forEach((items) => {
          ArrayID.push(items.ID)
        })
        this.setState({ SelectedAll: true })
      } else {
        ArrayID = []
        this.setState({ SelectedAll: false })
      }
      this.setState({ AllSelectedIDs: ArrayID })
    } else {
      if(AllSelectedIDs.includes(SelectedID)) {
        AllSelectedIDs = AllSelectedIDs.filter(function (el) {
          return el !== SelectedID
        });
      } else {
        AllSelectedIDs.push(SelectedID)
      }
      this.setState({ AllSelectedIDs })
    }
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  async ConfirmPayment(type, SelectedID) {
    const AllSelectedIDs = this.state.AllSelectedIDs
    if (type === 'Multi') {
      if (AllSelectedIDs.length > 0) {
        const updatePayments = await updatePayment(AllSelectedIDs, this.props.username, this.props.token)
        if (updatePayments.message === 'Update Success') {
          this.DisplayMEssage('成功确认', 'Success');
          this.getPendingConfirmData()
        }
      }
    } else {
      const updatePayments = await updatePayment(SelectedID, this.props.username, this.props.token)
      if (updatePayments.message === 'Update Success') {
        this.DisplayMEssage('成功确认', 'Success');
        this.getPendingConfirmData()
      }
    }
  }

  FilterTableView(value, type) {
    let actionListAll = this.state.actionListAll
    if (value === 'All') {
      this.setState({ actionList: actionListAll, [type]: value })
    } else {
      const Filtered = actionListAll.filter((values) => values.Type === value);
      this.setState({ actionList: Filtered, [type]: value })
    }
  }

  CalculateTotal(type) {
    let userArray = this.state.actionList
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[type])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  render() {
    return (
      <div fluid style={{ fontSize: '14px'}}>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 60, minHeight: '100vh' }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>用户确认</h2>
              </div>
              <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
                {this.state.MessageType === 'Error' && 
              <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
                }
                {this.state.MessageType === 'Success' && 
              <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
                }
                <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                {/* <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ marginRight: 10}}>日期: </div>
                  <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                  <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                  <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
                </div> */}
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <Button style={{ minWidth: 80, marginRight: 5 }} onClick={() => this.ConfirmPayment('Multi', '')} variant="outline-success">Multi Confirm</Button>
                </div>
              </div>
            </div>
            <div className="dcc" style={{ width: '95%', marginBottom: 50 }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", border: "1px solid #e6e6e6", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex',padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'ID'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].DateTime}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].StaffMember}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'AddedBy'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Amount}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* {language[this.props.currentLanguage].Type} */}
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterType} onChange={(e) => this.FilterTableView(e.target.value, 'FilterType')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Type}`}</option>
                              <option value={'Cash'}>{'Cash'}</option>
                              <option value={'cheque'}>{'cheque'}</option>
                            </select>
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'结果'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Approved By'}
                          </div>
                          <div className="dcc resulttableTitle" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100, color: 'white' }}>
                            {'Action'}
                            <Form.Check
                              style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                              type="checkbox"
                              // checked={this.state.AllSelectedIDs.includes(items.ID)}
                              onChange={(e) => this.SelectedReAllID('All', e)}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.actionList.length > 0 && 
                      <>
                        {this.state.actionList.map((items, iddn) => {
                          return(
                            <div className='dcc' key={iddn} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (iddn%2) ? '#F1F1F1' : 'white' }}>
                              <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ID}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {moment(items.DateTime).format('YYYY-MM-DD')}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {this.state.agentObj[items.Worker]}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.AddedBy}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Amount.toLocaleString()}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Type}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApprovalStatus}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApproveBy}
                                </div>
                                <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100}}>
                                  <button style={{ marginRight: 5 }} onClick={() => this.ConfirmPayment('Self', [items.ID])}>确认</button>
                                  <Form.Check
                                    style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                                    type="checkbox"
                                    checked={this.state.AllSelectedIDs.includes(items.ID)}
                                    onChange={(e) => this.SelectedReAllID(items.ID, e)}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                      }
                      {this.state.actionList.length === 0 && 
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                        <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                          {'No Data'}
                        </div>
                      </div>
                      }
                      {this.state.actionList.length > 0 && 
                        <div className='dcc headerColorTable' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontWeight: 'bold' }}>
                              {'Total'}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {this.CalculateTotal('Amount')}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontWeight: 'bold' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100 }}>
                              {''}
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PlayerConfirm);
  