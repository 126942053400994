import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
// import Container from 'react-bootstrap/Container'
import { language } from '../language';
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
import { getAgentWorkerListFNC, showPenCfmPaymentFNC, triggerNotificationFNC } from '../Api'
import moment from 'moment-timezone';
import Modal from 'react-bootstrap/Modal'
// import { exportActionExcel } from '../Component/jsonToXlsx'
import { exportActionExcel } from '../jsonToXlsx' 
import { tableName, actionName } from '../Component/static'
var timer 

class ActionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
      AllSelectedIDs: [],
      FilterType: 'All',
      actionListAll: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      OpenDetailsModal: false,
      SelectedIDwithPayment: [],
    }
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList, agentObj });
      this.getPendingConfirmData()
    }
  }

  async getPendingConfirmData() {
    let getPenCData;
    if(this.state.filterAgent === ''){ 
      getPenCData = await showPenCfmPaymentFNC(this.props.username, 'All', moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.state.page, this.state.countperpage, this.props.token);
    } else {
      getPenCData = await showPenCfmPaymentFNC(this.props.username, this.state.filterAgent, moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.state.page, this.state.countperpage, this.props.token);
    }
    if (getPenCData) {
      this.setState({ actionList: getPenCData, loading: false, actionListAll: getPenCData });
      if (getPenCData.length > 0) {
        this.setState({
          countpagemax: Math.ceil(
            getPenCData[0].FullCount / this.state.countperpage,
          ),
        });
      } else {
        this.setState({
          countpagemax: 1,
          page: 1,
        });
      }
    } else {
      this.setState({ actionList: [], loading: false });
    }
  }

  changePage() {
    this.setState({ actionList: [], actionListAll: [], FilterType: 'All' }, () => {
      this.getPendingConfirmData();
    });
  }

  async initPage() {
    // const getActionData = await getActionList(this.props.username, this.props.token, this.state.fromDate, this.state.toDate, this.state.fromAmount, this.state.toAmount);
    // console.log('getActionData', getActionData)
    this.setState({
      actionList: [],
      loading: false,
    });
  }

  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.getPendingConfirmData())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value}, () => { this.getPendingConfirmData(); })
  }

  async exportExcel() {
    let getPenCData = ''
    if(this.state.filterAgent === ''){ 
      getPenCData = await showPenCfmPaymentFNC(this.props.username, 'All', moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), 1, 100000, this.props.token);
    } else {
      getPenCData = await showPenCfmPaymentFNC(this.props.username, this.state.filterAgent, moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), 1, 100000, this.props.token);
    }
    exportActionExcel(getPenCData, this.state.agentObj, {fromDate: this.state.fromDate, toDate: this.state.toDate}, this.state.filterAgent)
  }

  SelectedReAllID(SelectedID, e, withpaymentID) {
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    let SelectedIDwithPayment = JSON.parse(JSON.stringify(this.state.SelectedIDwithPayment))
    if (SelectedID === 'All') {
      const actionList = this.state.actionList
      let ArrayID = []
      let ArrayII = []
      if (e.target.checked) {
        actionList.forEach((items) => {
          ArrayID.push(items.ID)
          ArrayII.push({PaymentID: items.ID, Name: items.Client })
        })
        this.setState({ SelectedAll: true })
      } else {
        ArrayID = []
        this.setState({ SelectedAll: false })
      }
      this.setState({ AllSelectedIDs: ArrayID, SelectedIDwithPayment: ArrayII })
    } else if (SelectedID === 'AllDetails') {
    } else {
      if(AllSelectedIDs.includes(SelectedID)) {
        AllSelectedIDs = AllSelectedIDs.filter(function (el) {
          return el !== SelectedID
        });
      } else {
        AllSelectedIDs.push(SelectedID)
      }
      let checker = false
      SelectedIDwithPayment.forEach((items) => {
        if (items.PaymentID === SelectedID) {
          checker = true
        }
      })
      // console.log('checker', checker)
      if (checker === true) {
        SelectedIDwithPayment = SelectedIDwithPayment.filter(function (el) {
          return el.PaymentID !== SelectedID
        });
      } else {
        SelectedIDwithPayment.push(withpaymentID)
      }
      this.setState({ AllSelectedIDs, SelectedIDwithPayment })
    }
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  async SendNotifications(type, SelectedID, withName) {
    const AllSelectedIDs = this.state.AllSelectedIDs
    if (type === 'Multi') {
      if (AllSelectedIDs.length > 0) {
        const SendNoti = await triggerNotificationFNC(this.props.username, this.props.token, this.state.SelectedIDwithPayment)
        if(SendNoti.message === 'Notification Sended') {
          this.DisplayMEssage('成功发送所有信息', 'Success');
          this.setState({ AllSelectedIDs: [], SelectedIDwithPayment: [] })
          this.getPendingConfirmData();
        }
      }
    } else {
      this.setState({ SelectedIDwithPayment: withName }, async() => {
        const SendNoti = await triggerNotificationFNC(this.props.username, this.props.token, this.state.SelectedIDwithPayment)
        if(SendNoti.message === 'Notification Sended') {
          this.DisplayMEssage('成功发送信息', 'Success');
          this.setState({ AllSelectedIDs: [], SelectedIDwithPayment: [] })
          this.getPendingConfirmData();
        }
      })
    }
  }

  FilterTableView(value, type) {
    let actionListAll = this.state.actionListAll
    if (value === 'All') {
      this.setState({ actionList: actionListAll, [type]: value })
    } else {
      const Filtered = actionListAll.filter((values) => values.Type === value);
      this.setState({ actionList: Filtered, [type]: value })
    }
  }

  CalculateTotal(Totaltype) {
    let userArray = this.state.actionList
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  DisplayDetials() {
    this.setState({
      OpenDetailsModal: !this.state.OpenDetailsModal,
    })
  }

  handleClose() {
    this.setState({ OpenDetailsModal: false })
  }

  render() {
    return (
      <div fluid style={{ fontSize: '14px'}}>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5 , minHeight: '100vh', paddingTop: 60 }}>
            {/* {this.state.OpenDetailsModal && 
            <Modal centered show={this.state.OpenDetailsModal} size="xl" onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ marginRight: 10, maxHeight: '70vh', minHeight: '70vh', justifyContent: 'center', display: 'flex'}}>
                  <div style={{ width: '98%', display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', marginBottom: 40, padding: 10, minHeight: '60vh', maxHeight: '60vh' }}>
                    <div style={{ width: '100%', borderBottom: '1px solid #4A5874', padding: 5, backgroundColor: '#4A5874', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
                      <div style={{ width: 'calc(100%)'}}>Created Date</div>
                      <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                      <div style={{ width: 'calc(100%)'}}>PO.No</div>
                      <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                      <div style={{ width: 'calc(100%)'}}>Location</div>
                      <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                      <div style={{ width: 'calc(100%)'}}>Status</div>
                      <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row'}}>
                        <div>Action</div>
                        <Form.Check
                          style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                          type="checkbox"
                          checked={this.state.AllSelectedIDs.length === this.state.actionList.length}
                          onChange={(e) => this.SelectedReAllID('AllDetails', e)}
                        />
                      </div>
                    </div>
                    <div className='scrollhost' style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: 'auto', overflowX: 'auto'}}>
                      <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 'calc(100%)'}}>Created Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.No</div>
                        <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                        <div style={{ width: 'calc(100%)'}}>Location</div>
                        <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                        <div style={{ width: 'calc(100%)'}}>Status</div>
                        <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                          <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.SendNotifications('Self', ['123'])}>Send Noti</div>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            type="checkbox"
                            checked={this.state.AllSelectedIDs.includes(123)}
                            onChange={(e) => this.SelectedReAllID(123, e)}
                          />
                        </div>
                      </div>
                      <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 'calc(100%)'}}>Created Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.No</div>
                        <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                        <div style={{ width: 'calc(100%)'}}>Location</div>
                        <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                        <div style={{ width: 'calc(100%)'}}>Status</div>
                        <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                          <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.SendNotifications('Self', ['123'])}>Send Noti</div>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            type="checkbox"
                            checked={this.state.AllSelectedIDs.includes(123)}
                            onChange={(e) => this.SelectedReAllID(123, e)}
                          />
                        </div>
                      </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 'calc(100%)'}}>Created Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.No</div>
                        <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                        <div style={{ width: 'calc(100%)'}}>Location</div>
                        <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                        <div style={{ width: 'calc(100%)'}}>Status</div>
                        <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                          <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.SendNotifications('Self', ['123'])}>Send Noti</div>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            type="checkbox"
                            checked={this.state.AllSelectedIDs.includes(123)}
                            onChange={(e) => this.SelectedReAllID(123, e)}
                          />
                        </div>
                      </div><div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <div style={{ width: 'calc(100%)'}}>Created Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.Date</div>
                        <div style={{ width: 'calc(100%)'}}>PO.No</div>
                        <div style={{ width: 'calc(100%)'}}>Vender Name</div>
                        <div style={{ width: 'calc(100%)'}}>Location</div>
                        <div style={{ width: 'calc(100%)'}}>Suntotal</div>
                        <div style={{ width: 'calc(100%)'}}>Status</div>
                        <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                          <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.SendNotifications('Self', ['123'])}>Send Noti</div>
                          <Form.Check
                            style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                            type="checkbox"
                            checked={this.state.AllSelectedIDs.includes(123)}
                            onChange={(e) => this.SelectedReAllID(123, e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
              </Modal.Footer>
            </Modal>
            } */}
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>活动</h2>
                <div style={{ marginRight: 10 }}>Admin: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value=''>全部</option>
                  {this.state.agentList.map((item) => 
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
                {this.state.MessageType === 'Error' && 
              <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
                }
                {this.state.MessageType === 'Success' && 
              <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
                }
                <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ marginRight: 10 }}>日期: </div>
                  <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                  <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                  <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row'}}>
                  <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150, marginRight: 5 }} onClick={() => this.SendNotifications('Multi', '')}>{'Send Multi Noti'}</div>
                  <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150 }} onClick={() => this.exportExcel()}>{'Excel'}</div>
                </div>
              </div>
            </div>
            {/* <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>日期时间</th>
                  <th>Admin</th>
                  <th>客仔</th>
                  <th>活动</th>
                  <th>细节</th>
                  <th>类别</th>
                  <th>Key</th>
                  <th>从</th>
                  <th>结果</th>
                </tr>
              </thead>
              <tbody>
                {this.state.actionList.map((item, index) => (this.state.filterAgent === '' || (this.state.filterAgent !== '' && this.state.filterAgent === item.UserID)) && <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{item.UserName}</td>
                  <td>{item.ClientName}</td>
                  <td>{actionName[item.Action]}</td>
                  <td>{item.Details}</td>
                  <td>{tableName[item.Table]}</td>
                  <td>{item.Key}</td>
                  <td>{item.FromValue}</td>
                  <td>{item.ToValue}</td>
                </tr>)}
              </tbody>
            </Table> */}
            <div className="dcc" style={{ width: '95%', marginBottom: 50, backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex',padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'ID'}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].DateTime}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Guest}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].StaffMember}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'AddedBy'}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Amount}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {/* {language[this.props.currentLanguage].Type} */}
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterType} onChange={(e) => this.FilterTableView(e.target.value, 'FilterType')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Type}`}</option>
                              <option value={'Cash'}>{'Cash'}</option>
                              <option value={'cheque'}>{'cheque'}</option>
                            </select>
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'结果'}
                          </div>
                          <div className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Approved By'}
                          </div>
                          <div className="dcc resulttableTitle" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100, color: 'white' }}>
                            {'Action'}
                            <Form.Check
                              style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                              type="checkbox"
                              checked={this.state.AllSelectedIDs.length === this.state.actionList.length}
                              onChange={(e) => this.SelectedReAllID('All', e)}
                            />
                          </div>
                        </div>
                      </div>
                      {this.state.actionList.length > 0 &&
                      <>
                        {this.state.actionList.map((items, iddn) => {
                          return(
                            <div className='dcc' key={iddn} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (iddn%2) ? '#F1F1F1' : 'white' }}>
                              <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                                <div className="dcc resulttable" style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  <div style={{ width: 'calc(100%)' }}>{items.ID}</div>
                                  {/* <div className='POButton' style={{ borderRadius: 100, backgroundColor: '#4A5874', paddingLeft: 5, paddingRight: 5, width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.DisplayDetials()}>Details</div> */}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {moment(items.DateTime).format('YYYY-MM-DD')}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Client}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {this.state.agentObj[items.Worker]}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.AddedBy}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Amount.toLocaleString()}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Type}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApprovalStatus}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApproveBy}
                                </div>
                                <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100}}>
                                  {/* <button style={{ marginRight: 5 }} onClick={() => this.SendNotifications('Self', [items.ID], {PaymentID: items.ID, Name: items.Client})}>Send Noti</button> */}
                                  <div className='dcc POButton'
                                    style={{ borderRadius: 100, backgroundColor: 'black', paddingLeft: 5, paddingRight: 5, width:'auto', cursor: 'pointer' }}
                                    onClick={() => this.SendNotifications('Self', [items.ID], {PaymentID: items.ID, Name: items.Client})}>
                                    {language[this.props.currentLanguage].SendNoti}
                                  </div>
                                  <Form.Check
                                    style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                                    type="checkbox"
                                    checked={this.state.AllSelectedIDs.includes(items.ID)}
                                    onChange={(e) => this.SelectedReAllID(items.ID, e, {PaymentID: items.ID, Name: items.Client})}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
                      }
                      <>
                        {/* {this.state.paymentList.length > 0 && this.state.paymentList.map((item, index) => {
                          return(
                            <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                              <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.ID}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Client}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {this.state.agentObj[item.Worker]}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Amount}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Type}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.ApprovalStatus}
                                </div>
                                <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100}}>
                                  <Button disabled={item.ApprovalStatus === 'Pending Confirm'} onClick={() => this.setState({ConfirmModal: true, SendID: item.ID,UserIDandDate: `ID ${item.ID} ${language[this.props.currentLanguage].DateTime} ${moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}`})} variant="primary">Confirm</Button>
                                </div>
                              </div>
                            </div>
                          );
                        })} */}
                      </>
                      {this.state.actionList.length === 0 && 
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                        <div style={{ width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                          {'No Data'}
                        </div>
                      </div>
                      }
                      {this.state.actionList.length > 0 && 
                        <div className='dcc headerColorTable' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                          <div style={{ width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontWeight: 'bold' }}>
                              {'Total'}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', fontWeight: 'bold' }}>
                              {this.CalculateTotal('Amount')}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {''}
                            </div>
                            <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100 }}>
                              {''}
                            </div>
                          </div>
                        </div>
                      }
                      <div>
                        <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          {this.state.page > this.state.countpagemin ? (
                            <button  style={{ textAlign: 'center',backgroundColor: '#4A5874', color: 'white', width: 100, borderColor: '#4A5874' }} 
                              class="btn my-cusomized-button button2" 
                              onClick={() =>
                                this.setState(
                                  {
                                    page: this.state.page - 1,
                                  },
                                  () => {
                                    this.changePage(this.state.page);
                                  },
                                )
                              }>
                              {'<<'}
                            </button>
                          ) : (
                            <div style={{ width: 100 }}/>
                          )}
                          <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
                          {this.state.page < this.state.countpagemax ? (
                            <button
                              style={{ textAlign: 'center', backgroundColor: '#4A5874', color: 'white', width: 100, borderColor: '#4A5874' }}
                              class="btn my-cusomized-button button2" 
                              onClick={() =>
                                this.setState(
                                  {
                                    page: this.state.page + 1,
                                  },
                                  () => {
                                    this.changePage();
                                  },
                                )
                              }>
                              {'>>'}
                            </button>
                          ) : (
                            <div style={{ width: 100 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
            {/* <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>{language[this.props.currentLanguage].DateTime}</div><div style={{ cursor: 'pointer'}} onClick={() => this.SortView()}>⧨</div>
                  </th>
                  <th>{language[this.props.currentLanguage].Guest}</th>
                  <th>{language[this.props.currentLanguage].StaffMember}</th>
                  <th>{language[this.props.currentLanguage].Amount}</th>
                  <th>{language[this.props.currentLanguage].Type}</th>
                  <th>结果</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                </td>
              </tbody>
            </Table> */}
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ActionList);
  