import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import { language } from '../language';
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { getAgentWorkerListFNC, showPaymentHistoryFNC } from '../Api'
import moment from 'moment-timezone';
import { exportclienthistoryExcel } from '../Component/jsonToXlsx'

class HistoryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      paymentList: [],
      AllPaymentLists: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
      SelectedAgent: 'All',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      SearchClient: '',
      statename: '',
      statedate: '',
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList: agentList, agentObj });
      // this.initPage();
    }
    this.getUserHistory();
  }

  async getUserHistory() {
    let filterAgent = this.state.filterAgent
    if (this.props.role === 'work' || this.props.role === 'agent') {
      filterAgent = this.props.userID
    }
    const showPaymentHistory = await showPaymentHistoryFNC(this.state.SearchClient, filterAgent, this.props.username, this.props.token)
    // console.log('showPaymentHistory', showPaymentHistory)
    if (showPaymentHistory) {
      if (showPaymentHistory.error === 'No history') {
        this.setState({ paymentList: [], AllPaymentLists: [] })
      } else {
        this.setState({ paymentList: showPaymentHistory, AllPaymentLists: showPaymentHistory })
      }
    }
  }

  changeDate(value, key) {
    this.setState({ [key]: value}, ()=>{
      this.getUserHistory()
    })
  }

  summariedData(data) {
    let summarisedObject = {}
    for (let i = 0; i < data.length; i += 1) {
      if (!summarisedObject[data[i].User]) {
        summarisedObject[data[i].User] = {}
      }
      if (!summarisedObject[data[i].User][data[i].Name]) {
        summarisedObject[data[i].User][data[i].Name] = {
          Cash: 0,
          Cheque: 0,
        }
      }
      if (data[i].Type === 'Cash') {
        summarisedObject[data[i].User][data[i].Name].Cash += data[i].Amount
      }
    }
    const summarisedArray = []
    const summarisedAgentKeys = Object.keys(summarisedObject)
    for (let i = 0; i < summarisedAgentKeys.length; i += 1) {
      const tempArray = []
      const summarisedClientKeys = Object.keys(summarisedObject[summarisedAgentKeys[i]])
      for (let j = 0; j < summarisedClientKeys.length; j += 1) {
        tempArray.push({
          Client: summarisedClientKeys[j],
          Cash: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cash,
          Cheque: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cheque,
        })
      }
      summarisedArray.push({
        agent: summarisedAgentKeys[i],
        clientArray: tempArray,
      })
    }
    this.setState({
      paymentList: summarisedArray,
      loading: false,
    });
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value})
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.initPage()
    }
  }

  exportExcel() {
    const DisplayData = (this.state.filterAgent === '') ? this.state.paymentList : this.state.paymentList.filter((item) => item.Worker === this.state.filterAgent)
    exportclienthistoryExcel(DisplayData, this.state.agentObj)
  }

  changePage() {
    this.setState({ paymentList: [], AllPaymentLists: [], FilterType: 'All' }, () => {
      this.getUserHistory();
    });
  }

  FilterTableView(value, type) {
    let AllPaymentLists = this.state.AllPaymentLists
    if (value === 'All') {
      this.setState({ paymentList: AllPaymentLists, [type]: value })
    } else {
      const Filtered = AllPaymentLists.filter((values) => values.Type === value);
      this.setState({ paymentList: Filtered, [type]: value })
    }
  }

  EnterClick(e) {
    if (e.key === 'Enter') {
      this.getUserHistory();
    }
  }

  Calculate(Totaltype) {
    let userArray = (this.state.filterAgent === '') ? this.state.paymentList : this.state.paymentList.filter((item) => item.Worker === this.state.filterAgent)
    console.log('userArray', userArray)
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  sortfunction(state, sortstate) {
    const DisplayData = (this.state.filterAgent === '') ? this.state.paymentList : this.state.paymentList.filter((item) => item.Worker === this.state.filterAgent)
    if(state === 'Name') {
      if(sortstate === 'bigtosmall') {
        DisplayData.sort((a, b) => (a.Client > b.Client) ? 1 : -1)
        this.setState({ paymentList: DisplayData, statename: 'smalltobig', statedate: '' })
      } else {
        DisplayData.sort((a, b) => (a.Client > b.Client) ? -1 : 1)
        this.setState({ paymentList: DisplayData, statename: 'bigtosmall', statedate: '' })
      }
    } else if (state === 'Date') {
      if(sortstate === 'bigtosmall') {
        DisplayData.sort((a, b) => ( new Date(a.DateTime) > new Date(b.DateTime)) ? 1 : -1)
        // const sortedEvents = DisplayData.sort((a, b) => {
        // // Convert date strings to Date objects for accurate comparison
        //   const dateA = new Date(a.DateTime);
        //   const dateB = new Date(b.DateTime);
        
        //   // Compare dates
        //   return dateA - dateB;
        // });

        this.setState({ paymentList: DisplayData, statedate: 'smalltobig', statename: '' })
      } else {
        DisplayData.sort((a, b) => ( new Date(a.DateTime) >  new Date(b.DateTime)) ? -1 : 1)
        this.setState({ paymentList: DisplayData, statedate: 'bigtosmall', statename: '' })
      }
    }
  }

  render() {
    console.log('paymentList', this.state.filterAgent)
    // Worker
    const DisplayData = (this.state.filterAgent === '') ? this.state.paymentList : this.state.paymentList.filter((item) => item.Worker === this.state.filterAgent)

    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>历史记录</h2>
                {(this.props.role !== 'work' && this.props.role !== 'agent') && 
                <>
                  <div style={{ marginRight: 10 }}>工作人员: </div>
                  <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                    <option value=''>{language[this.props.currentLanguage].All}</option>
                    {this.state.agentList.map((item) =>
                      <option value={item.ID}>{item.Name}</option>
                    )}
                  </Form.Select>
                </>}
                <div style={{ marginRight: 10, marginLeft: 10 }}>搜索客户: </div>
                <Form.Control value={this.state.SearchClient} style={{ width: 150, marginRight: 10 }} onChange={(e) => this.setState({ SearchClient: e.target.value })} onKeyDown={(e) => this.EnterClick(e)} />
                <Button variant='success' onClick={() => this.getUserHistory()}>{language[this.props.currentLanguage].Search}</Button>
              </div>
              <div style={{ display: 'flex' }}>
                <Button style={{ minWidth: 80}} onClick={() => this.exportExcel()} variant='success'>Excel</Button>
              </div>
              {/* <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                  <div style={{ marginRight: 10}}>日期: </div>
                  <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                  <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                  <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
                </div>
              </div> */}
            </div>
            <div className="dcc" style={{ width: '99%', backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%' }}>
                <div  style={{ flex: "1", padding: 5 }}>
                  <Fragment>
                    <div style={{ marginBottom: 10 }}>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white', cursor: 'pointer'}} onClick={(e) => this.sortfunction('Name', this.state.statename === '' ? 'bigtosmall': this.state.statename)}>
                            {language[this.props.currentLanguage].Name}{this.state.statename === '' ? ' ⬍' : this.state.statename === 'bigtosmall' ? ' ￬' : ' ￪'}
                          </div>
                          {/* <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ResponsiblePerson}
                          </div> */}
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white', cursor: 'pointer'}} onClick={(e) => this.sortfunction('Date', this.state.statedate === '' ? 'bigtosmall' : this.state.statedate)}>
                            {'收款日期'} {this.state.statedate === '' ? ' ⬍' : this.state.statedate === 'bigtosmall' ? ' ￬' : ' ￪'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Cheque'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Cash'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Total'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Remark'}
                          </div>
                        </div>
                      </div>
                      {DisplayData.length > 0 && DisplayData.map((item, index) => {
                        return(
                          <div className='dcc highlight' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                            <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {item.Client}
                              </div>
                              {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {this.state.agentObj[item.Worker]}
                              </div> */}
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {moment(item.DateTime).format('YYYY-MM-DD')}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {item.AmountCheque.toLocaleString()}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {item.AmountCash.toLocaleString()}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {item.Amount.toLocaleString()}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                {item.Remark}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {DisplayData.length > 0 && <div className='dcc' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: 'black' }}>
                        <div style={{width:'100%' , flexDirection: 'row', display: 'flex', fontWeight: 'bold' }}>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {''}
                          </div>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {'Total'}
                          </div>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {this.Calculate('AmountCheque')}
                          </div>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {this.Calculate('AmountCash')}
                          </div>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {this.Calculate('Amount')}
                          </div>
                          <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: 'white' }}>
                            {''}
                          </div>
                        </div>
                      </div>}
                      {DisplayData.length === 0 && 
                        <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                          <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                            {'No Data'}
                          </div>
                        </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    userID: state.userID,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(HistoryPage);
  