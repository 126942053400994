import xlsx from 'xlsx'
import moment from 'moment-timezone'
import { nextChar } from './Component/nextChar'
// import { tableName, actionName } from './static'

function stylings(value, color, type, username) {
  let colorBackground = {}
  if (color === 'blue') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "4A5874"},
        bgColor: {rgb: "4A5874"}
      }
    }
  }
  if (color === 'g') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "dddddd"},
        bgColor: {rgb: "dddddd"}
      }
    }
  }
  if (color === 's') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "9FA7FF"},
        bgColor: {rgb: "9FA7FF"}
      }
    }
  }

  return {v: username ? `[${value}]` : value, t: type === "n" ? "n" : "s", s: {
    border: {
      top: {style: 'thin', color:{rgb: "000"}}, 
      bottom: {style: 'thin', color:{rgb: "000"}}, 
      left: {style: 'thin', color:{rgb: "000"}}, 
      right: {style: 'thin', color:{rgb: "000"}}
    },
    alignment: {
      horizontal: 'center',
      wrapText: true,
    },
    ...colorBackground,
  }}
}

export function exportzhangbenExcel(userArray, dateArray, agentObj) {
  let currentAlphabet = 'E'
  let objArray = {
    A: 'ID',
    B: '名字',
    C: '负责人',
    D: '旧',
  }
  let alphabetArray = ['A', 'B', 'C', 'D']
  for(let i = 0; i < dateArray.length; i += 1) {
    objArray[currentAlphabet] = dateArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }
  let leftoverArray = ['剩余', '定金/多余', '已收现金', '已收支票', '注释']
  for(let i = 0; i < leftoverArray.length; i += 1) {
    objArray[currentAlphabet] = leftoverArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: alphabetArray, skipHeader: true})
  for (let i = 0; i < userArray.length; i += 1) {
    currentAlphabet = 'E'
    objArray = {
      A: userArray[i].ID,
      B: userArray[i].Name,
      C: agentObj[userArray[i].ResponsibleUser],
      D: 'Hi'
    }
    alphabetArray = ['A', 'B', 'C', 'D']
    for(let j = 0; j < dateArray.length; j += 1) {
      objArray[currentAlphabet] = userArray[i][dateArray[j]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    
    leftoverArray = ['Balance', 'Extra', 'Cash', 'Cheque', 'Remark']
    for(let k = 0; k < leftoverArray.length; k += 1) {
      objArray[currentAlphabet] = userArray[i][leftoverArray[k]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: alphabetArray, skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`账本`)
  wb.Sheets['账本'] = ws
  xlsx.writeFile(wb, `账本 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportagentzhangbenExcel(userArray, dateArray, agentObj) {
  let currentAlphabet = 'D'
  let objArray = {
    A: 'ID',
    B: '名字',
  }
  let alphabetArray = ['A', 'B']
  for(let i = 0; i < dateArray.length; i += 1) {
    objArray[currentAlphabet] = dateArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }
  let leftoverArray = ['剩余', '定金/多余', '已收现金', '已收支票', '注释']
  for(let i = 0; i < leftoverArray.length; i += 1) {
    objArray[currentAlphabet] = leftoverArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: alphabetArray, skipHeader: true})
  for (let i = 0; i < userArray.length; i += 1) {
    currentAlphabet = 'D'
    objArray = {
      A: userArray[i].ID,
      B: userArray[i].Name,
    }
    alphabetArray = ['A', 'B']
    for(let j = 0; j < dateArray.length; j += 1) {
      objArray[currentAlphabet] = userArray[i][dateArray[j]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    
    leftoverArray = ['Balance', 'Extra', 'Cash', 'Cheque', 'Remark']
    for(let k = 0; k < leftoverArray.length; k += 1) {
      objArray[currentAlphabet] = userArray[i][leftoverArray[k]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: alphabetArray, skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`账本`)
  wb.Sheets['账本'] = ws
  xlsx.writeFile(wb, `账本 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportclientExcel(clientList, agentObj) {
  let objArray = {
    A: 'ID',
    B: '名字',
    C: '定金/多余',
    D: '已收现金',
    E: '已收支票',
    F: '负责人',
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  for (let i = 0; i < clientList.length; i += 1) {
    objArray = {
      A: clientList[i].ID,
      B: clientList[i].Name,
      C: clientList[i].Extra,
      D: clientList[i].Cash,
      E: clientList[i].Cheque,
      F: agentObj[clientList[i].ResponsibleUser],
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`客仔`)
  wb.Sheets['客仔'] = ws
  xlsx.writeFile(wb, `客仔 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportpaymentExcel(paymentList, agentObj) {
  let objArray = {
    A: 'ID',
    B: '日期时间',
    C: '客仔',
    D: '工作人员',
    E: '数目',
    F: '款项',
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  for (let i = 0; i < paymentList.length; i += 1) {
    objArray = {
      A: paymentList[i].ID,
      B: moment(paymentList[i].DateTime).format('YYYY-MM-DD HH:mm:ss'),
      C: paymentList[i].Name,
      D: agentObj[paymentList[i].User],
      E: paymentList[i].Amount,
      F: paymentList[i].Type,
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`交易`)
  wb.Sheets['交易'] = ws
  xlsx.writeFile(wb, `交易 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportsimplifiedpaymentExcel(paymentList, agentObj, role) {
  if (role && role === 'agent') {
    let objArray = {
      A: '客仔',
      B: 'Cash',
      C: 'Cheque',
    }
  
    let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C'], skipHeader: true})
    for (let i = 0; i < paymentList.length; i += 1) {
      objArray = {
        A: paymentList[i].Client,
        B: paymentList[i].Cash,
        C: paymentList[i].Cheque,
      }
      xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    }
    let wb = { SheetNames: [], Sheets: {} }
    wb.SheetNames.push(`日结`)
    wb.Sheets['日结'] = ws
    xlsx.writeFile(wb, `日结 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  } else {
    let objArray = {
      A: '工作人员',
      B: '客仔',
      C: 'Cash',
      D: 'Cheque',
    }
  
    let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D'], skipHeader: true})
    for (let i = 0; i < paymentList.length; i += 1) {
      objArray = {
        A: agentObj[paymentList[i].agent],
        B: '',
        C: '',
        D: '',
      }
      xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
      for (let j = 0; j < paymentList[i].clientArray.length; j += 1) {
        objArray = {
          A: '',
          B: paymentList[i].clientArray[j].Client,
          C: paymentList[i].clientArray[j].Cash,
          D: paymentList[i].clientArray[j].Cheque,
        }
        xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
      }
    }
    let wb = { SheetNames: [], Sheets: {} }
    wb.SheetNames.push(`日结`)
    wb.Sheets['日结'] = ws
    xlsx.writeFile(wb, `日结 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  }
}

export function exportActionExcel(actionList, agentObj, Date) {
  const total = { 'TotalAmount': 0 }
  for (let i = 0; i < actionList.length; i += 1) { 
    total.TotalAmount += Number(actionList[i].Amount)
  }
  let objArray = {
    A: stylings('工作人员'),
    B: stylings(''),
    C: stylings('日期'),
    D: stylings(`${Date.fromDate} - ${Date.toDate}`),
  }
  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: ''}], { header: ['A'], skipHeader: true, origin: -1 })
  objArray = {
    A: stylings('ID', 'blue', ''),
    B: stylings('日期时间', 'blue', ''),
    C: stylings('客仔', 'blue', ''),
    D: stylings('工作人员', 'blue', ''),
    E: stylings('AddedBy', 'blue', ''),
    F: stylings('数目', 'blue', ''),
    G: stylings('类别', 'blue', ''),
    H: stylings('结果', 'blue', ''),
    I: stylings('ApprovedBy', 'blue', ''),
  }
  xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], skipHeader: true, origin: -1 })
  for (let i = 0; i < actionList.length; i += 1) {
    objArray = {
      A: stylings(actionList[i].ID, 'g'),
      B: stylings(moment(actionList[i].DateTime).format('YYYY-MM-DD'), 'g'),
      C: stylings(actionList[i].Client, 'g'),
      D: stylings(agentObj[actionList[i].Worker], 'g'),
      E: stylings(actionList[i].AddedBy, 'g'),
      F: stylings(Number(actionList[i].Amount).toLocaleString(), 'g'),
      G: stylings(actionList[i].Type, 'g'),
      H: stylings(actionList[i].ApprovalStatus, 'g'),
      I: stylings(actionList[i].ApproveBy, 'g'),
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], skipHeader: true, origin: -1 })
  }
  objArray = {
    A: stylings('', 'blue'),
    B: stylings('', 'blue'),
    C: stylings('', 'blue'),
    D: stylings('', 'blue'),
    E: stylings('Total : ', 'blue'),
    F: stylings(total.TotalAmount.toLocaleString(), 'blue'),
    G: stylings('', 'blue'),
    H: stylings('', 'blue'),
    I: stylings('', 'blue'),
  }
  xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], skipHeader: true, origin: -1 })

  ws['!cols'] = [{wpx: 60},{wpx: 70},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 70},{wpx: 70},{wpx: 100},{wpx: 100}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`活动`)
  wb.Sheets['活动'] = ws
  xlsx.writeFile(wb, `活动 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}
