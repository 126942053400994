import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { getPaymentList } from '../Api'
import moment from 'moment-timezone';
import { exportsimplifiedpaymentExcel } from '../Component/jsonToXlsx'

class AgentPaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paymentList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'agent') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      this.initPage()
    }
  }

  async initPage() {
    const getPaymentData = await getPaymentList(this.props.username, this.props.token, this.state.fromDate, this.state.toDate, this.state.fromAmount, this.state.toAmount);
    this.summariedData(getPaymentData)
  }

  summariedData(data) {
    let summarisedObject = {}
    for (let i = 0; i < data.length; i += 1) {
      if (!summarisedObject[data[i].Name]) {
        summarisedObject[data[i].Name] = {
          Cash: 0,
          Cheque: 0,
        }
      }
      if (data[i].Type === 'Cash') {
        summarisedObject[data[i].Name].Cash += data[i].Amount
      }
    }

    const summarisedArray = []
    const summarisedClientKeys = Object.keys(summarisedObject)
    for (let j = 0; j < summarisedClientKeys.length; j += 1) {
      summarisedArray.push({
        Client: summarisedClientKeys[j],
        Cash: summarisedObject[summarisedClientKeys[j]].Cash,
        Cheque: summarisedObject[summarisedClientKeys[j]].Cheque,
      })
    }
    this.setState({
      paymentList: summarisedArray,
      loading: false,
    });
  }
  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.initPage())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value})
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.initPage()
    }
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.paymentList.filter((item) => item.agent === this.state.filterAgent)
      exportsimplifiedpaymentExcel(filterArray, this.state.agentObj, 'agent')
    } else {
      exportsimplifiedpaymentExcel(this.state.paymentList, this.state.agentObj, 'agent')
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>交易</h2>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.exportExcel()} variant="outline-success">Excel</Button>
                <div style={{ marginRight: 10}}>日期: </div>
                <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
              </div>
            </div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>客仔</th>
                  <th>Cash</th>
                  <th>Cheque</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paymentList.map((item, index) =>  
                  <tr key={index}>
                    <td>{item.Client}</td>
                    <td>{item.Cash}</td>
                    <td>{item.Cheque}</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AgentPaymentList);
  