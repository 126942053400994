import '../App.css';
import JSEncrypt from 'jsencrypt';
import React from 'react';
import ShowPass from '../Image/ShowPass.png';
import HidePass from '../Image/HidePass.png';
import ErrorDragon from '../Image/errorsign.gif';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { getPublicKey, getLogin } from '../Api';
import Container from 'react-bootstrap/Container';

var timer

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Username: '',
      Password: '',
      loading: false,
      ShowPassword: false,
      DisplayError: false,
      DisplayErrorMessage: '',
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.UsernameInput = React.createRef();
    this.PasswordInput = React.createRef();
  }

  async handleSubmit() {
    this.setState({ loading: true });
    if (this.state.Username === '') {
      this.DisplayMEssage('错误: 请输入用户名', 'Error');
      this.setState({ loading: false})
    } else if (this.state.Password === '') {
      // alert(`请输入密码`);
      this.DisplayMEssage('错误: 请输入密码', 'Error');
      this.setState({ loading: false})
    }
    else {
      const PublicKey = await getPublicKey();
      const encrypt = new JSEncrypt();
      encrypt.setPublicKey(PublicKey.publicKey);
      const EnPassword = encrypt.encrypt(this.state.Password);
      const LoginStatus = await getLogin(
        this.state.Username,
        EnPassword,
      );
      // console.log('LoginStatus', LoginStatus)
      if (LoginStatus.error === 'Invalid Password') {
        // alert('密码错误');
        this.DisplayMEssage('错误: 密码错误', 'Error');
        this.setState({ loading: false })
        this.PasswordInput.focus();
      } else if (LoginStatus.error === 'Account Has Been Locked, Please Find Admin to Unlock') {
        // alert('此用户已经被封锁');
        this.DisplayMEssage('错误: 此用户已经被封锁', 'Error');
        this.setState({ loading: false })
        this.UsernameInput.focus();
      } else if (LoginStatus.error === 'Invalid Account') {
        // alert('查无此号');
        this.DisplayMEssage('错误: 查无此号', 'Error');
        this.setState({ loading: false })
        // this.props.setLoginStatus('', '', '');
        this.UsernameInput.focus();
      } else {
        this.props.setLoginStatus(
          LoginStatus.username,
          LoginStatus.userID,
          LoginStatus.token,
          LoginStatus.userRole,
        );
        sessionStorage.setItem("shouzhangtoken", LoginStatus.token);
        sessionStorage.setItem("shouzhangusername", this.state.Username);
        sessionStorage.setItem("shouzhanguserID", LoginStatus.userID);
        sessionStorage.setItem("shouzhangrole", LoginStatus.userRole);
        this.setState({ loading: false })
        if (LoginStatus.userRole === 'agent') {
          this.props.history.push('/Agent')
        } else if (LoginStatus.userRole === 'admin'){
          this.props.history.push('/Admin')
        } else if (LoginStatus.userRole === 'obs'){
          this.props.history.push('/Obs')
        } 
        else if (LoginStatus.userRole === 'work'){
          this.props.history.push('/Agent')
        } else if (LoginStatus.userRole === 'account'){
          this.props.history.push('/Admin')
        } else if (LoginStatus.userRole === 'agent'){
          this.props.history.push('/Agent')
        } else {
          this.props.history.push('/PlayerPage')
        }
      }
    }
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true })
    if (type === 'Error') {
      this.props.setLoginStatus('', '', '', '');
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false
      });
    }, 5000);
  }

  async onKeyEnter(e) {
    if (e.charCode === 13) {
      this.handleSubmit()
    }
  }

  handleChange(value, field) {
    this.setState({ [field]: value });
  }

  render() {
    return (
      <Container className='dcc' fluid style={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: 'lightgrey' }}>
        {this.state.DisplayError === true && 
         <div className='dcc' style={{ marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
           <img src={ErrorDragon} alt="Logo" style={{ width: 100 }}/>
           <div style={{ fontWeight: 'bold', fontSize: '20px', color: 'red' }}>{` ${this.state.DisplayErrorMessage}`}</div>
         </div>
        }
        <Form className="dcc LoginContainer">
          {/* backgroundImage: "linear-gradient(#6D4F3C, #7A5842, #704D36)" */}
          <Form.Label className="dcc" style={{ width: '100%', height: 'auto', fontWeight: 'bold', color: 'black', backgroundColor: '#ffa51e', fontSize: '30px', padding: 35 }}>
            SZ
          </Form.Label>
          <Form.Group className="dcc LoginUsername">
            <Form.Label className='LoginUsernameLabel'>用户名</Form.Label>
            <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row'}}>
              <Form.Control
                className="LoginInputText"
                type="text"
                value={this.state.Username}
                onChange={(e) => this.handleChange(e.target.value, 'Username')}
                ref={(input) => { this.UsernameInput = input; }}
                placeholder={'用户名'}
                style={{ fontWeight: 'bold', marginRight:  null, marginLeft: ''}}
              />
              <div style={{ width: '10%'}}/> 
            </Form.Group>
          </Form.Group>
          <Form.Group className="dcc LoginUsername2">
            <Form.Label className='LoginUsernameLabel'>密码</Form.Label>
            <Form.Group className="dcc" style={{ width: '100%', height: 'auto', flexDirection: 'row'}}>
              <Form.Control
                className="LoginInputText"
                type={(this.state.ShowPassword === true) ? "Text" : "Password"}
                value={this.state.Password}
                onChange={(e) => this.handleChange(e.target.value, 'Password')}
                placeholder={'密码'}
                ref={(input) => { this.PasswordInput = input; }}
                onKeyPress={(e) => this.onKeyEnter(e)}
                style={{ fontWeight: 'bold', marginRight: null,  marginLeft: ''}}
              />
              {this.state.ShowPassword === false
                ? <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: true })}>
                  <i class="fas fa-eye-slash" style={{ fontSize: 25, marginLeft: 10}}></i>
                </div>
                : <div style={{ cursor: 'pointer'}} onClick={()=> this.setState({ShowPassword: false })}>
                  {/* <img src={ShowPass} alt="Logo" className='PasswordEye' />*/}
                  <i class="fas fa-eye" style={{ fontSize: 25, marginLeft: 10}}></i>
                </div> 
              }
            </Form.Group>
          </Form.Group>
          <Form.Group className='dcc' style={{ width: '100%', paddingBottom: '8%' }}>
            <Button  className="ButtonLogin" style={{ flexDirection: 'row',  backgroundColor: '#ffa51e', border: 'none' }} onClick={this.handleSubmit}>
              <p1 style={{ fontSize: '18px', fontWeight: 'bold', marginRight: 5, color: 'black' }}>
                登入
              </p1>
            </Button >
          </Form.Group>
        </Form>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserID, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          userID: UserID,
          token: UserToken,
          role: role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Login);
  