import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { getActionList, getAdminList } from '../Api'
import moment from 'moment-timezone';
import { exportActionExcel } from '../Component/jsonToXlsx'
import { tableName, actionName } from '../Component/static'

class ActionList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      actionList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'obs') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      const agentList = await getAdminList(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList, agentObj });
      this.initPage()
    }
  }

  async initPage() {
    const getActionData = await getActionList(this.props.username, this.props.token, this.state.fromDate, this.state.toDate, this.state.fromAmount, this.state.toAmount);
    this.setState({
      actionList: getActionData,
      loading: false,
    });
  }

  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.initPage())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value})
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.actionList.filter((item) => item.User === this.state.filterAgent)
      exportActionExcel(filterArray, this.state.agentObj)
    } else {
      exportActionExcel(this.state.actionList, this.state.agentObj)
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>活动</h2>
                <div style={{ marginRight: 10 }}>Admin: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value=''>全部</option>
                  {this.state.agentList.map((item) => 
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ marginRight: 10}}>日期: </div>
                  <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                  <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                  <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
                </div>
                <Button style={{ minWidth: 80 }} onClick={() => this.exportExcel()} variant="outline-success">Excel</Button>
              </div>
            </div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>日期时间</th>
                  <th>Admin</th>
                  <th>客仔</th>
                  <th>活动</th>
                  <th>细节</th>
                  <th>类别</th>
                  <th>Key</th>
                  <th>从</th>
                  <th>结果</th>
                </tr>
              </thead>
              <tbody>
                {this.state.actionList.map((item, index) => (this.state.filterAgent === '' || (this.state.filterAgent !== '' && this.state.filterAgent === item.UserID)) && <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                  <td>{item.UserName}</td>
                  <td>{item.ClientName}</td>
                  <td>{actionName[item.Action]}</td>
                  <td>{item.Details}</td>
                  <td>{tableName[item.Table]}</td>
                  <td>{item.Key}</td>
                  <td>{item.FromValue}</td>
                  <td>{item.ToValue}</td>
                </tr>)}
              </tbody>
            </Table>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ActionList);
  