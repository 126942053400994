import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Header from '../Component/Header'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
// import Container from 'react-bootstrap/Container'
import moment from 'moment-timezone'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import RemarkModal from '../Component/RemarkModal';
import { addPaymentFNC, getClientList, editPending, getAgentWorkerListFNC, addPending, deletePending } from '../Api'
import deletePng from '../Image/delete.png'
import { exportzhangbenExcel } from '../Component/jsonToXlsx'
import { language } from '../language';

var timer

class Obs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateArray: [],
      userArray: [],
      inputNumber: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      total: {
        Balance: 0,
        Cash: 0,
        Cheque: 0,
        Extra: 0,
      },
      agentList: [],
      filterAgent: '',
      agentObj: {},
      AddPayment: [],
      RemarkModal: false,
      DisplayItems: '',
      SearchName: '',
      RamarkType: '',
    }
    this.handleClose = this.handleClose.bind(this);
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList: agentList, agentObj }, () => {
        this.initPage();
      });
    }
  }

  async initPage() {
    // if (this.state.filterAgent.length > 0) {
    const getClientData = await getClientList(this.props.username, this.props.token, this.state.filterAgent, this.state.SearchName);
    // console.log('getClientData', getClientData)
    this.setState({ userArray: getClientData.userArray, dateArray: getClientData.dateArray, inputNumber: getClientData.userArray.map((item) => ({cash: '', cheque: ''})), AddPayment: getClientData.userArray.map((item) => ({inputNumberCash: '', inputNumberCek: ''})) })
    // } else {
    //   this.setState({ userArray: [], dateArray: [] })
    // }
  }
  
  handleClose() {
    this.setState({ 
      RamarkType: '',
      RemarkModal: false,
      DisplayItems: '',
      SearchName: '',
    }, () => {
      this.initPage();
    })
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.userArray.filter((item) => item.ResponsibleUser === this.state.filterAgent)
      exportzhangbenExcel(filterArray, this.state.dateArray, this.state.agentObj)
    } else {
      exportzhangbenExcel(this.state.userArray, this.state.dateArray, this.state.agentObj)
    }
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  CalculateTotal(Totaltype) {
    let userArray = this.state.userArray
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  TotalZong(Selected) {
    let Totals = {
      TotalNumber: 0,
    }
    this.state.dateArray.forEach((date) => {
      Totals.TotalNumber += Number(Selected[date]) 
    })
    Totals.TotalNumber += Number(Selected['Old']) 
    return Totals.TotalNumber.toLocaleString()
  }

  CalculatezongTotal() {
    let Totals = {
      TotalNumber: 0,
    }
    const UserArray = this.state.userArray

    UserArray.forEach((array) => {
      this.state.dateArray.forEach((date) => {
        Totals.TotalNumber += Number(array[date])
      })
      Totals.TotalNumber += Number(array['Old'])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  OpenRemarkModal(item, type) {
    this.setState({ 
      RemarkModal: true,
      DisplayItems: item,
      RamarkType: type,
    })
  }

  onKeyEnterSearch(e) {
    if (e.charCode === 13) {
      this.initPage();
    }
  }

  ReturnColor(Limit, see) {
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }
    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnColorText(index, number) {
    const item = this.state.userArray[index];
    const Limit =  item.Limits;
    const see =  Number(item.Total);
    const XDate = item.XDate;
    let DataArray = []
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }
    if (number === '0') {
      return ''
    }
    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      if (Number(XDate) === 0) {
        return ''
      }
      
      const dateArray = this.state.dateArray
      for (var i = 0; i < dateArray.length; i++) {
        if (item[dateArray[i]] !== '0') {
          DataArray.push(item[dateArray[i]])
        }
      }

      if (DataArray.length >= Number(XDate)) {
        color = '#FF5132'
      } else {
        color = ''
      }
    }
    return color
  }


  ReturnColorXDate(XDate, item) {
    let DataArray = []
    if (Number(XDate) === 0) {
      return ''
    }
    const dateArray = this.state.dateArray
    for (var i = 0; i < dateArray.length; i++) {
      if (item[dateArray[i]] !== '0') {
        DataArray.push(item[dateArray[i]])
      }
    }
    let color = ''
    if (DataArray.length >= Number(XDate)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnCompanyTotalColor(CompanyTotal, Total) {
    let color = ''
    if (CompanyTotal !== Total) {
      color = '#FF5132'
    } else { color = '' }
    return color
  }

  ReturnNumberCount(NumberDisplay) {
    // return NumberDisplay;
    const DecimalNumber = NumberDisplay % 1
    return (DecimalNumber < 0.5) ? Math.floor(NumberDisplay).toLocaleString() : Math.ceil(NumberDisplay).toLocaleString()
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          {this.state.RemarkModal && 
          <RemarkModal RemarkModal={this.state.RemarkModal} handleClose={this.handleClose} DisplayItems={this.state.DisplayItems} RamarkType={this.state.RamarkType}/>}
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ marginRight: 10 }}>📖 Obs 账本</h2>
              <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].StaffMember}: </div>
              <Form.Select style={{ width: 150}} onChange={(e) => {
                this.setState({ filterAgent: e.target.value }, () => this.initPage())
              }}>
                <option value=''>{language[this.props.currentLanguage].All}</option>
                {this.state.agentList.map((item, inde) => 
                  <option key={inde} value={item.ID}>{item.Name}</option>
                )}
              </Form.Select>
              <div style={{ paddingLeft: 5 }}><Form.Control value={this.state.SearchName} placeholder='Search Name' onChange={(e) => this.setState({ SearchName: e.target.value })} onKeyPress={(e) => this.onKeyEnterSearch(e)} /></div>
            </div>
            <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
              {this.state.MessageType === 'Error' && 
              <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
              }
              {this.state.MessageType === 'Success' && 
              <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
              }
              <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
            </div>
            <div style={{ display: 'flex' }}>
              <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.exportExcel()} variant="success">Excel</Button>
            </div>
          </div>
          <div style={{ overflowY: 'auto' }}>
            <Table striped bordered hover responsive style={{  backgroundColor: 'white' }}>
              <thead>
                <tr>
                  {/* <th style={{ minWidth: 100 }}>ID</th> */}
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Name}</th>
                  <th style={{ minWidth: 100 }}>{'Limit'}</th>
                  <th style={{ minWidth: 100 }}>{'X期'}</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Deposit}</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].ResponsiblePerson}</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Old}</th>
                  {this.state.dateArray.map((item, idx) => <th key={idx}>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                      <div style={{ marginRight: 10 }}>{moment(item, 'YYYYMMDD').format('DD/MM')}</div>
                    </div>
                  </th>)}
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Total}</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Company}{language[this.props.currentLanguage].Total}</th>
                  {/* <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].Advance}</th>
                  <th style={{ minWidth: 100 }}>Return Cheque</th> */}
                  <th style={{ minWidth: 100 }}>Remark</th>
                  <th style={{ minWidth: 100 }}>Acc Remark</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].ReceivedCash}</th>
                  <th style={{ minWidth: 100 }}>{language[this.props.currentLanguage].ReceivedCheque}</th>
                </tr>
              </thead>
              <tbody>
                {this.state.userArray.length > 0 && 
                <>
                  {this.state.userArray.map((item, index) => 
                    <tr key={index}>
                      {/* <td>{item.ID}</td> */}
                      <td>{item.Name}</td>
                      <td style={{ color: this.ReturnColor(item.Limits, Number(item.Total)) }}>{item.Limits}</td>
                      <td style={{ color: this.ReturnColorXDate(item.XDate, item) }}>{item.XDate}</td>
                      <td>{Number(item.Deposit).toLocaleString()}</td>
                      <td>{this.state.agentObj[item.ResponsibleWorker]}</td>
                      <td>{Number(item.Old).toLocaleString()}</td>
                      {this.state.dateArray.map((dateItem, idxs) => <td key={idxs} style={{ color: this.ReturnColorText(index, item[dateItem])}}>
                        {typeof item[dateItem] === 'undefined' ? '' : Number(item[dateItem]).toLocaleString()}
                      </td>)}
                      <td>{Number(item.Total).toLocaleString()}</td>
                      <td style={{ color: this.ReturnCompanyTotalColor(this.ReturnNumberCount(Number(item.CompanyTotal)), this.ReturnNumberCount(Number(item.Total))) }}>{Number(item.CompanyTotal).toLocaleString()}</td>
                      {/* <td>{Number(item.ReturnedCheque).toLocaleString()}</td> */}
                      {/* <td>{Number(item.Advance).toLocaleString()}</td>
                      <td>{Number(item.ReturnedCheque).toLocaleString()}</td> */}
                      <td>{
                        ((item.Remark === null || item.Remark === '') ? '-' : item.Remark === '-')
                          ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].Add}</div> 
                          : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].View}</div>}</td>
                      <td>{
                        ((item.AccRemark === null || item.AccRemark === '') ? '-' : item.AccRemark === '-')
                          ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].Add}</div> 
                          : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].View}</div>}</td>
                      <td>{Number(item.ReceivedCash).toLocaleString()}</td>
                      <td>{Number(item.ReceivedCheque).toLocaleString()}</td>
                    </tr>)}
                  <tr>
                    <td colSpan="1" style={{ fontWeight: 'bold', backgroundColor: 'black', color: 'white', textAlign: 'center'}}>{`Total`}</td>
                    <td>{'-'}</td>
                    <td>{'-'}</td>
                    <td>{this.CalculateTotal('Deposit')}</td>
                    <td>{'-'}</td>
                    <td>{this.CalculateTotal('Old')}</td>
                    {this.state.dateArray.map((dateItem, idxa) => <td key={idxa}>{this.CalculateTotal(dateItem)}</td>)}
                    <td style={{ backgroundColor: this.state.total.Balance !== 0 && '#FFFF00'}}>{this.CalculateTotal('Total')}</td>
                    <td style={{ backgroundColor: this.state.total.Balance !== 0 && '#FFFF00'}}>{this.CalculateTotal('CompanyTotal')}</td>
                    {/* <td>{this.CalculateTotal('ReturnedCheque')}</td> */}
                    {/* <td>{this.CalculateTotal('Advance')}</td>
                    <td>{this.CalculateTotal('ReturnedCheque')}</td> */}
                    <td>{'-'}</td>
                    <td>{'-'}</td>
                    <td>{this.CalculateTotal('ReceivedCash')}</td>
                    <td>{this.CalculateTotal('ReceivedCheque')}</td>
                  </tr>
                </>
                }
                {this.state.userArray.length === 0 && 
                  <tr>
                    <td colSpan="16" style={{ fontWeight: 'bold', textAlign: 'center' }}>{`No Data`}</td>
                  </tr>
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Obs);
  