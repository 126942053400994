import '../App.css';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../Component/Header'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
import RemarkModal from '../Component/RemarkModal';
import moment from 'moment-timezone'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { getClientList, getAgentWorkerListFNC, updatePayment, showPlayerPaymentFNC } from '../Api'
import { exportzhangbenExcel } from '../Component/jsonToXlsx'
import { language } from '../language';

var timer

class PlayerPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateArray: [],
      userArray: [],
      selectedPending: '',
      inputNumber: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      agentList: [],
      agentObj: {},
      HighlightID: '',
      RemarkModal: false,
      DisplayItems: '',
      actionListAll: [],
      actionList: [],
      loading: false,
      AllSelectedIDs: [],
      RamarkType: '',
    }
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount() {
    const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
    const agentObj = {}
    agentList.forEach((item) => {
      agentObj[item.ID] = item.Name
    })
    this.setState({ agentObj }, () => {
      this.initPage();
    });
  }

  async initPage() {
    const getClientData = await getClientList(this.props.username, this.props.token, this.props.userID, '');
    this.setState({ userArray: getClientData.userArray, dateArray: getClientData.dateArray })

    const getPenCData = await showPlayerPaymentFNC(this.props.username, this.props.token);
    if (getPenCData) {
      this.setState({ actionList: getPenCData, loading: false, actionListAll: getPenCData });
    } else {
      this.setState({ actionList: [], loading: false });
    }
  }

  handleChange(event, type) {
    this.setState({ [type]: event})
  }

  NumberOnly(value, field) {
    this.setState({ [field]: value });
  }

  exportExcel() {
    exportzhangbenExcel(this.state.userArray, this.state.dateArray, this.state.agentObj, this.props.username)
  }

  handleClose() {
    this.setState({
      RamarkType: '',
      RemarkModal: false,
      DisplayItems: '',
    }, () => {
      this.initPage();
    })
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  //   handleUpdate(event, type, index) {
  //     const tempInputNumber = JSON.parse(JSON.stringify(this.state.AddPayment))
  //     tempInputNumber[index][type] = event
  //     this.setState({ AddPayment: tempInputNumber})
  //   }

  selectPendingToPay(id, date) {
    if (this.state.selectedPending === `${id}-${date}`) {
      this.setState({ selectedPending: '', HighlightID: '' });
    } else {
      this.setState({ selectedPending: `${id}-${date}`, HighlightID: id  });
    }
  }

  OpenRemarkModal(item, type) {
    this.setState({ 
      DisplayItems: item,
      RemarkModal: true,
      RamarkType: type,
    })
  }

  ReturnNumberCount(NumberDisplay) {
    // return NumberDisplay;
    const DecimalNumber = NumberDisplay % 1
    return (DecimalNumber < 0.5) ? Math.floor(NumberDisplay).toLocaleString() : Math.ceil(NumberDisplay).toLocaleString()
  }

  CalculateTotal(type) {
    let userArray = this.state.actionList
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[type])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  FilterTableView(value, type) {
    let actionListAll = this.state.actionListAll
    if (value === 'All') {
      this.setState({ actionList: actionListAll, [type]: value })
    } else {
      const Filtered = actionListAll.filter((values) => values.Type === value);
      this.setState({ actionList: Filtered, [type]: value })
    }
  }

  SelectedReAllID(SelectedID, e) {
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    if (SelectedID === 'All') {
      const actionList = this.state.actionList
      let ArrayID = []
      if (e.target.checked) {
        actionList.forEach((items) => {
          ArrayID.push(items.ID)
        })
        this.setState({ SelectedAll: true })
      } else {
        ArrayID = []
        this.setState({ SelectedAll: false })
      }
      this.setState({ AllSelectedIDs: ArrayID })
    } else {
      if(AllSelectedIDs.includes(SelectedID)) {
        AllSelectedIDs = AllSelectedIDs.filter(function (el) {
          return el !== SelectedID
        });
      } else {
        AllSelectedIDs.push(SelectedID)
      }
      this.setState({ AllSelectedIDs })
    }
  }

  async ConfirmPayment(type, SelectedID) {
    const AllSelectedIDs = this.state.AllSelectedIDs
    if (type === 'Multi') {
      if (AllSelectedIDs.length > 0) {
        const updatePayments = await updatePayment(AllSelectedIDs, this.props.username, this.props.token)
        if (updatePayments.message === 'Update Success') {
          this.DisplayMEssage('成功确认', 'Success');
          this.initPage()
        }
      }
    } else {
      const updatePayments = await updatePayment(SelectedID, this.props.username, this.props.token)
      if (updatePayments.message === 'Update Success') {
        this.DisplayMEssage('成功确认', 'Success');
        this.initPage()
      }
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          {this.state.RemarkModal && 
          <RemarkModal RemarkModal={this.state.RemarkModal} handleClose={this.handleClose} DisplayItems={this.state.DisplayItems} RamarkType={this.state.RamarkType}/>}
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ marginRight: 10, color: 'black' }}>📖 账本</h2>
            </div>
            {this.state.MessageType !== '' && 
            <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', padding: 5, backgroundColor: 'rgba(255, 255, 255, .9)', borderRadius: 10 }}>
              {this.state.MessageType === 'Error' && 
            <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
              }
              {this.state.MessageType === 'Success' && 
            <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
              }
              <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F' }}>{` ${this.state.DisplayErrorMessage}`}</div>
            </div>
            }
            <div style={{ display: 'flex' }}>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150 }} onClick={() => this.exportExcel()}>{language[this.props.currentLanguage].ExporExcel}</div>
            </div>
          </div>
          <div className='dcc' style={{ overflowY: 'auto' }}>
            <div className="dcc" style={{ width: '99%', marginBottom: 50, backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: 'black', padding: 5 }}>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%/1.3)', color: 'white'}}>
                            {'ID'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Name}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Deposit}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ResponsiblePerson}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Old}
                          </div>
                          {this.state.dateArray.map((item, idx) => 
                            <div key={idx} className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', display: 'flex', flexDirection: 'row', wordBreak: 'break-word'}}>
                              <div style={{ marginRight: 10 }}>{moment(item, 'YYYYMMDD').format('DD/MM')}</div>
                              {/* <img src={deletePng} alt="Logo" style={{ height: 16, width: 16}} onClick={() => this.deletePending(item)}/> */}
                            </div>)}
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Total}
                          </div>
                          {/* <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Advance}
                          </div> */}
                          {this.props.role === 'work' &&
                          <>
                            <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', minWidth: 150, maxWidth: 100}}>
                              {language[this.props.currentLanguage].CashPending}
                            </div>
                            <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', minWidth: 150, maxWidth: 100}}>
                              {language[this.props.currentLanguage].ChequePending}
                            </div> 
                          </>
                          }
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Return Cheque'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'Remark'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'Acc Remark'}
                          </div>
                          {/* <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ReceivedCash}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ReceivedCheque}
                          </div> */}
                        </div>
                      </div>
                      {this.state.userArray.length > 0 && 
                  <>
                    {this.state.userArray.map((item, index) => {
                      return(
                        <div className='dcc highlight' key={index} style={{ padding: 10, display: 'flex', marginTop: 0, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (item.ID === this.state.HighlightID) ? '#bce3ff' : (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.3)', wordBreak: 'break-word' }}>
                              {item.ID}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Name}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.Deposit).toLocaleString()}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.state.agentObj[item.ResponsibleWorker]}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.Old).toLocaleString()}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.Advance).toLocaleString()}
                            </div> */}
                            {this.state.dateArray.map((dateItem, idxs) => <div key={idxs} className="dcc resulttable" style={{ paddingTop: 2,  paddingBottom: 2, cursor: 'pointer', backgroundColor: this.state.selectedPending === `${item.ID}-${dateItem}` && '#4A5874', flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'}} onClick={() => this.selectPendingToPay(item.ID, dateItem)}>
                              {/* <Form.Control readOnly id={`${index}${dateItem}`} value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]} type="text" style={{ minWidth: 50, maxWidth: 80 }} onKeyPress={(e) => this.onKeyEnter(e, index, dateItem)} onChange={(e) => this.handleChange(e, index, null, dateItem)}/> */}
                              {/* <div style={{ border: '1px solid black', borderRadius: 5, minWidth: 50, maxWidth: 80}}>{typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}</div> */}
                              {/* <input style={{ border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70}} readOnly value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}  onKeyPress={(e) => this.onKeyEnter(e, index, dateItem)} onChange={(e) => this.handleChange(e, index, null, dateItem)}></input> */}
                              <div style={{ border: '1px solid grey', borderRadius: 5, minWidth: 85, maxWidth: 85, background: 'white'}}>{typeof item[dateItem] === 'undefined' ? '' : this.ReturnNumberCount(Number(item[dateItem]))}</div>
                            </div>)}
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* {Number(item.Total).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.Total))}
                              {/* {this.TotalZong(item)} */}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.ReturnNumberCount(Number(item.Advance))}
                            </div> */}
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* {Number(item.ReturnedCheque).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.ReturnedCheque))}
                            </div>
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              {
                                ((item.Remark === null || item.Remark === '') ? '-' : item.Remark === '-')
                                  ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].Add}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              {
                                ((item.AccRemark === null || item.AccRemark === '') ? '-' : item.AccRemark === '-')
                                  ? <div>{'-'}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </>
                      }
                      {this.state.userArray.length === 0 &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {'No Data'}
                    </div>
                  </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', padding: 10 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h3 style={{ marginRight: 10, color: 'black' }}>{`待用户确认 (${this.state.actionList.length})`}</h3>
            </div>
            {this.state.actionList.length > 0 && <h3 style={{ marginRight: 10 }}>{`/ Total Amount => RM ${this.CalculateTotal('Amount')}`}</h3>}
            <div style={{ display: 'flex', flexDirection: 'row'}}>
              <Button style={{ minWidth: 80, marginRight: 5 }} onClick={() => this.ConfirmPayment('Multi', '')} variant="outline-success">Multi Confirm</Button>
            </div>
          </div>
          <div style={{ paddingLeft: 10, paddingBottom: 40 }}>
            <div style={{ width: '75%'}}>
              <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex',padding: 5 }}>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {'ID'}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {language[this.props.currentLanguage].DateTime}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {language[this.props.currentLanguage].StaffMember}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {'AddedBy'}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {language[this.props.currentLanguage].Amount}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {/* {language[this.props.currentLanguage].Type} */}
                    <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterType} onChange={(e) => this.FilterTableView(e.target.value, 'FilterType')}>
                      <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Type}`}</option>
                      <option value={'Cash'}>{'Cash'}</option>
                      <option value={'cheque'}>{'cheque'}</option>
                    </select>
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {'结果'}
                  </div>
                  <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                    {'Approved By'}
                  </div>
                  <div className="dcc resulttableTitle" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100, color: 'white' }}>
                    {'Action'}
                    <Form.Check
                      style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                      type="checkbox"
                      // checked={this.state.AllSelectedIDs.includes(items.ID)}
                      onChange={(e) => this.SelectedReAllID('All', e)}
                    />
                  </div>
                </div>
              </div>
              {this.state.actionList.length > 0 && 
                      <>
                        {this.state.actionList.map((items, iddn) => {
                          return(
                            <div className='dcc' key={iddn} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (iddn%2) ? '#F1F1F1' : 'white' }}>
                              <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ID}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {moment(items.DateTime).format('YYYY-MM-DD')}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {this.state.agentObj[items.Worker]}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.AddedBy}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Amount.toLocaleString()}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.Type}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApprovalStatus}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {items.ApproveBy}
                                </div>
                                <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100}}>
                                  <button style={{ marginRight: 5 }} onClick={() => this.ConfirmPayment('Self', [items.ID])}>确认</button>
                                  <Form.Check
                                    style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                                    type="checkbox"
                                    checked={this.state.AllSelectedIDs.includes(items.ID)}
                                    onChange={(e) => this.SelectedReAllID(items.ID, e)}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </>
              }
              {this.state.actionList.length === 0 && 
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                        <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                          {'No Data'}
                        </div>
                      </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    userID: state.userID,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserID, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          userID: UserID,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PlayerPage);
  