import '../App.css';
import JSEncrypt from 'jsencrypt';
import React from 'react';
import Header from '../Component/Header'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
import Form from 'react-bootstrap/Form'
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { ChangePassFrontFNC } from '../Api';

var timer

class ChangePass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: this.props.username,
      ConfirmPass: '',
      NewPass: '',
      DisplayError: '',
      DisplayErrorMessage: '',
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit() {
    const ConfirmPass = this.state.ConfirmPass
    const NewPass = this.state.NewPass
    const username = this.state.username
    if (NewPass === '') {
      this.DisplayMEssage('請輸入密碼', 'error');
    } else if (ConfirmPass === 'ConfirmPass') {
      this.DisplayMEssage('請輸入確認密碼', 'error');
    } else if (NewPass !== ConfirmPass) {
      this.DisplayMEssage('密碼錯誤', 'error');
    } else {
      const ChangePass = await ChangePassFrontFNC(NewPass, username, this.props.token)
      if (ChangePass.message === 'Password updated') {
        this.DisplayMEssage('密碼成功更改', 'success');
        this.setState({
          ConfirmPass: '',
          NewPass: '',
        })
      }
    }
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: ''
      });
    }, 3000);
  }


  render() {
    return (
      <div className='FontSize'>
        <Header/>
        <div className='dcc DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          <div className='dcc' style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            {this.state.DisplayErrorMessage !== '' && 
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row', marginBottom: 20 }}>
              {(this.state.DisplayError === 'success') && <img src={SuccessLogo} alt="Logo" style={{ width: 80 }}/>}
              {(this.state.DisplayError === 'error') && <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>}
              <div style={{ color: (this.state.DisplayError === 'success') ? 'green' : 'red', fontWeight: 'bold', fontSize: '18px'}}>{this.state.DisplayErrorMessage}</div>
            </div>
            }
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '25%', marginBottom: 20 }}>
              <div style={{ width: 'calc(100%/2)', fontWeight: 'bold', fontSize: '18px'}}>新密碼</div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control value={this.state.NewPass} placeholder='New Password' onChange={(e) => this.setState({ NewPass: e.target.value })} />
              </div>
            </div>
            <div className='dcc' style={{ display: 'flex', flexDirection: 'row', width: '25%'}}>
              <div style={{ width: 'calc(100%/2)', fontWeight: 'bold', fontSize: '18px'}}>確認密碼</div>
              <div style={{ width: 'calc(100%/1)'}}>
                <Form.Control value={this.state.ConfirmPass} placeholder='Confirm Password' onChange={(e) => this.setState({ ConfirmPass: e.target.value })} />
              </div>
            </div>
            <Button  className="ButtonLogin" style={{ flexDirection: 'row',  backgroundColor: '#ffa51e', border: 'none' }} onClick={this.handleSubmit}>
              <p1 style={{ fontSize: '18px', fontWeight: 'bold', marginRight: 5, color: 'black' }}>
                Change
              </p1>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    currentLanguage: state.currentLanguage,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserID, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          userID: UserID,
          token: UserToken,
          role: role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ChangePass);
  