import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import { language } from '../language';
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { getPaymentList, getAgentWorkerListFNC, showDailyActionFNC } from '../Api'
import moment from 'moment-timezone';
import { exportsimplifiedpaymentExcel } from '../Component/jsonToXlsx'

class SimplifiedPaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      paymentList: [],
      AllPaymentLists: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
      SelectedAgent: 'All',
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList: agentList, agentObj });
      this.initPage();
    }
  }

  async initPage() {
    let getDailyActionList;
    if (this.state.filterAgent === '') {
      getDailyActionList = await showDailyActionFNC(this.props.username, 'All', moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.state.page, this.state.countperpage, this.props.token);
    } else {
      getDailyActionList = await showDailyActionFNC(this.props.username, this.state.filterAgent, moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.state.page, this.state.countperpage, this.props.token);
    }
    this.setState({ paymentList: getDailyActionList, AllPaymentLists: getDailyActionList })
    if (getDailyActionList.length > 0) {
      this.setState({
        countpagemax: Math.ceil(
          getDailyActionList[0].FullCount / this.state.countperpage,
        ),
      });
    } else {
      this.setState({
        countpagemax: 1,
        page: 1,
      });
    }
  }

  summariedData(data) {
    let summarisedObject = {}
    for (let i = 0; i < data.length; i += 1) {
      if (!summarisedObject[data[i].User]) {
        summarisedObject[data[i].User] = {}
      }
      if (!summarisedObject[data[i].User][data[i].Name]) {
        summarisedObject[data[i].User][data[i].Name] = {
          Cash: 0,
          Cheque: 0,
        }
      }
      if (data[i].Type === 'Cash') {
        summarisedObject[data[i].User][data[i].Name].Cash += data[i].Amount
      }
    }
    const summarisedArray = []
    const summarisedAgentKeys = Object.keys(summarisedObject)
    for (let i = 0; i < summarisedAgentKeys.length; i += 1) {
      const tempArray = []
      const summarisedClientKeys = Object.keys(summarisedObject[summarisedAgentKeys[i]])
      for (let j = 0; j < summarisedClientKeys.length; j += 1) {
        tempArray.push({
          Client: summarisedClientKeys[j],
          Cash: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cash,
          Cheque: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cheque,
        })
      }
      summarisedArray.push({
        agent: summarisedAgentKeys[i],
        clientArray: tempArray,
      })
    }
    this.setState({
      paymentList: summarisedArray,
      loading: false,
    });
  }
  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.initPage())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value})
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.initPage()
    }
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.paymentList.filter((item) => item.agent === this.state.filterAgent)
      exportsimplifiedpaymentExcel(filterArray, this.state.agentObj)
    } else {
      exportsimplifiedpaymentExcel(this.state.paymentList, this.state.agentObj)
    }
  }

  changePage() {
    this.setState({ paymentList: [], AllPaymentLists: [], FilterType: 'All' }, () => {
      this.initPage();
    });
  }

  FilterTableView(value, type) {
    let AllPaymentLists = this.state.AllPaymentLists
    if (value === 'All') {
      this.setState({ paymentList: AllPaymentLists, [type]: value })
    } else {
      const Filtered = AllPaymentLists.filter((values) => values.ActionType === value);
      this.setState({ paymentList: Filtered, [type]: value })
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>日结</h2>
                <div style={{ marginRight: 10 }}>工作人员: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value='All'>{language[this.props.currentLanguage].All}</option>
                  {this.state.agentList.map((item) =>
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {/* <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.exportExcel()} variant="outline-success">Excel</Button> */}
                <div style={{ marginRight: 10}}>日期: </div>
                <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
              </div>
            </div>
            <div className="dcc" style={{ width: '99%', marginBottom: 50, backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'10%', color: 'white'}}>
                            {'ActionBy'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'10%', color: 'white'}}>
                            {'日期'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'60%', color: 'white'}}>
                            {'Action'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'20%', color: 'white'}}>
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterType} onChange={(e) => this.FilterTableView(e.target.value, 'FilterType')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Type}`}</option>
                              <option value={'Add Date Amount'}>{'Add Date Amount'}</option>
                              <option value={'Upload Payment Bill'}>{'Upload Payment Bill'}</option>
                              <option value={'Confirm Bill'}>{'Confirm Bill'}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {this.state.paymentList.length > 0 && this.state.paymentList.map((item, index) => {
                        return(
                          <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                            <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'10%', wordBreak: 'break-word' }}>
                                {item.Name}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'10%', wordBreak: 'break-word' }}>
                                {moment(item.DateTime).format('YYYY-MM-DD')}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'60%', wordBreak: 'break-word' }}>
                                {item.Action}
                              </div>
                              <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'20%', wordBreak: 'break-word' }}>
                                {item.ActionType}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                      {this.state.paymentList.length === 0 && 
                        <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                          <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                            {'No Data'}
                          </div>
                        </div>
                      }
                      {/* <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            
                          </div>
                        </div>
                      </div> */}
                      <div style={{ marginTop: 20 }}>
                        <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          {this.state.page > this.state.countpagemin ? (
                            <button  style={{ textAlign: 'center',backgroundColor: '#4A5874', color: 'white', width: 100, borderColor: '#4A5874' }} 
                              class="btn my-cusomized-button button2" 
                              onClick={() =>
                                this.setState(
                                  {
                                    page: this.state.page - 1,
                                  },
                                  () => {
                                    this.changePage(this.state.page);
                                  },
                                )
                              }>
                              {'<<'}
                            </button>
                          ) : (
                            <div style={{ width: 100 }}/>
                          )}
                          <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
                          {this.state.page < this.state.countpagemax ? (
                            <button
                              style={{ textAlign: 'center', backgroundColor: '#4A5874', color: 'white', width: 100, borderColor: '#4A5874' }}
                              class="btn my-cusomized-button button2"
                              onClick={() =>
                                this.setState(
                                  {
                                    page: this.state.page + 1,
                                  },
                                  () => {
                                    this.changePage();
                                  },
                                )
                              }>
                              {'>>'}
                            </button>
                          ) : (
                            <div style={{ width: 100 }} />
                          )}
                        </div>
                      </div>
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
            {/* <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>工作人员</th>
                  <th>客仔</th>
                  <th>Cash</th>
                  <th>Cheque</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paymentList.map((item, index) => (this.state.filterAgent === '' || (this.state.filterAgent !== '' && this.state.filterAgent === item.agent)) && 
                  <>
                    <tr key={index}>
                      <td>{this.state.agentObj[item.agent]}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {item.clientArray.map((clientitem, clientindex) => <tr key={clientindex}>
                      <td></td>
                      <td>{clientitem.Client}</td>
                      <td>{clientitem.Cash}</td>
                      <td>{clientitem.Cheque}</td>
                    </tr>)}
                  </>
                )}
              </tbody>
            </Table> */}
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SimplifiedPaymentList);
  