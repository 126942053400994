import '../App.css';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../Component/Header'
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
// import Container from 'react-bootstrap/Container'
import RemarkModal from '../Component/RemarkModal';
import moment from 'moment-timezone'
import Button from 'react-bootstrap/Button'
import deletePng from '../Image/delete.png'
import { getClientList, getAgentWorkerListFNC, addPaymentFNC } from '../Api'
// import deletePng from '../Image/delete.png'
import { exportzhangbenExcel } from '../Component/jsonToXlsx'
import { language } from '../language';

var timer

class Agent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateArray: [],
      userArray: [],
      selectedPending: '',
      // inputNumberCash: '',
      // inputNumberCek: '',
      inputNumber: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      agentList: [],
      filterAgent: '',
      agentObj: {},
      modalDate: '',
      deletePendingModalShow: false,
      deleteDate: '',
      AddPayment: [],
      HighlightID: '',
      RemarkModal: false,
      DisplayItems: '',
      SearchName: '',
      RamarkType: '',
    }
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount() {
    const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
    const agentObj = {}
    agentList.forEach((item) => {
      agentObj[item.ID] = item.Name
    })
    this.setState({ agentObj }, () => {
      this.initPage();
    });
  }

  async initPage() {
    const getClientData = await getClientList(this.props.username, this.props.token, this.props.userID, this.state.SearchName);
    // console.log('getClientData', getClientData)
    this.setState({ userArray: getClientData.userArray, dateArray: getClientData.dateArray, AddPayment: getClientData.userArray.map((item) => ({inputNumberCash: '', inputNumberCek: ''})) })
  }

  // handleChange(e, index, mode, date) {
  //   if (mode) {
  //     const tempInputNumber = JSON.parse(JSON.stringify(this.state.inputNumber))
  //     let tempValue = e.target.value.replace(/[^0-9.-]/g,'') 
  //     if (tempValue.startsWith('0')) tempValue = Number(tempValue)
  //     tempInputNumber[index][mode] = tempValue
  //     this.setState({ inputNumber: tempInputNumber });
  //   } else if (date) {
  //     if (date !== 'Remark') {
  //       const tempUserArray = JSON.parse(JSON.stringify(this.state.userArray))
  //       let tempValue = e.target.value.replace(/[^0-9.-]/g,'') 
  //       if (tempValue.startsWith('0')) tempValue = Number(tempValue)
  //       tempUserArray[index][date] = tempValue
  //       this.setState({ userArray: tempUserArray });
  //     } else {
  //       const tempUserArray = JSON.parse(JSON.stringify(this.state.userArray))
  //       tempUserArray[index][date] = e.target.value
  //       this.setState({ userArray: tempUserArray });
  //     }
  //   }
  // }

  handleChange(event, type) {
    this.setState({ [type]: event})
  }

  NumberOnly(value, field) {
    this.setState({ [field]: value });
  }

  async submitPaymentF(type, index, ID) {
    let AddPayment = this.state.AddPayment
    const userArray = this.state.userArray
    const selectedPending = this.state.selectedPending.split('-')[1]
    const selectedID = this.state.selectedPending.split('-')[0]
    const Worker = userArray[index].ResponsibleWorker
    let finalObj = {
      DateTime: selectedPending,
      Worker: Worker,
      Client: userArray[index].Name,
      type: '',
      CashAmount: '',
      ChequeAmount: '',
      UserID: ID,
    }
    // console.log('Number(selectedID)', Number(selectedID))
    if (Number(selectedID) !== 0 && Number(selectedID) !== ID) {
      this.DisplayMEssage('Error Found', 'Error');
    } else {
      if (this.state.AddPayment[index].inputNumberCek === '' && this.state.AddPayment[index].inputNumberCash !== '') {
        finalObj.type = 'Cash'
        finalObj.CashAmount = AddPayment[index].inputNumberCash
      } else if (this.state.AddPayment[index].inputNumberCek !== '' && this.state.AddPayment[index].inputNumberCash === '') {
        finalObj.type = 'cheque'
        finalObj.ChequeAmount = AddPayment[index].inputNumberCek
      } else if (this.state.AddPayment[index].inputNumberCek !== '' && this.state.AddPayment[index].inputNumberCash !== '') {
        finalObj.type = 'both'
        finalObj.ChequeAmount = AddPayment[index].inputNumberCek
        finalObj.CashAmount = AddPayment[index].inputNumberCash
      }
      const AddPaymenttoServer = await addPaymentFNC(this.props.username, finalObj, '', this.props.token);
      if (AddPaymenttoServer.message === 'Success') {
        this.DisplayMEssage('Success Add Payment', 'Success');
        this.initPage();
      }
      if (AddPaymenttoServer.error === 'Empty Amount') {
        this.DisplayMEssage(language[this.props.currentLanguage].EmptyAmount, 'Error');
      }
    }
  }

  focusNextIndex(index, date) {
    let found = false
    let foundIndex = index + 1
    while (found === false) {
      const elemToScrollTo = document.getElementById(`${foundIndex}${date}`)
      if (elemToScrollTo) {
        found = true
        elemToScrollTo.focus()
        elemToScrollTo.select();
      } else {
        foundIndex += 1
      }
      if (foundIndex > this.state.userArray.length) {
        foundIndex = 0
      }
    }
  }

  exportExcel() {
    exportzhangbenExcel(this.state.userArray, this.state.dateArray, this.state.agentObj, this.props.username)
  }

  handleClose() {
    this.setState({ 
      RamarkType: '',
      SearchName: '',
      RemarkModal: false,
      DisplayItems: '',
    }, () => {
      this.initPage();
    })
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  CalculateTotal(Totaltype) {
    let userArray = this.state.userArray
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    const DecimalNumber = Totals.TotalNumber % 1
    return (DecimalNumber < 0.5) ? Math.floor(Totals.TotalNumber).toLocaleString() : Math.ceil(Totals.TotalNumber).toLocaleString()
    // return Totals.TotalNumber.toLocaleString()
  }

  // Actions(event, type) {
  //   console.log('event', event)
  //   console.log('type', type)
  // }

  handleUpdate(event, type, index) {
    const tempInputNumber = JSON.parse(JSON.stringify(this.state.AddPayment))
    tempInputNumber[index][type] = event
    this.setState({ AddPayment: tempInputNumber})
  }

  onKeyEnter(e, type, index, ID) {
    if (e.key === 'Enter') {
      this.submitPaymentF(type, index, ID) 
    }
  }

  selectPendingToPay(id, date) {
    if (this.state.selectedPending === `${id}-${date}`) {
      this.setState({ selectedPending: '', HighlightID: '' });
    } else {
      this.setState({ selectedPending: `${id}-${date}`, HighlightID: id  });
    }
  }

  TotalZong(Selected) {
    let Totals = {
      TotalNumber: 0,
    }
    this.state.dateArray.forEach((date) => {
      Totals.TotalNumber += Number(Selected[date]) 
    })
    Totals.TotalNumber += Number(Selected['Old']) 
    return Totals.TotalNumber.toLocaleString()
  }

  CalculatezongTotal() {
    let Totals = {
      TotalNumber: 0,
    }
    const UserArray = this.state.userArray

    UserArray.forEach((array) => {
      this.state.dateArray.forEach((date) => {
        Totals.TotalNumber += Number(array[date])
      })
      Totals.TotalNumber += Number(array['Old'])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  OpenRemarkModal(item, type) {
    this.setState({ 
      DisplayItems: item,
      RemarkModal: true,
      RamarkType: type,
    })
  }

  onKeyEnterSearch(e) {
    if (e.charCode === 13) {
      this.initPage();
    }
  }

  ReturnNumberCount(NumberDisplay) {
    // return NumberDisplay;
    const DecimalNumber = NumberDisplay % 1
    // return (DecimalNumber < 0.5) ? Math.floor(NumberDisplay).toLocaleString() : Math.ceil(NumberDisplay).toLocaleString()
    return NumberDisplay.toFixed(2)
  }

  ReturnColor(Limit, see) {
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }
    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnColorText(index, number) {
    const item = this.state.userArray[index];
    const Limit =  item.Limits;
    const see =  Number(item.Total);
    const XDate = item.XDate;
    let DataArray = []
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }
    if (number === '0') {
      return ''
    }
    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      if (Number(XDate) === 0) {
        return ''
      }
      
      const dateArray = this.state.dateArray
      for (var i = 0; i < dateArray.length; i++) {
        if (item[dateArray[i]] !== '0') {
          DataArray.push(item[dateArray[i]])
        }
      }

      if (DataArray.length >= Number(XDate)) {
        color = '#FF5132'
      } else {
        color = ''
      }
    }
    return color
  }

  ReturnColorXDate(XDate, item) {
    let DataArray = []
    if (Number(XDate) === 0) {
      return ''
    }
    const dateArray = this.state.dateArray
    for (var i = 0; i < dateArray.length; i++) {
      if (item[dateArray[i]] !== '0') {
        DataArray.push(item[dateArray[i]])
      }
    }
    let color = ''
    if (DataArray.length >= Number(XDate)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnCompanyTotalColor(CompanyTotal, Total) {
    let color = ''
    if (CompanyTotal !== Total) {
      color = '#FF5132'
    } else { color = '' }
    return color
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          {this.state.RemarkModal && 
          <RemarkModal RemarkModal={this.state.RemarkModal} handleClose={this.handleClose} DisplayItems={this.state.DisplayItems} RamarkType={this.state.RamarkType}/>}
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ marginRight: 10, color: 'black' }}>📖 账本</h2>
              <div style={{ paddingLeft: 5 }}><Form.Control value={this.state.SearchName} placeholder='Search Name' onChange={(e) => this.setState({ SearchName: e.target.value })} onKeyPress={(e) => this.onKeyEnterSearch(e)} /></div>
            </div>
            {this.state.MessageType !== '' && 
            <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', padding: 5, backgroundColor: 'rgba(255, 255, 255, .9)', borderRadius: 10 }}>
              {this.state.MessageType === 'Error' && 
            <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
              }
              {this.state.MessageType === 'Success' && 
            <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
              }
              <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F' }}>{` ${this.state.DisplayErrorMessage}`}</div>
            </div>
            }
            <div style={{ display: 'flex' }}>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150 }} onClick={() => this.exportExcel()}>{language[this.props.currentLanguage].ExporExcel}</div>
            </div>
          </div>
          <div className='dcc' style={{ overflowY: 'auto' }}>
            <div className="dcc" style={{ width: '99%', marginBottom: 50, backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: 'black', padding: 5 }}>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Name}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'Limit'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'X期清1'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Deposit}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ResponsiblePerson}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Old}
                          </div>
                          {/* <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Advance'}
                          </div> */}
                          {this.state.dateArray.map((item, idx) => 
                            <div key={idx} className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', display: 'flex', flexDirection: 'row', wordBreak: 'break-word'}}>
                              <div style={{ marginRight: 10 }}>{moment(item, 'YYYYMMDD').format('DD/MM')}</div>
                              {/* <img src={deletePng} alt="Logo" style={{ height: 16, width: 16}} onClick={() => this.deletePending(item)}/> */}
                            </div>)}
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Total}
                          </div>
                          {/* <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Advance}
                          </div> */}
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Company}{language[this.props.currentLanguage].Total}
                          </div>
                          {/* <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'定金/多余'}
                          </div> */}
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'Remark'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'Acc Remark'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ReceivedCash}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ReceivedCheque}
                          </div>
                        </div>
                      </div>
                      {this.state.userArray.length > 0 && 
                  <>
                    {this.state.userArray.map((item, index) => {
                      return(
                        <div className='dcc highlight' key={index} style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (item.ID === this.state.HighlightID) ? '#bce3ff' : (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex', marginBottom: 1.5}}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Name}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: this.ReturnColor(item.Limits, Number(item.Total)) }}>
                              {`${(item.Limits === null) ? '0' : item.Limits}`}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: this.ReturnColorXDate(item.XDate, item) }}>
                              {`${(item.XDate === null) ? '-' : (Number(item.XDate) === 0) ? '' : `${item.XDate} 清 1` }`}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.Deposit).toLocaleString()}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.state.agentObj[item.ResponsibleWorker]}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.Old).toLocaleString()}
                            </div>
                            {this.state.dateArray.map((dateItem, idxs) => <div key={idxs} className="dcc resulttable" style={{ paddingTop: 2,  paddingBottom: 2, cursor: 'pointer', flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'}} onClick={() => this.selectPendingToPay(item.ID, dateItem)}>
                              {/* <Form.Control readOnly id={`${index}${dateItem}`} value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]} type="text" style={{ minWidth: 50, maxWidth: 80 }} onKeyPress={(e) => this.onKeyEnter(e, index, dateItem)} onChange={(e) => this.handleChange(e, index, null, dateItem)}/> */}
                              {/* <div style={{ border: '1px solid black', borderRadius: 5, minWidth: 50, maxWidth: 80}}>{typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}</div> */}
                              {/* <input style={{ border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70}} readOnly value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}  onKeyPress={(e) => this.onKeyEnter(e, index, dateItem)} onChange={(e) => this.handleChange(e, index, null, dateItem)}></input> */}
                              <div style={{ color: this.ReturnColorText(index, item[dateItem]),border: '1px solid grey', borderRadius: 5, minWidth: 80, maxWidth: 80, background: 'white'}}>{typeof item[dateItem] === 'undefined' ? '' : this.ReturnNumberCount(Number(item[dateItem]))}</div>
                            </div>)}
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* {Number(item.Total).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.Total))}
                              {/* {this.TotalZong(item)} */}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.ReturnNumberCount(Number(item.Advance))}
                            </div> */}
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', color: this.ReturnCompanyTotalColor(this.ReturnNumberCount(Number(item.CompanyTotal)), this.ReturnNumberCount(Number(item.Total))) }}>
                              {this.ReturnNumberCount(Number(item.CompanyTotal))}
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {Number(item.ReturnedCheque).toLocaleString()}
                            </div> */}
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              {
                                ((item.Remark === null || item.Remark === '') ? '-' : item.Remark === '-')
                                  ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].Add}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              {
                                ((item.AccRemark === null || item.AccRemark === '') ? '-' : item.AccRemark === '-')
                                  ? <div>-</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* {Number(item.ReceivedCash).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.ReceivedCash))}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* {Number(item.ReceivedCheque).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.ReceivedCheque))}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='dcc headerColorTable' style={{ display: 'flex', marginTop: 3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                      <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {'Total'}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('Deposit')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('Old')}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                        {this.CalculateTotal('Advance')}
                      </div> */}
                        {this.state.dateArray.map((dateItem, idxa) => <div key={idxa} className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal(dateItem)}
                        </div>)}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {/* {this.CalculatezongTotal()} */}
                          {this.CalculateTotal('Total')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('CompanyTotal')}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                        {this.CalculateTotal('ReturnedCheque')}
                      </div> */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', color: 'white', wordBreak: 'break-word' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', color: 'white', wordBreak: 'break-word' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('ReceivedCash')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('ReceivedCheque')}
                        </div>
                      </div>
                    </div>
                  </>
                      }
                      {this.state.userArray.length === 0 &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {'No Data'}
                    </div>
                  </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    userID: state.userID,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserID, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          userID: UserID,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Agent);
  