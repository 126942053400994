const idAvailableString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'

function nextChar(value) {
  let carry = 1;
  let res = '';
  const values = value.split('');
  for (let i = value.length - 1; i >= 0; i -= 1) {
    const char = values[i]
    let charindex = idAvailableString.indexOf(char);
    charindex += carry;

    if (charindex > 25) {
      charindex = 0;
      carry = 1;
    } else {
      carry = 0;
    }

    res = idAvailableString.charAt(charindex) + res;

    if (carry === 0) {
      res = value.substring(0, i) + res;
      break;
    }
  }

  if (carry) {
    res = `A${res}`;
  }

  const indexOfDollar = value.lastIndexOf('$')
  if (indexOfDollar >= 0 && res.lastIndexOf('$') < 0) {
    res = `$${res}`;
  }

  return res;
}

exports.nextChar = nextChar
