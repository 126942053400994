const language = {
  en: {
    Home: 'Home',
    cn: 'CN',
    en: 'EN',
    All: 'All',
    ErrorFound: 'Error: Operation Error',
    EmptyAmount: 'Error: Please Insert Amount',
    PlesaseInsertPassword: 'Error: Please Insert Password',
    PlesaseInsertUsername: 'Error: Please Insert Username',
    PleaseInsertName: 'Error: Please Insert Name',
    Loading: 'Loading ... Please Wait a moment',
    SuccessfulUpdateUsers: 'Success: Successful Updated User',
    CreateUserAccount: 'Success: Create User Account',
    Username: 'Username',
    DonotHaveAutho: 'Error: you do not have Authorization',
    PleaseSelectRole: 'Error: Please Select Role',
    SuccessDeleteUser: 'Success: Successful Deleted User.',
    DeleteUSerConfirmation: 'Are you sure you want to delete this user ?',
    StaffMember: 'Staff Member',
    PleaseSelectResponsibleWorker: 'Error: Please Select ResponsibleWorker',
    DateTime: 'DateTime',
    Guest: 'Guest',
    Amount: 'Amount',
    Type: 'Type',
    Upperline: 'Upperline',
    Role: 'Role',
    Close: 'Close',
    Submit: 'Submit',
    confirm: 'Confirm',
    MultiConfirm: 'Multi Confirm',
    SendNoti: 'Send Noti',
    UploadbyExcel: 'Upload by Excel',
    ExporExcel: 'Export Excel',
    Name: 'Name',
    Deposit: 'Deposit',
    ResponsiblePerson: 'Responsible Person',
    Old: 'Old',
    Total: 'Total',
    Advance: 'Advance',
    CashPending: 'Cash',
    ChequePending: 'Cheque',
    ReceivedCash: 'Received Cash',
    ReceivedCheque: 'Received Cheque',
    View: 'View',
    Add: 'Add',
    CashDisplay: 'Cash',
    ChequeDisplay: 'cheque',
    AddedDate: 'Add Date',
    Search: 'Search',
    Company: 'Company ',
    UploadCompanyTotal: 'Upload Company Total',
  },
  cn: {
    Home: '首页',
    cn: '华语',
    en: '英语',
    All: '全部',
    ErrorFound: '错误: 操作有误',
    EmptyAmount: '错误: 请输了数额',
    PlesaseInsertPassword: '错误: 请输入密码',
    PlesaseInsertUsername: '错误: 请输入用户名',
    PleaseInsertName: '错误: 请输入名称',
    Loading: '加载 ... 请稍等片刻',
    SuccessfulUpdateUsers: '成功: 更改用户',
    CreateUserAccount: '成功: 增加工作人员',
    Username: '用户名',
    DonotHaveAutho: '错误: 您没有此功能的权限',
    PleaseSelectRole: '错误: 请选择用户角色',
    SuccessDeleteUser: '成功: 删去选择用户',
    DeleteUSerConfirmation: '您确定要删除此用户 ?',
    StaffMember: '工作人员',
    PleaseSelectResponsibleWorker: '错误: 请选择用户负责worker',
    DateTime: '日期时间',
    Guest: '客仔',
    Amount: '数目',
    Type: '类型',
    Upperline	: '上线	',
    Role: '角色',
    Close: '关闭',
    Submit: '提交',
    confirm: '确认',
    MultiConfirm: '多重确认',
    SendNoti: '发送简讯',
    UploadbyExcel: '上传 Excel',
    ExporExcel: '下载 Excel',
    Name: '名字',
    Deposit: '订金',
    ResponsiblePerson: '负责人',
    Old: '旧',
    Total: '总',
    Advance: 'Advance',
    CashPending: '待收现金',
    ChequePending: '待收支票',
    ReceivedCash: '已收现金',
    ReceivedCheque: '已收支票',
    View: '查看',
    Add: '增加',
    CashDisplay: '现金',
    ChequeDisplay: '支票',
    AddedDate: '添加日期',
    Search: '搜索',
    Company: '公司',
    UploadCompanyTotal: '上传公司縂',
  },
}

exports.language = language
  