export const tableName = {
  shouzhangPending: '待收',
  shouzhangUser: '工作人员',
  shouzhangClient: '客仔',
}

export const actionName = {
  'add pending': '增加待收',
  'clear 0': '归零',
  add: '增加',
  delete: '删除',
  edit: '更改',
}