

const initialState = {
  currentLanguage: 'cn',
  token: '',
  role: '',
  username: '',
  userID: '',
}
  
function reducer(state = initialState, action) {
  switch (action.type) {
  case 'SET_LANGUAGE':
    sessionStorage.setItem("currentLanguage", action.payload);
    return {
      ...state,
      currentLanguage: action.payload,
    };
  case 'LOGIN_STATUS':
    return {
      ...state,
      username: action.payload.username,
      userID: action.payload.userID,
      role: action.payload.role,
      token: action.payload.token,
    };
  default:
    return state;
  }
}
  
export default reducer
  