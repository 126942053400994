import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
import { language } from '../language';
import Header from '../Component/Header'
// import Container from 'react-bootstrap/Container'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { getAgentList, updateAgent, createAgent, DeleteUserF } from '../Api'
var timer

class AgentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      agentList: [],
      addAgentModalShow: false,
      DisplayError: false,
      DisplayErrorModal: false,
      DisplayErrorMessage: '',
      MessageType: '',
      WorkUser: [],
      SortView: 'none',
      AllDataList: '',
      modalInput: {
        username: '',
        password: '',
        name: '',
        role: 'agent',
        ResponsibleWorker: '',
        Upline: '',
      },
      SearchName: '',
    }
    this.handleClose = this.handleClose.bind(this)
  }

  componentDidMount() {
    // console.log('this.props.username', this.props.username)
    // console.log('this.props.token', this.props.token)
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'admin') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      this.initPage()
    }
  }

  async getWorkUser() {
    // const SearchName = this.state.SearchName
    const getWorkData = await getAgentList(this.props.username, this.props.token, 'details', '');
    // console.log('getWorkData', getWorkData)
    const OnlyWork = getWorkData.filter(function(item){
      return item.Role === 'work';
    })
    this.setState({ WorkUser: OnlyWork })
  }

  async initPage() {
    this.getWorkUser();
    const SearchName = this.state.SearchName
    const getAgentData = await getAgentList(this.props.username, this.props.token, 'notdetail', SearchName);
    // console.log('getAgentData ==', getAgentData)
    if(getAgentData.error) {
      this.setState({
        loading: false,
      });
    } else {
      this.setState({
        agentList: getAgentData,
        AllDataList: getAgentData,
        loading: false,
      });
    }
  }

  handleChange(e, index, mode) {
    const tempData = JSON.parse(JSON.stringify(this.state.agentList))
    tempData[index][mode] = e.target.value
    this.setState({ agentList: tempData })
  }

  handleModalInputChange(e, key) {
    const tempData = JSON.parse(JSON.stringify(this.state.modalInput))
    tempData[key] = e.target.value
    this.setState({ modalInput: tempData })
  }

  filterViews(event) {
    let agentList = this.state.agentList
    let AllDataList = this.state.AllDataList
    if(event.target.value === 'all') {
      agentList = AllDataList
    } else {
      agentList = AllDataList.filter(function (el) {
        return el.Role === event.target.value
      });
    }
    this.setState({ agentList })
  }

  async HandleEditUsers(index) {
    const agentList = this.state.agentList[index]
    const ChangeName = agentList.Name
    const Password = agentList.Password
    const Role = agentList.Role
    const ResponsibleWorker =(agentList.ResponsibleWorker === null) ? '' : agentList.ResponsibleWorker
    const Upline = (agentList.Upline === null) ? '' : agentList.Upline
    const Limits = agentList.Limits
    const XDate = agentList.XDate

    if (Password === '') {
      this.DisplayMEssage(language[this.props.currentLanguage].PlesaseInsertPassword, 'Error');
    } else if (ChangeName === '') {
      this.DisplayMEssage(language[this.props.currentLanguage].PleaseInsertName, 'Error');
    } else {
      const updateAgentResult = await updateAgent(this.props.username, this.props.token, agentList.ID, Password, Role, ChangeName, ResponsibleWorker, Upline, Limits, XDate)
      if (updateAgentResult.message === 'successful') {
        this.DisplayMEssage(language[this.props.currentLanguage].SuccessfulUpdateUsers, 'Success');
        this.initPage()
      }
    }
  }

  async DeleteSelectUser(SelectedID) {
    // console.log('SelectedID', SelectedID)
    const r = (window.confirm(language[this.props.currentLanguage].DeleteUSerConfirmation));
    if (r === true) {
      const DeleteUser = await DeleteUserF(SelectedID, this.props.username, this.props.token)
      if (DeleteUser.message === 'successful') {
        this.DisplayMEssage(language[this.props.currentLanguage].SuccessDeleteUser, 'Success');
        this.initPage()
      }
    } else {
      return null;
    }
  }

  handleClose() {
    this.setState({ 
      addAgentModalShow: false,
      modalInput: {
        SearchName: '',
        username: '',
        password: '',
        name: '',
        role: 'agent',
        ResponsibleWorker: '',
        Upline: '',
      },
    })
  }

  async handleSubmit() {
    if (this.state.modalInput.username === '') {
      this.DisplayMEssageModal(language[this.props.currentLanguage].PlesaseInsertUsername);
    } else if (this.state.modalInput.password === '') {
      this.DisplayMEssageModal(language[this.props.currentLanguage].PlesaseInsertPassword);
    } else if (this.state.modalInput.name === '') {
      this.DisplayMEssageModal(language[this.props.currentLanguage].PleaseInsertName);
    } else if (this.state.modalInput.role === '') {
      this.DisplayMEssageModal(language[this.props.currentLanguage].PleaseSelectRole);
    } else if (this.state.modalInput.ResponsibleWorker === '') {
      this.DisplayMEssageModal(language[this.props.currentLanguage].PleaseSelectResponsibleWorker);
    } else {
      const createAgentResult = await createAgent(this.props.username, this.props.token, {
        NewAgentUsername: this.state.modalInput.username,
        NewAgentPassword: this.state.modalInput.password,
        NewAgentName: this.state.modalInput.name,
        NewAgentRole: this.state.modalInput.role,
        NewResponsibleWorker: this.state.modalInput.ResponsibleWorker,
        NewUpline: this.state.modalInput.Upline,
      });
      if (createAgentResult.message === 'invalid authorization') {
        this.DisplayMEssageModal(language[this.props.currentLanguage].DonotHaveAutho);
      }
      if (createAgentResult.message === 'successful') {
        this.DisplayMEssage(language[this.props.currentLanguage].CreateUserAccount, 'Success');
        this.setState({ 
          addAgentModalShow: false,
          modalInput: {
            username: '',
            password: '',
            name: '',
            role: '',
          },
        })
        this.initPage()
      }
    } 
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
        DisplayErrorModal: false,
      });
    }, 5000);
  }

  DisplayMEssageModal(Message) {
    this.setState({ DisplayErrorMessage: Message, DisplayErrorModal: true})
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        MessageType: '',
        DisplayErrorModal: false,
      });
    }, 3000);
  }

  SortView() {
    const SortView = this.state.SortView
    let agentList = this.state.agentList
    let AllDataList = JSON.parse(JSON.stringify(this.state.AllDataList))
    if (SortView === 'none') {
      agentList = AllDataList.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
      this.setState({ SortView: 'small' })
    }
    if (SortView === 'small') {
      agentList = AllDataList.sort((a, b) => (a.Username > b.Username) ? -1 : 1)
      this.setState({ SortView: 'big' })
    }
    if (SortView === 'big') {
      agentList = AllDataList
      this.setState({ SortView: 'none' })
    }
    this.setState({ agentList })
  }

  onKeyEnterSearch(e) {
    if (e.key === 'Enter') { 
      this.initPage();
    }
  }

  render() {
    const DisplayCustomer = this.state.agentList
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> {language[this.props.currentLanguage].Loading}</div>
        ) : (
          <div className='DashboardBackground' style={{paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60}}>
            <Modal centered show={this.state.addAgentModalShow} onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  增加客仔
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {this.state.DisplayErrorModal === true && 
         <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
           <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
           <div style={{ fontWeight: 'bold', fontSize: '19px', color: 'red'}}>{` ${this.state.DisplayErrorMessage}`}</div>
         </div>
                }
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>用户名</div>
                  <Form.Control value={this.state.modalInput.username} type="text" onChange={(e) => this.handleModalInputChange(e, 'username')}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>密码</div>
                  <Form.Control value={this.state.modalInput.password} type="text" onChange={(e) => this.handleModalInputChange(e, 'password')}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>名字</div>
                  <Form.Control value={this.state.modalInput.name} type="text" onChange={(e) => this.handleModalInputChange(e, 'name')}/>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>{language[this.props.currentLanguage].Role}</div>
                  <Form.Select value={this.state.modalInput.role} onChange={(e) => this.handleModalInputChange(e, 'role')}>
                    <option value='agent'>Agent</option>
                    <option value='player'>Player</option>
                  </Form.Select>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>Worker</div>
                  <Form.Select style={{ minWidth: 20 }} value={this.state.modalInput.ResponsibleWorker} onChange={(e) => this.handleModalInputChange(e, 'ResponsibleWorker')}>
                    <option value="">{}</option>
                    {this.state.WorkUser.map((UserUpline, idd) => {
                      return <option key={idd} value={UserUpline.ID}>{UserUpline.Username}</option>
                    })}
                  </Form.Select>
                </div>
                {/* {this.state.modalInput.role === 'player' && 
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                  <div style={{ width: 100 }}>{language[this.props.currentLanguage].Upperline}</div>
                  <Form.Select value={this.state.modalInput.Upline} onChange={(e) => this.handleModalInputChange(e, 'Upline')}>
                    <option value="">{}</option>
                    {this.state.agentList.filter(function(item){
                      return item.Role !== 'player';
                    }).map((UsersUpline, idx) => {
                      return <option key={idx} value={UsersUpline.ID}>{UsersUpline.Username}</option>
                    })}
                  </Form.Select>
                </div>
                } */}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
                <Button variant="success" onClick={() => this.handleSubmit()}>提交</Button>
              </Modal.Footer>
            </Modal>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ textAlign: 'left' }}>🖉 客仔</h2>
                <div style={{ paddingLeft: 10 }}><Form.Control value={this.state.SearchName} placeholder='Search Name' onChange={(e) => this.setState({ SearchName: e.target.value })} onKeyPress={(e) => this.onKeyEnterSearch(e)} /></div>
              </div>
              {this.state.DisplayError === true && 
         <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row' }}>
           {this.state.MessageType === 'Error' && 
              <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
           }
           {this.state.MessageType === 'Success' && 
              <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
           }
           <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
         </div>
              }
              <Button style={{ minWidth: 80 }} onClick={() => this.setState({ addAgentModalShow: true })} variant="primary">添加</Button>
            </div>
            <Table striped bordered hover responsive style={{ backgroundColor: 'white' }}>
              <thead>
                <tr>
                  <th>No</th>
                  <th style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>用户名</div><div style={{ cursor: 'pointer'}} onClick={() => this.SortView()}>⧨</div>
                  </th>
                  <th>密码</th>
                  <th>名字</th>
                  <th style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div>{language[this.props.currentLanguage].Role}</div>
                    <Form.Select onChange={(e) => this.filterViews(e)} style={{ width: '50%', textAlign: 'center'}}>
                      <option value='all'>All</option>
                      <option value='agent'>Agent</option>
                      <option value='player'>Player</option>
                    </Form.Select>
                  </th>
                  <th>Worker</th>
                  <th style={{ width: 100}}>Limit</th>
                  <th style={{ width: 100}}>XDate</th>
                  {/* <th>{language[this.props.currentLanguage].Upperline}</th> */}
                  <th>行动</th>
                </tr>
              </thead>
              <tbody>
                {DisplayCustomer.map((item, index) => <tr key={index}>
                  <td>{item.ID}</td>
                  <td>
                    {/* <Form.Control style={{ minWidth: 100 }} value={item.Username} type="text" placeholder="用户名" onKeyPress={(e) => this.onKeyEnter(e, index, 'username')} onChange={(e) => this.handleChange(e, index, 'Username')}/> */}
                    <div style={{ minWidth: 100, fontWeight: 'bold' }}>{item.Username}</div>
                  </td>
                  <td>
                    {/* <Form.Control style={{ minWidth: 100 }} value={item.Password} type="text" placeholder="密码" onKeyPress={(e) => this.onKeyEnter(e, index, 'password')} onChange={(e) => this.handleChange(e, index, 'Password')}/> */}
                    <Form.Control style={{ minWidth: 100 }} value={item.Password} type="text" placeholder="密码" onChange={(e) => this.handleChange(e, index, 'Password')}/>
                  </td>
                  <td>
                    {/* <Form.Control style={{ minWidth: 100 }} value={item.Name} type="text" placeholder="名字" onKeyPress={(e) => this.onKeyEnter(e, index, 'name')} onChange={(e) => this.handleChange(e, index, 'Name')}/> */}
                    <Form.Control style={{ minWidth: 100 }} value={item.Name} type="text" placeholder="名字" onChange={(e) => this.handleChange(e, index, 'Name')}/>
                  </td>
                  <td>
                    <Form.Select style={{ minWidth: 100 }} value={item.Role} onChange={(e) => this.handleChange(e, index, 'Role')}>
                      <option value='agent'>Agent</option>
                      <option value='player'>Player</option>
                    </Form.Select>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Form.Select style={{ minWidth: 20 }} value={item.ResponsibleWorker} onChange={(e) => this.handleChange(e, index, 'ResponsibleWorker')}>
                      <option value="">{}</option>
                      {this.state.WorkUser.map((UserUpline, idd) => {
                        return <option key={idd} value={UserUpline.ID}>{UserUpline.Username}</option>
                      })}
                    </Form.Select>
                  </td>
                  {/* <td style={{ textAlign: 'center' }}>
                    <Form.Select style={{ minWidth: 20 }} disabled={(item.Role === 'agent') ? true : false} value={item.Upline} onChange={(e) => this.handleChange(e, index, 'Upline')}>
                      <option value="">{}</option>
                      {this.state.agentList.filter(function(item){
                        return item.Role !== 'player';
                      }).map((UsersUpline, idx) => {
                        return <option key={idx} value={UsersUpline.ID}>{UsersUpline.Username}</option>
                      })}
                    </Form.Select>
                  </td> */}
                  <td style={{ width: 100}}><Form.Control style={{ width: '100%' }} value={item.Limits} type="text" placeholder="Limit" onChange={(e) => this.handleChange(e, index, 'Limits')}/></td>
                  <td style={{ width: 100}}><Form.Control style={{ width: '100%' }} value={item.XDate} type="text" placeholder="XDate" onChange={(e) => this.handleChange(e, index, 'XDate')}/></td>
                  <td className='dcc'>
                    <Button className='button' style={{ marginRight: 5, backgroundColor: 'white', borderColor: 'blue' }} onClick={() => this.HandleEditUsers(index)}>✏</Button>
                    <Button className='button' style={{ color: 'white', backgroundColor: 'white', borderColor: 'red' }} onClick={() => this.DeleteSelectUser(item.ID)}>🗑️</Button>
                  </td>
                </tr>)}
              </tbody>
            </Table>
          </div>
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(AgentList);
  