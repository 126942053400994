// import xlsx from 'xlsx'
import xlsx from 'xlsx-js-style'
import moment from 'moment-timezone'
import { nextChar } from './nextChar'
// import { tableName, actionName } from './static'

function stylings(value, color, type, username) {
  let colorBackground = {}
  if (color === 'blue') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "ADD8E6"},
        bgColor: {rgb: "ADD8E6"}
      }
    }
  }
  if (color === 'g') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "dddddd"},
        bgColor: {rgb: "dddddd"}
      }
    }
  }
  if (color === 's') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "9FA7FF"},
        bgColor: {rgb: "9FA7FF"}
      }
    }
  }
  if (color === 'color7') {
    colorBackground = {
      fill: {
        patternType: 'solid',
        fgColor: {rgb: "f1cd4a"},
        bgColor: {rgb: "f1cd4a"}
      }
    }
  } 

  return {v: username ? `[${value}]` : value, t: type === "n" ? "n" : "s", s: {
    border: {
      top: {style: 'thin', color:{rgb: "000"}}, 
      bottom: {style: 'thin', color:{rgb: "000"}}, 
      left: {style: 'thin', color:{rgb: "000"}}, 
      right: {style: 'thin', color:{rgb: "000"}}
    },
    // font:{
    //   sz: '12',
    // },
    alignment: {
      horizontal: 'center',
      wrapText: true,
    },
    ...colorBackground,
  }}
}

export function exportzhangbenExcel(userArray, dateArray, agentObj, agent) {

  let currentAlphabet = 'F'
  let objArray = {
    A: stylings('ID', 'blue', 's'),
    B: stylings('名字', 'blue', 's'),
    C: stylings('负责人', 'blue', 's'),
    D: stylings('旧', 'blue', 's'),
    E: stylings('Advance', 'blue', 's'),
  }
  let alphabetArray = ['A', 'B', 'C', 'D', 'E']
  for(let i = 0; i < dateArray.length; i += 1) {
    objArray[currentAlphabet] = stylings(moment(dateArray[i]).format('DD/MM'), 'blue', 's')
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }
  let leftoverArray = ['总', 'Return Cheque', 'Remark', '现金', '支票']
  for(let i = 0; i < leftoverArray.length; i += 1) {
    objArray[currentAlphabet] = stylings(leftoverArray[i], 'blue', 's')
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }
  
  let ws = xlsx.utils.json_to_sheet([{...objArray}],{header: alphabetArray, skipHeader: true})
  const total = {
    'D': 0, 'E': 0, 'F': 0, 'G': 0, 'H': 0, 'I': 0, 'J': 0, 'K': 0, 'L': 0, 'M': 0, 'N': 0, 'O': 0, 'P': 0, 'Q': 0,
  }
  for (let i = 0; i < userArray.length; i += 1) {
    currentAlphabet = 'F'
    objArray = {
      A: stylings(userArray[i].ID, '', 's'),
      B: stylings(userArray[i].Name, '', 's'),
      C: stylings(agentObj[userArray[i].ResponsibleWorker], '', 's'),
      D: stylings(userArray[i].Old, '', 's'),
      E: stylings(userArray[i].Advance, '', 's'),
    }
    total.D += Number(userArray[i].Old)
    total.E += Number(userArray[i].Advance)
    alphabetArray = ['A', 'B', 'C', 'D', 'E']
    for(let j = 0; j < dateArray.length; j += 1) {
      total[currentAlphabet] += Number(userArray[i][dateArray[j]])
      objArray[currentAlphabet] = stylings(userArray[i][dateArray[j]], '', 's')
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    // leftoverArray = ['Total', 'ReturnedCheque']
    leftoverArray = ['Total', 'ReturnedCheque', 'Remark', 'ReceivedCash', 'ReceivedCheque']
    for(let k = 0; k < leftoverArray.length; k += 1) {
      total[currentAlphabet] += Number(userArray[i][leftoverArray[k]])
      objArray[currentAlphabet] = stylings(userArray[i][leftoverArray[k]], '', 's')
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    
    xlsx.utils.sheet_add_json(ws, [{...objArray}], { header: alphabetArray, skipHeader: true, origin: -1 })
  }
  
  xlsx.utils.sheet_add_json(ws, [{A: stylings('', 'g'), B: stylings('', 'g'), C: stylings('Total', 'g', 's'), D: stylings(total.D.toFixed(2), 'g', 'n'), 
    E: stylings(total.E.toFixed(2), 'g', 'n'), F: stylings(total.F.toFixed(2), 'g', 'n'), G: stylings(total.G.toFixed(2), 'g', 'n'), H: stylings(total.H.toFixed(2), 'g', 'n'), 
    I: stylings(total.I.toFixed(2), 'g', 'n'), J: stylings(total.J.toFixed(2), 'g', 'n'), K: stylings(total.K.toFixed(2), 'g', 'n'), L: stylings(total.L.toFixed(2), 'g', 'n'), 
    M: stylings(total.M.toFixed(2), 'g', 'n'), N: stylings(total.N.toFixed(2), 'g', 'n'), O: stylings('-', 'g', 'n'), P: stylings(total.P.toFixed(2), 'g', 'n'), Q: stylings(total.Q.toFixed(2), 'g', 'n'),
  }], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', ], skipHeader: true, origin: -1 })
  ws['!cols'] = [{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90},{wpx: 90}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`账本`)
  wb.Sheets['账本'] = ws
  xlsx.writeFile(wb, `${agent} 账本 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportagentzhangbenExcel(userArray, dateArray, agentObj) {
  let currentAlphabet = 'D'
  let objArray = {
    A: 'ID',
    B: '名字',
  }
  let alphabetArray = ['A', 'B']
  for(let i = 0; i < dateArray.length; i += 1) {
    objArray[currentAlphabet] = dateArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }
  let leftoverArray = ['剩余', '定金/多余', '已收现金', '已收支票', '注释']
  for(let i = 0; i < leftoverArray.length; i += 1) {
    objArray[currentAlphabet] = leftoverArray[i]
    alphabetArray.push(currentAlphabet)
    currentAlphabet = nextChar(currentAlphabet)
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: alphabetArray, skipHeader: true})
  for (let i = 0; i < userArray.length; i += 1) {
    currentAlphabet = 'D'
    objArray = {
      A: userArray[i].ID,
      B: userArray[i].Name,
    }
    alphabetArray = ['A', 'B']
    for(let j = 0; j < dateArray.length; j += 1) {
      objArray[currentAlphabet] = userArray[i][dateArray[j]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    
    leftoverArray = ['Balance', 'Extra', 'Cash', 'Cheque', 'Remark']
    for(let k = 0; k < leftoverArray.length; k += 1) {
      objArray[currentAlphabet] = userArray[i][leftoverArray[k]]
      alphabetArray.push(currentAlphabet)
      currentAlphabet = nextChar(currentAlphabet)
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: alphabetArray, skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`账本`)
  wb.Sheets['账本'] = ws
  xlsx.writeFile(wb, `账本 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportclientExcel(clientList, agentObj) {
  let objArray = {
    A: 'ID',
    B: '名字',
    C: '定金/多余',
    D: '已收现金',
    E: '已收支票',
    F: '负责人',
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  for (let i = 0; i < clientList.length; i += 1) {
    objArray = {
      A: clientList[i].ID,
      B: clientList[i].Name,
      C: clientList[i].Extra,
      D: clientList[i].Cash,
      E: clientList[i].Cheque,
      F: agentObj[clientList[i].ResponsibleUser],
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`客仔`)
  wb.Sheets['客仔'] = ws
  xlsx.writeFile(wb, `客仔 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportpaymentExcel(paymentList, agentObj) {
  // console.log('paymentList', paymentList)
  let objArray = {
    A: 'ID',
    B: '日期时间',
    C: '客仔',
    D: '工作人员',
    E: '数目',
    F: '款项',
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})
  for (let i = 0; i < paymentList.length; i += 1) {
    objArray = {
      A: paymentList[i].ID,
      B: moment(paymentList[i].DateTime).format('YYYY-MM-DD HH:mm:ss'),
      C: paymentList[i].Name,
      D: agentObj[paymentList[i].Worker],
      E: paymentList[i].Amount,
      F: paymentList[i].Type,
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`交易`)
  wb.Sheets['交易'] = ws
  xlsx.writeFile(wb, `交易 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}

export function exportsimplifiedpaymentExcel(paymentList, agentObj, role) {
  if (role && role === 'agent') {
    let objArray = {
      A: '客仔',
      B: 'Cash',
      C: 'Cheque',
    }
  
    let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C'], skipHeader: true})
    for (let i = 0; i < paymentList.length; i += 1) {
      objArray = {
        A: paymentList[i].Client,
        B: paymentList[i].Cash,
        C: paymentList[i].Cheque,
      }
      xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
    }
    let wb = { SheetNames: [], Sheets: {} }
    wb.SheetNames.push(`日结`)
    wb.Sheets['日结'] = ws
    xlsx.writeFile(wb, `日结 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  } else {
    let objArray = {
      A: '工作人员',
      B: '客仔',
      C: 'Cash',
      D: 'Cheque',
    }
  
    let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D'], skipHeader: true})
    for (let i = 0; i < paymentList.length; i += 1) {
      objArray = {
        A: agentObj[paymentList[i].agent],
        B: '',
        C: '',
        D: '',
      }
      xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
      for (let j = 0; j < paymentList[i].clientArray.length; j += 1) {
        objArray = {
          A: '',
          B: paymentList[i].clientArray[j].Client,
          C: paymentList[i].clientArray[j].Cash,
          D: paymentList[i].clientArray[j].Cheque,
        }
        xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D'], skipHeader: true, origin: -1 })
      }
    }
    let wb = { SheetNames: [], Sheets: {} }
    wb.SheetNames.push(`日结`)
    wb.Sheets['日结'] = ws
    xlsx.writeFile(wb, `日结 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
  }
}

export function exportActionExcel(actionList, agentObj, Date) {
  let objArray = {
    A: '工作人员',
    B: '',
    C: '日期',
    D: `${Date.fromDate} - ${Date.toDate}`,
  }
  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D'], skipHeader: true})
  xlsx.utils.sheet_add_json(ws, [{A: ''}], { header: ['A'], skipHeader: true, origin: -1 })
  objArray = {
    A: stylings('ID', 'blue'),
    B: stylings('日期时间', 'blue'),
    C: stylings('客仔', 'blue'),
    D: stylings('工作人员', 'blue'),
    E: stylings('AddedBy', 'blue'),
    F: stylings('数目', 'blue'),
    G: stylings('类别', 'blue'),
    H: stylings('结果', 'blue'),
    I: stylings('ApprovedBy', 'blue'),
  }
  xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], skipHeader: true, origin: -1 })
  for (let i = 0; i < actionList.length; i += 1) {
    objArray = {
      A: actionList[i].ID,
      B: moment(actionList[i].DateTime).format('YYYY-MM-DD'),
      C: actionList[i].Client,
      D: agentObj[actionList[i].Worker],
      E: actionList[i].AddedBy,
      F: actionList[i].Amount,
      G: actionList[i].Type,
      H: actionList[i].ApprovalStatus,
      I: actionList[i].ApproveBy,
    }
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I'], skipHeader: true, origin: -1 })
  }

  ws['!cols'] = [{wpx: 60},{wpx: 70},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 70},{wpx: 70},{wpx: 100},{wpx: 100}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`活动`)
  wb.Sheets['活动'] = ws
  xlsx.writeFile(wb, `活动 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}


export function exportclienthistoryExcel(clientList, agentObj) {
  let objArray = {
    A: stylings('名字', 'blue', 's'),
    B: stylings('收款日期', 'blue', 's'),
    C: stylings('Cheque', 'blue', 's'),
    D: stylings('Cash', 'blue', 's'),
    E: stylings('Total', 'blue', 's'),
    F: stylings('Remark', 'blue', 's'),
  }

  let ws = xlsx.utils.json_to_sheet([objArray],{header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true})

  let totalAmount = 0;
  let totalAmountCash = 0;
  let totalAmountCheque = 0;

  for (let i = 0; i < clientList.length; i += 1) {
    objArray = {
      A: clientList[i].Client,
      B: stylings(moment(clientList[i].DateTime).format('DD/MM'), '', 's'),
      C: stylings(clientList[i].AmountCheque, '', 's'),
      D: stylings(clientList[i].AmountCash, '', 's'),
      E: stylings(clientList[i].Amount, '', 's'),
      F: stylings((clientList[i].Remark === null) ? '' : clientList[i].Remark, '', 's')
    }
    totalAmountCash += clientList[i].AmountCash;
    totalAmountCheque += clientList[i].AmountCheque;
    totalAmount += clientList[i].Amount;
    xlsx.utils.sheet_add_json(ws, [objArray], { header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 })
  }

  const totalRow = {
    A: stylings('', 'blue', 's'),
    B: stylings('总计', 'blue', 's'),
    C: stylings(totalAmountCheque.toLocaleString(), 'blue', 's'),
    D: stylings(totalAmountCash.toLocaleString(), 'blue', 's'),
    E: stylings(totalAmount.toLocaleString(), 'blue', 's'),
    F: stylings('', 'blue', 's'),
  };
  xlsx.utils.sheet_add_json(ws, [totalRow], {header: ['A', 'B', 'C', 'D', 'E', 'F'], skipHeader: true, origin: -1 });

  ws['!cols'] = [{wpx: 100},{wpx: 40},{wpx: 100},{wpx: 100},{wpx: 100},{wpx: 100}];
  let wb = { SheetNames: [], Sheets: {} }
  wb.SheetNames.push(`历史记录`)
  wb.Sheets['历史记录'] = ws
  xlsx.writeFile(wb, `历史记录 ${moment().format('YYYY-MM-DD HH:mm:ss')}.xlsx`)
}
