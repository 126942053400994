import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { getPaymentList, getAgentList } from '../Api'
import moment from 'moment-timezone';
import { exportsimplifiedpaymentExcel } from '../Component/jsonToXlsx'

class SimplifiedPaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      paymentList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '',
      toAmount: '',
    }
  }

  async componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'obs') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      // const agentList = await getAgentList(this.props.username, this.props.token)
      // const agentObj = {}
      // agentList.forEach((item) => {
      //   agentObj[item.ID] = item.Name
      // })
      // this.setState({ agentList, agentObj });
      // this.initPage()
    }
  }

  async initPage() {
    // const getPaymentData = await getPaymentList(this.props.username, this.props.token, this.state.fromDate, this.state.toDate, this.state.fromAmount, this.state.toAmount);
    // this.summariedData(getPaymentData)
  }

  summariedData(data) {
    let summarisedObject = {}
    for (let i = 0; i < data.length; i += 1) {
      if (!summarisedObject[data[i].User]) {
        summarisedObject[data[i].User] = {}
      }
      if (!summarisedObject[data[i].User][data[i].Name]) {
        summarisedObject[data[i].User][data[i].Name] = {
          Cash: 0,
          Cheque: 0,
        }
      }
      if (data[i].Type === 'Cash') {
        summarisedObject[data[i].User][data[i].Name].Cash += data[i].Amount
      }
    }
    const summarisedArray = []
    const summarisedAgentKeys = Object.keys(summarisedObject)
    for (let i = 0; i < summarisedAgentKeys.length; i += 1) {
      const tempArray = []
      const summarisedClientKeys = Object.keys(summarisedObject[summarisedAgentKeys[i]])
      for (let j = 0; j < summarisedClientKeys.length; j += 1) {
        tempArray.push({
          Client: summarisedClientKeys[j],
          Cash: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cash,
          Cheque: summarisedObject[summarisedAgentKeys[i]][summarisedClientKeys[j]].Cheque,
        })
      }
      summarisedArray.push({
        agent: summarisedAgentKeys[i],
        clientArray: tempArray,
      })
    }
    this.setState({
      paymentList: summarisedArray,
      loading: false,
    });
  }
  changeDate(value, key) {
    this.setState({ [key]: value}, () => this.initPage())
  }

  filterAgent(e) {
    this.setState({ filterAgent: e.target.value})
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.initPage()
    }
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.paymentList.filter((item) => item.agent === this.state.filterAgent)
      exportsimplifiedpaymentExcel(filterArray, this.state.agentObj)
    } else {
      exportsimplifiedpaymentExcel(this.state.paymentList, this.state.agentObj)
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>交易</h2>
                <div style={{ marginRight: 10 }}>工作人员: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value=''>全部</option>
                  {this.state.agentList.map((item) => 
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.exportExcel()} variant="outline-success">Excel</Button>
                <div style={{ marginRight: 10}}>日期: </div>
                <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
              </div>
            </div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>工作人员</th>
                  <th>客仔</th>
                  <th>Cash</th>
                  <th>Cheque</th>
                </tr>
              </thead>
              <tbody>
                {this.state.paymentList.map((item, index) => (this.state.filterAgent === '' || (this.state.filterAgent !== '' && this.state.filterAgent === item.agent)) && 
                  <>
                    <tr key={index}>
                      <td>{this.state.agentObj[item.agent]}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    {item.clientArray.map((clientitem, clientindex) => <tr key={clientindex}>
                      <td></td>
                      <td>{clientitem.Client}</td>
                      <td>{clientitem.Cash}</td>
                      <td>{clientitem.Cheque}</td>
                    </tr>)}
                  </>
                )}
              </tbody>
            </Table>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(SimplifiedPaymentList);
  