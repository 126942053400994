const base = window.location.protocol === 'https:' ? 'https://server.sz118.xyz/' : 'http://118.107.232.34/';
// const base = 'http://localhost:8003/';

async function massageRes(res, resolve) {
  const result = await res.json()
  if (result.error && result.error === 'Invalid credentials!') {
    resolve(result)
  } else {
    resolve(result)
  }
}

function getPublicKey(Username, Password) {
  return new Promise((resolve) => {
    fetch(`${base}public/pbkey`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Password }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getLogin(Username, Password) {
  return new Promise((resolve) => {
    fetch(`${base}public/login`, {
      method: 'post',
      headers: {
        Authorization: 'ZHXYPK9sCV31bP81',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Password }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getClientList(Username, token, agent, SearchName) {
  return new Promise((resolve) => {
    fetch(`${base}main/getClientList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, agent, SearchName }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getTempListFNC(agent, SearchName, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getTempList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ agent, SearchName, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function showPaymentHistoryFNC(selectedUser, workerID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/showPaymentHistory`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ selectedUser, workerID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function EditAmountF(SelectedID, ObjectValues, token, Username) {
  return new Promise((resolve) => {
    fetch(`${base}main/EditAmount`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ SelectedID, ObjectValues, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getClientResponsible(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getClientResponsible`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function addSundayFNC(newDate, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/addSunday`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ newDate, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function UpdateByExcelFNC(AddUploadExcelObj, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateByExcel`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ AddUploadExcelObj, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}
function UpdateByExcel2FNC(AddUploadExcelObj, UploadOption, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/UploadByExcel2`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ AddUploadExcelObj, UploadOption, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAgentList(Username, token, Mode, SearchName) {
  return new Promise((resolve) => {
    fetch(`${base}main/getAgentList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Mode, SearchName }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAgentWorkerListFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getAgentWorkerList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getAdminList(Username, token, Mode) {
  return new Promise((resolve) => {
    fetch(`${base}main/getAdminList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Mode }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function addPaymentFNC(Username, paymentDetail, Page, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/addPayment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, paymentDetail, Page }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function searchClient(Username, Keyword, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/searchClient`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, Keyword }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteUserF(UserID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteUser`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ UserID, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateClient(Username, token, ClientID, ClientName, ClientCash, ClientCheque, ClientResponsibleUser, ClientExtra) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateClient`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username, 
        ClientID,
        ClientName,
        ClientCash,
        ClientCheque,
        ClientResponsibleUser,
        ClientExtra,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createClient(Username, token, obj) {
  return new Promise((resolve) => {
    fetch(`${base}main/createClient`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username, 
        Name: obj.Name,
        Cash: obj.Cash, 
        Cheque: obj.Cheque, 
        AgentID: obj.AgentID,
        Extra: obj.Extra,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function createAgent(Username, token, obj) {
  return new Promise((resolve) => {
    fetch(`${base}main/createAgent`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        Username,
        NewAgentUsername: obj.NewAgentUsername,
        NewAgentPassword: obj.NewAgentPassword,
        NewAgentName: obj.NewAgentName,
        NewAgentRole: obj.NewAgentRole,
        NewResponsibleWorker: obj.NewResponsibleWorker,
        NewUpline: obj.NewUpline,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateAgent(Username, token, AgentID, AgentPassword, AgentRole, AgentName, AgentResponsibleWorker, AgentUpline, Limits, XDate) {
  return new Promise((resolve) => {
    fetch(`${base}main/updateAgent`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, AgentID, AgentPassword, AgentRole, AgentName, AgentResponsibleWorker, AgentUpline, Limits, XDate }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function addAmtOnSpecificDateFNC(Username, token, addAmtDateObject) {
  return new Promise((resolve) => {
    fetch(`${base}main/addAmtOnSpecificDate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, addAmtDateObject }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function expandShowPayment(Username, token, paymentIDArray) {
  return new Promise((resolve) => {
    fetch(`${base}main/expandShowPayment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, paymentIDArray }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function triggerNotificationFNC(Username, token, notificationList) {
  return new Promise((resolve) => {
    fetch(`${base}main/triggerNotification`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ Username, notificationList }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function editPending(Username, token, obj) {
  return new Promise((resolve) => {
    fetch(`${base}main/editPending`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username, 
        ClientID: obj.ClientID,
        Date: obj.Date,
        Amount: obj.Amount,
        Remark: obj.Remark,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function deletePending(Username, token, Date) {
  return new Promise((resolve) => {
    fetch(`${base}main/deletePendingDate`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username, 
        Date,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function addPending(Username, token, obj) {
  return new Promise((resolve) => {
    fetch(`${base}main/addPending`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username, 
        AmountArray: obj.AmountArray,
        Date: obj.Date,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function submitPayment(Username, token, obj) {
  return new Promise((resolve) => {
    fetch(`${base}main/submitPayment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        Cash: obj.Cash || null,
        Cheque: obj.Cheque || null,
        ClientID: obj.ClientID,
        Date: obj.Date || null,
        Remark: obj.Remark || null,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getPaymentList(Username, token, FromDate, ToDate, FromAmount, ToAmount) {
  return new Promise((resolve) => {
    fetch(`${base}main/getPaymentList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        FromDate,
        ToDate,
        FromAmount,
        ToAmount,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getActionList(Username, token, FromDate, ToDate) {
  return new Promise((resolve) => {
    fetch(`${base}main/getActionList`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        FromDate,
        ToDate,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function clearClientZero(Username, token, ClientIDArray, Mode) {
  return new Promise((resolve) => {
    fetch(`${base}main/clearClientZero`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        ClientIDArray,
        Mode,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updateRemarkFNC(ReturnID, Remark, Type, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/UpdateRemark`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ReturnID, Remark, Type, Username }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function showPayment(Username,agent, fromDate, toDate, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/showPayment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        agent,
        fromDate,
        toDate,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function ChangePassFrontFNC(newPwd, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/ChangePassFront`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        newPwd,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function addTempPaymentFNC(TempPayment, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/addTempPayment`, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        TempPayment,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function showDailyActionFNC(Username,agent, fromDate, toDate, page, countperpage, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getDailyAction `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        agent,
        fromDate,
        toDate,
        page,
        countperpage,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function showPenCfmPaymentFNC(Username,agent, fromDate, toDate, page, countperpage, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/showPenCfmPayment `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
        agent,
        fromDate,
        toDate, page, countperpage,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function updatePayment(paymentID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/updatePayment `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        paymentID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function getViewTempZhangFNC(Name, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/getViewTempZhang `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Name,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function DeleteTempF(SelectedID, Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/DeleteTemp `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        SelectedID,
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

function showPlayerPaymentFNC(Username, token) {
  return new Promise((resolve) => {
    fetch(`${base}main/showPlayerPayment `, {
      method: 'post',
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ 
        Username,
      }),
    }).then((res) => {
      massageRes(res, resolve)
    });
  })
}

export {
  getPublicKey,
  getLogin, 
  getClientList,
  getTempListFNC,
  showPaymentHistoryFNC,
  EditAmountF,
  getClientResponsible,
  addSundayFNC,
  UpdateByExcelFNC,
  UpdateByExcel2FNC,
  getAgentList,
  getAgentWorkerListFNC,
  searchClient, 
  DeleteUserF,
  updateClient,
  createClient,
  updateAgent,
  addAmtOnSpecificDateFNC,
  expandShowPayment,
  createAgent,
  submitPayment,
  editPending,
  triggerNotificationFNC,
  addPending,
  deletePending,
  getPaymentList,
  clearClientZero,
  getAdminList,
  addPaymentFNC,
  getActionList,
  updatePayment,
  getViewTempZhangFNC,
  DeleteTempF,
  showPlayerPaymentFNC,
  showPayment,
  updateRemarkFNC,
  ChangePassFrontFNC,
  addTempPaymentFNC,
  showDailyActionFNC,
  showPenCfmPaymentFNC,
};
  