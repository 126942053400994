import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table'
import Form from 'react-bootstrap/Form'
// import Container from 'react-bootstrap/Container'
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import { getClientResponsible, getAgentList } from '../Api'
import { exportclientExcel } from '../Component/jsonToXlsx'
var timer

class ClientList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      clientList: [],
      agentList: [],
      agentObj: [],
      showNotification: false,
      notificationMessage: '',
      notificationType: 'success',
      addClientModalShow: false,
      modalInput: {
        name: '',
        cash: '',
        cheque: '',
        responsibleUser: '',
        extra: '',
      },
      total: {
        Extra: 0,
        Cash: 0,
        Cheque: 0,
      },
      filterAgent: '',
      clear0ModalShow: false,
      clear0Obj: {},
    }
  }

  componentDidMount() {
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'obs') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      this.initPage()
    }
  }

  async initPage() {
    const getClientData = await getClientResponsible(this.props.username, this.props.token);
    const agentList = await getAgentList(this.props.username, this.props.token)
    const agentObj = {}
    agentList.forEach((item) => {
      agentObj[item.ID] = item.Name
    })
    const tempTotal = {
      Cash: 0,
      Cheque: 0,
      Extra: 0,
    }
    getClientData.forEach((item) => {
      if (this.state.filterAgent === '') {
        tempTotal.Cash += item.Cash
        tempTotal.Cheque += item.Cheque
        tempTotal.Extra += item.Extra
      } else if (this.state.filterAgent !== '' && item.ResponsibleUser === this.state.filterAgent) {
        tempTotal.Cash += item.Cash
        tempTotal.Cheque += item.Cheque
        tempTotal.Extra += item.Extra
      }
    })
    
    this.setState({
      clientList: getClientData,
      loading: false,
      agentList, 
      agentObj,
      total: tempTotal,
    });
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.setState({ showNotification: true });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
        notificationMessage: ''
      });
    }, 2000);
  };

  filterAgent(e) {
    const tempTotal = {
      Cash: 0,
      Cheque: 0,
      Extra: 0,
    }
    this.state.clientList.forEach((item) => {
      if (e.target.value === '') {
        tempTotal.Cash += item.Cash
        tempTotal.Cheque += item.Cheque
        tempTotal.Extra += item.Extra
      } else if (e.target.value !== '' && item.ResponsibleUser === e.target.value) {
        tempTotal.Cash += item.Cash
        tempTotal.Cheque += item.Cheque
        tempTotal.Extra += item.Extra
      }
    })
    this.setState({ 
      filterAgent: e.target.value,
      total: tempTotal,
    })
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.clientList.filter((item) => item.ResponsibleUser === this.state.filterAgent)
      exportclientExcel(filterArray, this.state.agentObj)
    } else {
      exportclientExcel(this.state.clientList, this.state.agentObj)
    }
  }

  render() {
    return (
      <div className='FontSize'>
        <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '70%', left: 250}} show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
          <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
        </Alert>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div style={{ paddingLeft: 5, paddingRight: 5 }}>
            <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10}}>客仔</h2>
                <div style={{ marginRight: 10 }}>工作人员: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value=''>全部</option>
                  {this.state.agentList.map((item) => 
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div style={{ display: 'flex' }}>
                <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.exportExcel()} variant="outline-success">Excel</Button>
              </div>
            </div>
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>名字</th>
                  <th>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                      <div style={{ marginRight: 10 }}>定金/多余</div>
                    </div>
                  </th>
                  <th>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                      <div style={{ marginRight: 10 }}>已收现金</div>
                    </div>
                  </th>
                  <th>
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                      <div style={{ marginRight: 10 }}>已收支票</div>
                    </div>
                  </th>
                  <th>负责人</th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientList.map((item, index) => (this.state.filterAgent === '' || (this.state.filterAgent !== '' && this.state.filterAgent === item.ResponsibleUser)) && <tr key={index}>
                  <td>{item.ID}</td>
                  <td>{item.Name}</td>
                  <td>{item.Extra}</td>
                  <td>{item.Cash}</td>
                  <td>{item.Cheque}</td>
                  <td>{this.state.agentObj[item.ResponsibleUser]}</td>
                </tr>)}
                <tr>
                  <td></td>
                  <td></td>
                  <td>{this.state.total.Extra}</td>
                  <td>{this.state.total.Cash}</td>
                  <td>{this.state.total.Cheque}</td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ClientList);
  