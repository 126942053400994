import '../App.css';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../Component/Header'
// import Table from 'react-bootstrap/Table'
import * as XLSX from 'xlsx';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
// import Container from 'react-bootstrap/Container'
import moment from 'moment-timezone'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal';
import RemarkModal from '../Component/RemarkModal';
import { addPaymentFNC, getClientList, editPending, getAgentWorkerListFNC, deletePending, EditAmountF, addSundayFNC, UpdateByExcel2FNC } from '../Api'
// import deletePng from '../Image/trashdelete.png'
import { exportzhangbenExcel } from '../Component/jsonToXlsx'
import { language } from '../language';

var timer

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateArray: [],
      userArray: [],
      selectedPending: '',
      inputNumber: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      total: {
        Balance: 0,
        Cash: 0,
        Cheque: 0,
        Extra: 0,
      },
      agentList: [],
      filterAgent: '',
      agentObj: {},
      addDateModalShow: false,
      modalDate: moment().weekday(7).format('YYYY-MM-DD'),
      deletePendingModalShow: false,
      selectedDate: '',
      deleteDate: '',
      AddPayment: [],
      enabledDare: [],
      UploadExcelModal: false,
      ExcelValues: [],
      SelectedExcelDate: '',
      EnableUpload: false,
      HighlightID: '',
      RemarkModal: false,
      RamarkType: '',
      DisplayItems: '',
      SearchName: '',
      loading: false,
      UploadOption: '',
      NonDatabaseUserModal: false,
      NonDataUsers: [],
    }
    this.handleClose = this.handleClose.bind(this);
    this.handleCloseNonUsers = this.handleCloseNonUsers.bind(this);
    this.UploadExcelFile = this.UploadExcelFile.bind(this);
    this.closeDeletePendingModal = this.closeDeletePendingModal.bind(this);
  }

  async componentDidMount() {
    // const DaysUntilSun = 7 - moment().isoWeekday()
    // console.log('aaaaa', moment().isoWeekday())
    // console.log('DaysUntilSun', DaysUntilSun)
    // console.log('Add to Sun', moment().add(7, 'days').format('YYYYMMDD'))
    // console.log('see date', moment(this.state.modalDate).format('YYYYMMDD'))
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'admin' && this.props.role !== 'account' ) {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList: agentList, agentObj }, () => {
        this.initPage();
      });
    }
  }

  async initPage() {
    this.setState({ loading: true })
    const getClientData = await getClientList(this.props.username, this.props.token, this.state.filterAgent, this.state.SearchName);
    // console.log('getClientData', getClientData)
    const DisplatDate = []
    getClientData.dateArray.forEach((items) => {
      DisplatDate.push(new Date(moment(items).format('YYYY-MM-DD')))
    })
    DisplatDate.push(new Date(moment().weekday(7).format('YYYY-MM-DD')))
    DisplatDate.push(new Date(moment().weekday(14).format('YYYY-MM-DD')))
    this.setState({
      loading: false, userArray: getClientData.userArray, dateArray: getClientData.dateArray, inputNumber: getClientData.userArray.map((item) => ({cash: '', cheque: ''})), AddPayment: getClientData.userArray.map((item) => ({inputNumberCash: '', inputNumberCek: ''})), enabledDare: DisplatDate
    })
  }

  handleChange(e, index, mode, date) {
    if (mode) {
      const tempInputNumber = JSON.parse(JSON.stringify(this.state.inputNumber))
      let tempValue = e.target.value.replace(/[^0-9.-]/g,'') 
      if (tempValue.startsWith('0')) tempValue = Number(tempValue)
      tempInputNumber[index][mode] = tempValue
      this.setState({ inputNumber: tempInputNumber });
    } else if (date) {
      if (date !== 'Remark') {
        const tempUserArray = JSON.parse(JSON.stringify(this.state.userArray))
        let tempValue = e.target.value.replace(/[^0-9.-]/g,'') 
        if (tempValue.startsWith('0')) tempValue = Number(tempValue)
        tempUserArray[index][date] = tempValue
        this.setState({ userArray: tempUserArray });
      } else {
        const tempUserArray = JSON.parse(JSON.stringify(this.state.userArray))
        tempUserArray[index][date] = e.target.value
        this.setState({ userArray: tempUserArray });
      }
    }
  }

  NumberOnly(value, field) {
    this.setState({ [field]: value });
  }

  selectPendingToPay(id, date) {
    if (this.state.selectedPending === `${id}-${date}`) {
      this.setState({ selectedPending: '', HighlightID: '' });
    } else {
      this.setState({ selectedPending: `${id}-${date}`, HighlightID: id });
    }
  }

  async submitPaymentF(type, index, ID) {
    let AddPayment = this.state.AddPayment
    const userArray = this.state.userArray
    const selectedPending = this.state.selectedPending.split('-')[1]
    const selectedID = this.state.selectedPending.split('-')[0]
    const Worker = userArray[index].ResponsibleWorker
    let finalObj = {
      DateTime: (selectedPending === undefined) ? '' : selectedPending,
      Worker: Worker,
      Client: userArray[index].Name,
      type: '',
      CashAmount: '',
      ChequeAmount: '',
      UserID: ID,
      Remark: userArray[index].Remark,
    }
    // console.log('this.state.AddPayment[index].inputNumberCek', this.state.AddPayment[index].inputNumberCek)
    // console.log('this.state.AddPayment[index].inputNumberCash', this.state.AddPayment[index].inputNumberCash)
    if (Number(selectedID) !== 0 && Number(selectedID) !== ID) {
      this.DisplayMEssage('Error Found', 'Error');
    } else {
      // console.log('sss')
      if (this.state.AddPayment[index].inputNumberCek === '' && this.state.AddPayment[index].inputNumberCash !== '') {
        finalObj.type = 'Cash'
        finalObj.CashAmount = AddPayment[index].inputNumberCash
      } else if (this.state.AddPayment[index].inputNumberCek !== '' && this.state.AddPayment[index].inputNumberCash === '') {
        finalObj.type = 'cheque'
        finalObj.ChequeAmount = AddPayment[index].inputNumberCek
      } else if (this.state.AddPayment[index].inputNumberCek !== '' && this.state.AddPayment[index].inputNumberCash !== '') {
        finalObj.type = 'both'
        finalObj.ChequeAmount = AddPayment[index].inputNumberCek
        finalObj.CashAmount = AddPayment[index].inputNumberCash
      }
      // console.log('finalObj', finalObj)
      const AddPaymenttoServer = await addPaymentFNC(this.props.username, finalObj, '', this.props.token);
      if (AddPaymenttoServer.message === 'Success') {
        this.DisplayMEssage('Success Add Payment', 'Success');
        this.initPage();
      }
      if (AddPaymenttoServer.error === 'Empty Amount') {
        this.DisplayMEssage(language[this.props.currentLanguage].EmptyAmount, 'Error');
      }
    }
  }

  async editPendingF(index, date) {
    let editPendingResult
    if (date !== 'remark') {
      editPendingResult = await editPending(this.props.username, this.props.token, {
        ClientID: this.state.userArray[index].ID,
        Date: date,
        Amount: this.state.userArray[index][date],
      });
    } else {
      editPendingResult = await editPending(this.props.username, this.props.token, {
        ClientID: this.state.userArray[index].ID,
        Remark: this.state.userArray[index].Remark,
      });
    }
    if (editPendingResult.message && editPendingResult.message === 'successful') {
      this.openNotification('已更新', '')
      this.initPage()
    }
  }

  onKeyEnter(e, type, index, ID) {
    if (e.key === 'Enter') {
      this.submitPaymentF(type, index, ID) 
    }
  }

  focusNextIndex(index, date) {
    let found = false
    let foundIndex = index + 1
    while (found === false) {
      const elemToScrollTo = document.getElementById(`${foundIndex}${date}`)
      if (elemToScrollTo) {
        found = true
        elemToScrollTo.focus()
        elemToScrollTo.select();
      } else {
        foundIndex += 1
      }
      if (foundIndex > this.state.userArray.length) {
        foundIndex = 0
      }
    }
  }

  // filterAgent(e) {
  //   const tempTotal = {
  //     Balance: 0,
  //     Cash: 0,
  //     Cheque: 0,
  //     Extra: 0,
  //   }
  //   this.state.dateArray.forEach((dateitem) => {
  //     tempTotal[dateitem] = 0
  //   })
  //   this.state.userArray.forEach((item) => {
  //     if (e.target.value !== '' && item.ResponsibleUser === e.target.value) {
  //       tempTotal.Balance += item.Balance
  //       tempTotal.Cash += item.Cash
  //       tempTotal.Cheque += item.Cheque
  //       tempTotal.Extra += item.Extra || 0
  //       this.state.dateArray.forEach((dateitem) => {
  //         tempTotal[dateitem] += item[dateitem] || 0
  //       })
  //     } else if (e.target.value === '') {
  //       tempTotal.Balance += item.Balance
  //       tempTotal.Cash += item.Cash
  //       tempTotal.Cheque += item.Cheque
  //       tempTotal.Extra += item.Extra || 0
  //       this.state.dateArray.forEach((dateitem) => {
  //         tempTotal[dateitem] += item[dateitem] || 0
  //       })
  //     }
  //   })
  //   this.setState({
  //     filterAgent: e.target.value,
  //     total: tempTotal
  //   });
  // }

  handleClose() {
    this.setState({
      SearchName: '',
      RemarkModal: false,
      RamarkType: '',
      DisplayItems: '',
      addDateModalShow: false,
      UploadExcelModal: false,
      UploadOption: '',
      ExcelValues: [],
      inputNumber: this.state.userArray.map((item) => ({cash: '', cheque: ''})),
    }, () => {
      this.initPage();
    })
  }

  handleCloseNonUsers() {
    this.setState({ 
      NonDatabaseUserModal: false,
      NonDataUsers: [],
    })
  }

  async handleSubmit() {
    const dateArray = this.state.dateArray
    if (dateArray.includes(moment(this.state.modalDate).format('YYYYMMDD'))) {
      this.DisplayMEssage('已經存在選擇日期', 'Error');
      //sasdasd
      this.handleClose();
    } else {
      const addDateAmount = await addSundayFNC(moment(this.state.modalDate).format('YYYYMMDD'),this.props.username, this.props.token);
      // console.log('addDateAmount', addDateAmount)
      if (addDateAmount.message === 'Added Successful') {
        this.handleClose();
        this.initPage()
        this.DisplayMEssage('已更新', 'Success');
      }
      if (addDateAmount.error === 'Unable to add new week, please add previous sunday first') {
        this.handleClose();
        this.initPage()
        this.DisplayMEssage('无法增加此日期，请先添加上周日', 'Error');
      }
      if (addDateAmount.error === 'Duplicated Date') {
        this.handleClose();
        this.initPage()
        this.DisplayMEssage('已經存在選擇日期', 'Error');
      }
    }
  }

  deletePending(date) {
    this.setState({ deletePendingModalShow: true, deleteDate: date })
  }

  async confirmDeletePending() {
    const deletePendingResult = await deletePending(this.props.username, this.props.token, this.state.deleteDate);
    if (deletePendingResult.message && deletePendingResult.message === 'successful') {
      this.openNotification('已删除', '')
      this.setState({ 
        deletePendingModalShow: false,
        deleteDate: '',
      })
      this.initPage()
    } else if (deletePendingResult.message && deletePendingResult.message === 'consist non zero') {
      this.openNotification('有非零客仔，不可删除', 'error')
      this.setState({ 
        deletePendingModalShow: false,
        deleteDate: '',
      })
    }
  }

  closeDeletePendingModal() {
    this.setState({ deletePendingModalShow: false })
  }

  // exportExcel() {
  //   console.log('this.state.userArray', this.state.userArray)
  //   if (this.state.filterAgent !== '') {
  //     const filterArray = this.state.userArray.filter((item) => item.ResponsibleUser === this.state.filterAgent)
  //     exportzhangbenExcel(filterArray, this.state.dateArray, this.state.agentObj)
  //   } else {
  //     exportzhangbenExcel(this.state.userArray, this.state.dateArray, this.state.agentObj)
  //   }
  // }

  exportExcel() {
    const found = this.state.filterAgent === '' ? '全部' : this.state.agentList.find(state => state.ID === Number(this.state.filterAgent));
    exportzhangbenExcel(this.state.userArray, this.state.dateArray, this.state.agentObj, found.Name || found)
  }

  UploadByExcel(type) {
    this.setState({ UploadExcelModal: true, UploadOption: type })
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      });
    }, 5000);
  }

  CalculateTotal(Totaltype) {
    let userArray = this.state.userArray
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    const DecimalNumber = Totals.TotalNumber % 1
    return (DecimalNumber < 0.5) ? Math.floor(Totals.TotalNumber).toLocaleString() : Math.ceil(Totals.TotalNumber).toLocaleString()
  }

  handleUpdate(event, type, index) {
    const tempInputNumber = JSON.parse(JSON.stringify(this.state.AddPayment))
    tempInputNumber[index][type] = event
    this.setState({ AddPayment: tempInputNumber})
  }

  EditVAlue(event, index, type) {

    let userArray = this.state.userArray
    userArray[index][type] = event.target.value
    this.setState({ userArray })
  }

  onKeyEditConfirm(event, index) {
    if (event.key === 'Enter') {
      this.EditDataAPI(index);
    }
  }

  async EditDataAPI(index) {
    let userArray = this.state.userArray
    let EditObject = {}
    let DateArray = {}
    EditObject.ID = userArray[index].ID
    EditObject.Advance = userArray[index].Advance
    EditObject.Old = userArray[index].Old
    EditObject.CompanyTotal = userArray[index].CompanyTotal
    this.state.dateArray.forEach((items) => {
      DateArray[items] = userArray[index][items]
    })
    EditObject.Dates = DateArray
    const EditAmount = await EditAmountF(userArray[index].ID, EditObject, this.props.token, this.props.username)
    // console.log('EditAmount', EditAmount)
    if (EditAmount.message === 'successful Update') {
      this.DisplayMEssage(`已更新${userArray[index].Name}的帐本资料`, 'Success');
      this.initPage();
    }
  }

  // UploadExcelFile(e) {
  //   var files = e.target.files, f = files[0];
  //   const reader = new FileReader();
  //   reader.onload = (evt) => {
  //     const bstr = evt.target.result;
  //     const wb = XLSX.read(bstr, {type:'binary'});
  //     const wsname = wb.SheetNames[0];
  //     const ws = wb.Sheets[wsname];

  //     const data = XLSX.utils.sheet_to_csv(ws, {header:1});

  //     if (data.includes('\n')) {
  //       const datas = data.split('\n')
  //       let finalArray = []
  //       const finaldatas = datas.filter(function (el) {
  //         return el !== ''
  //       });
  //       if (finaldatas[0].startsWith('Name') === false) {
  //         this.setState({ EnableUpload: true })
  //         this.DisplayMEssage(`Wrong Format file`, 'Error');
  //       } else {
  //         this.setState({ EnableUpload: false })
  //         for (var i = 0; i < finaldatas.length; i ++) {
  //           if(i !== 0 ) {
  //             let ObjectData = {
  //               Name: '',
  //               Old: '',
  //               Date: {},
  //             }
  //             ObjectData.Name = finaldatas[i].split(',')[0]
  //             ObjectData.Old = finaldatas[i].split(',')[1]
  //             ObjectData.Date[finaldatas[0].split(',')[2]] = finaldatas[i].split(',')[2]
  //             ObjectData.Date[finaldatas[0].split(',')[3]] = finaldatas[i].split(',')[3]
  //             ObjectData.Date[finaldatas[0].split(',')[4]] = finaldatas[i].split(',')[4]
  //             ObjectData.Date[finaldatas[0].split(',')[5]] = finaldatas[i].split(',')[5]
  //             ObjectData.Date[finaldatas[0].split(',')[6]] = finaldatas[i].split(',')[6]
  //             ObjectData.Date[finaldatas[0].split(',')[7]] = finaldatas[i].split(',')[7]
  //             ObjectData.Date[finaldatas[0].split(',')[8]] = finaldatas[i].split(',')[8]
  //             finalArray.push(ObjectData)
  //           }
  //         }
  //       }
  //       this.setState({ ExcelValues: finalArray }, () => {
  //         console.log('ExcelValues', this.state.ExcelValues)
  //       })
  //     } else {
  //       console.log('Nope, there is not any')
  //     }
  //   };
  //   reader.readAsBinaryString(f);
  // }

  UploadExcelFile(e) {
    var files = e.target.files, f = files[0];
    const reader = new FileReader();
    if (files.length > 0) {
      this.setState({ ExcelUpload: [] })
      reader.onload = (evt) => {
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type:'binary'});
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        this.setState({ ExcelValues: data }, () => {
          // console.log('ExcelValues', this.state.ExcelValues)
        })
      };
      reader.readAsBinaryString(f);
    }
  }

  async SubmitUploadExcel() {
    this.setState({ loading: true })
    const UploadOption = this.state.UploadOption
    // const SelectedExcelDate = this.state.SelectedExcelDate
    const ExcelValues = this.state.ExcelValues
    // const uploadexcel = await UpdateByExcelFNC(ExcelValues, this.props.username, this.props.token);
    const uploadexcel = await UpdateByExcel2FNC(ExcelValues, UploadOption, this.props.username, this.props.token);
    if (uploadexcel.message === 'Success') {
      this.DisplayMEssage(`已成功更新用户账本`, 'Success');
      this.setState({ loading: false })
      this.handleClose();
      this.initPage();
      if (uploadexcel.NonDatabaseUsers.length > 0) {
        this.setState({ NonDatabaseUserModal: true, NonDataUsers: uploadexcel.NonDatabaseUsers })
      }
    } else {
      this.DisplayMEssage(`${uploadexcel.error}`, 'Error');
      this.handleClose();
      this.setState({ loading: false })
      this.initPage();
    }
  }

  TotalZong(Selected) {
    let Totals = {
      TotalNumber: 0,
    }
    this.state.dateArray.forEach((date) => {
      Totals.TotalNumber += Number(Selected[date]) 
    })
    Totals.TotalNumber += Number(Selected['Old']) 
    return Totals.TotalNumber.toLocaleString()
  }

  CalculatezongTotal() {
    let Totals = {
      TotalNumber: 0,
    }
    const UserArray = this.state.userArray

    UserArray.forEach((array) => {
      this.state.dateArray.forEach((date) => {
        Totals.TotalNumber += Number(array[date])
      })
      Totals.TotalNumber += Number(array['Old'])
    })
    // return Totals.TotalNumber.toLocaleString()
    const DecimalNumber = Totals.TotalNumber % 1
    return (DecimalNumber < 0.5) ? Math.floor(Totals.TotalNumber).toLocaleString() : Math.ceil(Totals.TotalNumber).toLocaleString()
  }

  OpenRemarkModal(item, type) {
    console.log('item', item)
    this.setState({ 
      DisplayItems: item,
      RemarkModal: true,
      RamarkType: type,
    })
  }

  onKeyEnterSearch(e) {
    if (e.charCode === 13) {
      this.initPage();
    }
  }

  ReturnNumberCount(NumberDisplay) {
    // return NumberDisplay;
    const DecimalNumber = NumberDisplay % 1
    // return (DecimalNumber < 0.5) ? Math.floor(NumberDisplay).toLocaleString() : Math.ceil(NumberDisplay).toLocaleString()
    return NumberDisplay.toFixed(2)
  }

  isSunday = (date) => {
    return date.getDay() === 0; // 0 corresponds to Sunday
  };

  filterDates = (date) => {
    return this.isSunday(date);
  };


  ReturnColor(Limit, see) {
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }
    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnColorText(index, number) {
    const item = this.state.userArray[index];
    const Limit =  item.Limits;
    const see =  Number(item.Total);
    const XDate = item.XDate;
    let DataArray = []
    let color = ''
    if (Number(Limit) === 0) {
      return ''
    }

    if (number === '0') {
      return ''
    }

    if (see.toFixed(2) > Number(Limit)) {
      color = '#FF5132'
    } else {
      if (Number(XDate) === 0) {
        return ''
      }
      
      const dateArray = this.state.dateArray
      for (var i = 0; i < dateArray.length; i++) {
        if (item[dateArray[i]] !== '0') {
          DataArray.push(item[dateArray[i]])
        }
      }

      if (DataArray.length >= Number(XDate)) {
        color = '#FF5132'
      } else {
        color = ''
      }
    }
    return color
  }


  ReturnColorXDate(XDate, item) {
    let DataArray = []
    if (Number(XDate) === 0) {
      return ''
    }
    const dateArray = this.state.dateArray
    for (var i = 0; i < dateArray.length; i++) {
      if (item[dateArray[i]] !== '0') {
        DataArray.push(item[dateArray[i]])
      }
    }
    let color = ''
    if (DataArray.length >= Number(XDate)) {
      color = '#FF5132'
    } else {
      color = ''
    }
    return color
  }

  ReturnCompanyTotalColor(CompanyTotal, Total) {
    let color = ''
    if (CompanyTotal !== Total) {
      color = '#FF2600'
    } else { color = '' }
    return color
  }

  render() {
    return (
      <div className='FontSize'>
        <Header/>
        <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          {this.state.NonDatabaseUserModal &&
          <Modal centered show={this.state.NonDatabaseUserModal} onHide={this.handleCloseNonUsers}>
            <Modal.Header closeButton>
              <Modal.Title>
                不存在的用戶/客戶
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10, maxHeight: '50vh', minHeight: '50vh', overflowX: 'auto' }}>
                {this.state.NonDataUsers.map((items, index) => {
                  return(
                    <div key={index}>{`${index+1}. ${items}`}</div>
                  );
                })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => this.handleCloseNonUsers()}>关闭</Button>
            </Modal.Footer>
          </Modal> 
          }
          {this.state.RemarkModal && 
          <RemarkModal RemarkModal={this.state.RemarkModal} handleClose={this.handleClose} DisplayItems={this.state.DisplayItems} RamarkType={this.state.RamarkType}/>}
          <Modal centered show={this.state.addDateModalShow} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  增加日期
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <DatePicker
                className={"form-control StyleDatePickerMobile"}
                inputClass="custom-input"
                onChange={(e) => this.setState({ modalDate: e })}
                placeholder="Date"
                selected={new Date(this.state.modalDate)}
                filterDate={this.filterDates}
                // includeDates={this.state.enabledDare}
                value={moment(this.state.modalDate).format('YYYY/MM/DD')}
                withPortal
              />
              {/* <Form.Control placeholder="新日期" value={this.state.modalDate} onChange={(e) => this.setState({ modalDate: e.target.value })} type="date" style={{ marginBottom: 10 }}/> */}
              <div style={{ display: 'flex', flexDirection: 'row', fontSize: '20px', marginTop: 10 }}>
                <div>{`您确定要增加此日期`}</div>
                <div style={{ fontWeight: 'bold', color: 'red' }}>{moment(this.state.modalDate).format('YYYY-MM-DD')}</div>
                <div>{'吗?'}</div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
              <Button variant="success" onClick={() => this.handleSubmit()}>增加</Button>
            </Modal.Footer>
          </Modal>
          <Modal centered size='xl' show={this.state.UploadExcelModal} onHide={this.handleClose}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                  Upload by Excel
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>{'请选择上传文件'}</div>
              {/* <Form.Control placeholder="新日期" value={this.state.modalDate} onChange={(e) => this.setState({ modalDate: e.target.value })} type="date" style={{ marginBottom: 10 }}/> */}
              <div style={{ display: 'flex', flexDirection: 'column', fontSize: '20px', marginTop: 10 }}>
                <div className="form-group">
                  <input type="file" accept=".xls,.xlsx,.csv" className="form-control" onChange={this.UploadExcelFile} />
                </div>
                {(this.state.ExcelValues.length > 0 && this.state.loading !== true && this.state.UploadOption === '') &&
                  <div style={{ display: 'flex', flexDirection: 'column'}}>
                    <div>{`${this.state.ExcelValues.length} datas`}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '100%' }}>Name</div>
                      <div style={{ width: '100%' }}>Old</div>
                      {this.state.dateArray.map((items, inn) => {
                        return(
                          <div key={inn} style={{ width: '100%' }}>{moment(items).format('YYYY/MM/DD')}</div>
                        );
                      })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '30vh', overflowX: 'auto', fontSize: '16px'}}>
                      {this.state.ExcelValues.map((item, index) => {
                        return(
                          <div className='dcc' key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%', wordBreak: 'break-all' }}>{item.Name}</div>
                            <div style={{ width: '100%' }}>{item.Old}</div>
                            {this.state.dateArray.map((items, inn) => {
                              return(
                                <div key={inn} style={{ width: '100%' }}>{item[items]}</div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                {(this.state.ExcelValues.length > 0 && this.state.loading !== true && this.state.UploadOption === 'CompanyTotal') &&
                  <div style={{ display: 'flex', flexDirection: 'column', width: '50%'}}>
                    <div>{`${this.state.ExcelValues.length} datas`}</div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <div style={{ width: '100%' }}>Name</div>
                      <div style={{ width: '100%' }}>Company Total</div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '30vh', overflowX: 'auto', fontSize: '16px'}}>
                      {this.state.ExcelValues.map((item, index) => {
                        return(
                          <div className='dcc' key={index} style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '100%', wordBreak: 'break-all' }}>{item.Name}</div>
                            <div style={{ width: '100%' }}>{item.Total}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                }
                {this.state.loading === true && 
                 <div style={{ marginTop: 50 }}>Uploading ...... </div>}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => this.handleClose()}>关闭</Button>
              <Button variant="success" disabled={this.state.EnableUpload} onClick={() => this.SubmitUploadExcel()}>上传</Button>
            </Modal.Footer>
          </Modal>
          <Modal centered show={this.state.deletePendingModalShow} onHide={this.closeDeletePendingModal}>
            <Modal.Header closeButton>
              <Modal.Title>确定删除全部 {moment(this.state.deleteDate, 'YYYYMMDD').format('DD/MM')}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button variant="primary" onClick={() => this.closeDeletePendingModal()}>关闭</Button>
              <Button variant="danger" onClick={() => this.confirmDeletePending()}>删除</Button>
            </Modal.Footer>
          </Modal>
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
              <h2 style={{ marginRight: 10 }}>📖 账本</h2>
              <div style={{ marginRight: 10 }}>{language[this.props.currentLanguage].StaffMember}: </div>
              <Form.Select style={{ width: 150 }} onChange={(e) => {
                this.setState({ filterAgent: e.target.value }, () => this.initPage())
              }}>
                <option value=''>{language[this.props.currentLanguage].All}</option>
                {this.state.agentList.map((item, inde) => 
                  <option key={inde} value={item.ID}>{item.Name}</option>
                )}
              </Form.Select>
              <div style={{ paddingLeft: 5 }}><Form.Control value={this.state.SearchName} placeholder='Search Name' onChange={(e) => this.setState({ SearchName: e.target.value })} onKeyPress={(e) => this.onKeyEnterSearch(e)} /></div>
            </div>
            {this.state.MessageType !== '' &&
            <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', padding: 5, backgroundColor: 'rgba(255, 255, 255, .9)', borderRadius: 10 }}>
              {this.state.MessageType === 'Error' && 
            <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
              }
              {this.state.MessageType === 'Success' && 
            <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
              }
              <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F'}}>{` ${this.state.DisplayErrorMessage}`}</div>
            </div>
            }
            <div style={{ display: 'flex' }}>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 120, marginRight: 5 }} onClick={() => this.UploadByExcel('')}>{language[this.props.currentLanguage].UploadbyExcel}</div>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 120, marginRight: 5 }} onClick={() => this.UploadByExcel('CompanyTotal')}>{language[this.props.currentLanguage].UploadCompanyTotal}</div>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 120, marginRight: 5 }} onClick={() => this.exportExcel()}>{language[this.props.currentLanguage].ExporExcel}</div>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 120 }} onClick={() => this.setState({ addDateModalShow: true })}>{language[this.props.currentLanguage].AddedDate}</div>
              {/* <Button style={{ minWidth: 80, marginRight: 10 }} onClick={() => this.UploadByExcel()} variant="success">{language[this.props.currentLanguage].UploadbyExcel}</Button> */}
            </div>
          </div>
          <div className='dcc' style={{ overflowY: 'auto' }}>
            <div className="dcc" style={{ width: '99%', marginBottom: 50, backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1" , padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className='headerColorTable' style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex'}}>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Name}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'Limit'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'X期清1'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Deposit}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].ResponsiblePerson}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Old}
                          </div>
                          {/* <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'Advance'}
                          </div> */}
                          {this.state.dateArray.map((item, idx) => 
                            <div key={idx} className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', display: 'flex', flexDirection: 'row', wordBreak: 'break-word'}}>
                              <div style={{ marginRight: 10 }}>{moment(item, 'YYYYMMDD').format('DD/MM')}</div>
                              {/* <img src={deletePng} alt="Logo" style={{ height: 16, width: 16,}} onClick={() => this.deletePending(item)}/> */}
                            </div>)}
                          <div className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Total}
                          </div>
                          <div className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Company}{language[this.props.currentLanguage].Total}
                          </div>
                          {/* <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {language[this.props.currentLanguage].Advance}
                          </div> */}
                          {/* <div  className="resulttableTitle" style={{ width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3}}>
                            {'定金/多余'}
                          </div> */}
                          {/* <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Return Cheque'}
                          </div> */}
                          <div  className="dcc resulttableTitle" style={{ width:'calc(100%/1.5)', color: 'white', paddingTop: 3, paddingBottom: 3 }}>
                            {'Remark'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{ width:'calc(100%/1.5)', color: 'white', paddingTop: 3, paddingBottom: 3 }}>
                            {'Acc Remark'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3 }}>
                            {language[this.props.currentLanguage].ReceivedCash}
                          </div>
                          <div className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', paddingTop: 3, paddingBottom: 3 }}>
                            {language[this.props.currentLanguage].ReceivedCheque}
                          </div>
                          <div  className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', minWidth: 130, maxWidth: 100, paddingTop: 3, paddingBottom: 3 }}>
                            {language[this.props.currentLanguage].CashPending}
                          </div>
                          <div  className="dcc resulttableTitle" style={{ width:'calc(100%)', color: 'white', minWidth: 130, maxWidth: 100, paddingTop: 3, paddingBottom: 3 }}>
                            {language[this.props.currentLanguage].ChequePending}
                          </div>
                        </div>
                      </div>
                      {(this.state.userArray.length > 0 && this.state.loading === false) &&
                  <>
                    {this.state.userArray.map((item, index) => {
                      return(
                        <div className='dcc highlight' key={index} style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (item.ID === this.state.HighlightID) ? '#bce3ff' : (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex', marginBottom: 1.5 }}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Name}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', 
                              color: this.ReturnColor(item.Limits, Number(item.Total)) 
                            }}>
                              {`${(item.Limits === null) ? '0' : item.Limits}`}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)', wordBreak: 'break-word', 
                              color: this.ReturnColorXDate(item.XDate, item) 
                            }}>
                              {`${(item.XDate === null) ? '-' : (Number(item.XDate) === 0) ? '' : `${item.XDate} 清 1` }`}
                              {/* {console.log('item.XDate', item.XDate)} */}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              {item.Deposit}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              {this.state.agentObj[item.ResponsibleWorker]}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              <input style={{ border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70}} value={item.Old}  onKeyPress={(e) => this.onKeyEditConfirm(e, index, 'Old')} onChange={(e) => this.EditVAlue(e, index, 'Old')}></input>
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              <input style={{ border: '1px solid black', borderRadius: 5, minWidth: 70, maxWidth: 70}} value={item.Advance}  onKeyPress={(e) => this.onKeyEditConfirm(e, index, 'Advance')} onChange={(e) => this.EditVAlue(e, index, 'Advance')}></input>
                            </div> */}
                            {this.state.dateArray.map((dateItem, idxs) => <div key={idxs} className="dcc resulttable" style={{ paddingTop: 2,  paddingBottom: 2, cursor: 'pointer', backgroundColor: this.state.selectedPending === `${item.ID}-${dateItem}` && '#4A5874', flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }} onClick={() => this.selectPendingToPay(item.ID, dateItem)}>
                              {/* <Form.Control readOnly id={`${index}${dateItem}`} value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]} type="text" style={{ minWidth: 50, maxWidth: 80 }} onKeyPress={(e) => this.onKeyEnter(e, index, dateItem)} onChange={(e) => this.handleChange(e, index, null, dateItem)}/> */}
                              {/* <div style={{ border: '1px solid black', borderRadius: 5, minWidth: 50, maxWidth: 80}}>{typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}</div> */}
                              {item.ID === this.state.HighlightID ?
                                <input autoFocus={(this.state.selectedPending === `${item.ID}-${dateItem}`) ? true : false} style={{   color: this.ReturnColorText(index, item[dateItem]), border: '1px solid grey', borderRadius: 5, minWidth: 80, maxWidth: 80}} value={typeof item[dateItem] === 'undefined' ? '' : item[dateItem]}  onKeyPress={(e) => this.onKeyEditConfirm(e, index, dateItem)} onChange={(e) => this.EditVAlue(e, index, dateItem)}></input>
                                :
                                <div style={{ color: this.ReturnColorText(index, item[dateItem]),border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70, background: 'white'}}>{typeof item[dateItem] === 'undefined' ? '' : this.ReturnNumberCount(Number(item[dateItem]))}</div>
                              }
                            </div>)}
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              {this.ReturnNumberCount(Number(item.Total))}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              <input style={{ border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70, color: this.ReturnCompanyTotalColor(this.ReturnNumberCount(Number(item.CompanyTotal)), this.ReturnNumberCount(Number(item.Total)))}} value={item.CompanyTotal}  onKeyPress={(e) => this.onKeyEditConfirm(e, index, 'CompanyTotal')} onChange={(e) => this.EditVAlue(e, index, 'CompanyTotal')}></input>
                            </div>
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              <input style={{ border: '1px solid grey', borderRadius: 5, minWidth: 70, maxWidth: 70}} value={item.Advance}  onKeyPress={(e) => this.onKeyEditConfirm(e, index, 'Advance')} onChange={(e) => this.EditVAlue(e, index, 'Advance')}></input>
                            </div> */}
                            {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.ReturnedCheque}
                            </div> */}
                            {/* <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.ReturnNumberCount(Number(item.ReturnedCheque))}
                            </div> */}
                            <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word'  }}>
                              {
                                ((item.Remark === null || item.Remark === '') ? '-' : item.Remark === '-')
                                  ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].Add}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word'  }}>
                              {
                                ((item.AccRemark === null || item.AccRemark === '') ? '-' : item.AccRemark === '-')
                                  ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].Add}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, 'Acc')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div  className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              {/* {Number(item.ReceivedCash).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.ReceivedCash))}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word'  }}>
                              {/* {Number(item.ReceivedCheque).toLocaleString()} */}
                              {this.ReturnNumberCount(Number(item.ReceivedCheque))}
                            </div>
                            <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 130, maxWidth: 100 }}>
                              <input style={{ minWidth: 60, maxWidth: 60, marginRight: 0.5 }} value={this.state.AddPayment[index].inputNumberCash} type="number" placeholder={language[this.props.currentLanguage].CashDisplay} onKeyPress={(e) => this.onKeyEnter(e,'inputNumberCash', index, item.ID)} onChange={(e) => this.handleUpdate(e.target.value,'inputNumberCash', index)}/>
                              <button style={{ minWidth: 50, maxWidth: 50, border: '1px solid grey', fontSize: '13px' }} onClick={() => this.submitPaymentF('inputNumberCash', index, item.ID)} variant="primary">{language[this.props.currentLanguage].Submit}</button>
                            </div>
                            <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 130, maxWidth: 100 }}>
                              <input style={{ minWidth: 60, maxWidth: 60, marginRight: 0.5 }} value={this.state.AddPayment[index].inputNumberCek} type="number" placeholder={language[this.props.currentLanguage].ChequeDisplay} onKeyPress={(e) => this.onKeyEnter(e, 'inputNumberCek', index, item.ID)} onChange={(e) => this.handleUpdate(e.target.value,'inputNumberCek', index)}/>
                              <button style={{ minWidth: 50, maxWidth: 50, border: '1px solid grey', fontSize: '13px' }} onClick={() => this.submitPaymentF('inputNumberCek', index, item.ID)} variant="primary">{language[this.props.currentLanguage].Submit}</button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                    <div className='dcc headerColorTable' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                      <div style={{width:'100%' , flexDirection: 'row', display: 'flex' }}>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.3)', color: 'white' }}>
                          {''}
                        </div> */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {'Total'}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('Deposit')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('Old')}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {this.CalculateTotal('Advance')}
                        </div> */}
                        {this.state.dateArray.map((dateItem, idxa) => <div key={idxa} className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal(dateItem)}
                        </div>)}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {/* {this.CalculatezongTotal()} */}
                          {this.CalculateTotal('Total')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {/* {this.CalculatezongTotal()} */}
                          {this.CalculateTotal('Old')}
                        </div>
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('Advance')}
                        </div> */}
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                          {this.CalculateTotal('ReturnedCheque')}
                        </div> */}
                        {/* <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('ReturnedCheque')}
                        </div> */}
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', color: 'white', wordBreak: 'break-word' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', color: 'white', wordBreak: 'break-word' }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('ReceivedCash')}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white', wordBreak: 'break-word' }}>
                          {this.CalculateTotal('ReceivedCheque')}
                        </div>
                        <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', minWidth: 130, maxWidth: 100 }}>
                          {''}
                        </div>
                        <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', minWidth: 130, maxWidth: 100 }}>
                          {''}
                        </div>
                      </div>
                    </div>
                    <div style={{ color: 'red', fontWeight: 'bold' }}>*若更改用户账本资料请按Enter确定更改</div>
                  </>
                      }
                      {(this.state.userArray.length === 0 && this.state.loading === false) &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {'No Data'}
                    </div>
                  </div>
                      }
                      {this.state.loading === true && 
                      <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Loading .....</div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Admin);
  