import '../App.css';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Header from '../Component/Header'
import Form from 'react-bootstrap/Form'
import ErrorDragon from '../Image/errorsign.gif';
import SuccessLogo from '../Image/Success1.gif';
// import Container from 'react-bootstrap/Container'
import RemarkModal from '../Component/RemarkModal';
import moment from 'moment-timezone'
import { getAgentWorkerListFNC, addTempPaymentFNC, getViewTempZhangFNC, DeleteTempF, getTempListFNC, addPaymentFNC } from '../Api'
// import { exportzhangbenExcel } from '../Component/jsonToXlsx'
import { language } from '../language';

var timer

class Temp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dateArray: [],
      userArray: [],
      MessageType: '',
      DisplayError: false,
      DisplayErrorMessage: '',
      agentList: [],
      agentObj: {},
      HighlightID: '',
      RemarkModal: false,
      DisplayItems: '',
      SearchName: '',
      RamarkType: '',
      selectedDate: '',
      ShowSaveDetails: false,
      ViewZhangs: [],
      SelectedViewName: '',
      SelectedUploads: [],
      SelectedAll: false,
    }
    this.handleClose = this.handleClose.bind(this)
  }

  async componentDidMount() {
    const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
    const agentObj = {}
    agentList.forEach((item) => {
      agentObj[item.ID] = item.Name
    })
    this.setState({ agentObj }, () => {
      this.initPage();
    });
  }

  async initPage() {
    const getClientData = await getTempListFNC(this.props.userID, this.state.SearchName, this.props.username, this.props.token);
    // console.log('getClientData', getClientData)
    this.setState({ userArray: getClientData.userArray, dateArray: getClientData.dateArray })
  }

  handleChange(event, type) {
    this.setState({ [type]: event})
  }

  NumberOnly(value, field) {
    this.setState({ [field]: value });
  }

  handleClose() {
    this.setState({ 
      RamarkType: '',
      SearchName: '',
      RemarkModal: false,
      DisplayItems: '',
      selectedDate: '',
      ShowSaveDetails: false,
      SelectedViewName: '',
      ViewZhangs: [],
      SelectedUploads: [],
      SelectedAll: false,
    }, () => {
      this.initPage();
    })
  }

  DisplayMEssage(Message, type) {
    this.setState({ DisplayErrorMessage: Message, DisplayError: true, MessageType: type })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        DisplayErrorMessage: '',
        DisplayError: false,
        MessageType: '',
      }, () => {
        this.handleClose();
      });
    }, 3000);
  }

  OpenRemarkModal(item, type) {
    this.setState({ 
      DisplayItems: item,
      RemarkModal: true,
      RamarkType: type,
    })
  }

  onKeyEnterSearch(e) {
    if (e.charCode === 13) {
      this.initPage();
    }
  }

  ReturnNumberCount(NumberDisplay) {
    const DecimalNumber = NumberDisplay % 1
    return (DecimalNumber < 0.5) ? Math.floor(NumberDisplay).toLocaleString() : Math.ceil(NumberDisplay).toLocaleString()
  }

  async UploadData(Items) {
    const finalObj = {
      DateTime: Items.PayDate,
      Worker:  Items.ResponsibleWorker,
      Client: Items.Name,
      type: (Items.PayCash !== '' && Items.PayCheque === '') ? 'Cash' : (Items.PayCash === '' && Items.PayCheque !== '') ? 'Cheque' : 'both' ,
      CashAmount: Items.PayCash,
      ChequeAmount: Items.PayCheque,
      UserID: Items.ID,
      Remark: Items.Remark,
    }
    const AddPaymenttoServer = await addPaymentFNC(this.props.username, finalObj, 'TEMP', this.props.token);
    if (AddPaymenttoServer.message === 'Success') {
      this.DisplayMEssage('Success Add Payment', 'Success');
      // this.initPage();
    }
    if (AddPaymenttoServer.error === 'Empty Amount') {
      this.DisplayMEssage(language[this.props.currentLanguage].EmptyAmount, 'Error');
    }
  }

  async SaveZhang(item) {
    const FinalResult = {
      ResponsibleWorker: item.ResponsibleWorker,
      ID: item.ID,
      Name: item.Name,
      PayCash: item.PayCash,
      PayCheque: item.PayCheque,
      PayDate: item.PayDate,
      Remark: item.Remark,
    }
    if (item.PayDate!== '' && !this.state.dateArray.includes(item.PayDate)) {
      this.DisplayMEssage('日期錯誤', 'Error')
    } else {
      const SaveTemp = await addTempPaymentFNC(FinalResult, this.props.username, this.props.token);
      if (SaveTemp.message === 'Success Saved Temp') {
        this.DisplayMEssage(SaveTemp.message, 'Success')
      }
    }
  }

  async ViewSavedZhang(Name) {
    this.setState({ SelectedViewName: Name })
    const getSaveZhang = await getViewTempZhangFNC(Name, this.props.username, this.props.token)
    if (getSaveZhang.message === 'Success') {
      this.setState({ ViewZhangs: getSaveZhang.SavedZhang })
    }
  }

  ChangeCash(value, index, type) {
    let userArray = this.state.userArray
    userArray[index][type] = value
    this.setState({ userArray })
  }

  CheckboxFunc(SelectedName) {
    const AllUsers = []
    const userArray = this.state.userArray
    let SelectedUploads = this.state.SelectedUploads
    if (SelectedName === 'All') {
      for (let i=0; i < userArray.length; i++) {
        AllUsers.push(userArray[i].Name)
      }
      if (SelectedUploads.length > 0) {
        this.setState({ SelectedUploads: [], SelectedAll: false })
      } else {
        this.setState({ SelectedUploads: AllUsers, SelectedAll: true })
      }
    } else {
      if (SelectedUploads.includes(SelectedName)) {
        SelectedUploads = SelectedUploads.filter(function (el) {
          return el !== SelectedName
        });
        this.setState({ SelectedUploads, SelectedAll: false })
      } else {
        SelectedUploads.push(SelectedName)
        this.setState({ SelectedUploads })
      }
    }
  }

  // async UploadAllData() {
  //   const SelectedUploads = this.state.SelectedUploads
  // }

  async DeletedSaveZhang(Zhang) {
    const ZhangID = Zhang.ID
    const DeletedZhang = await DeleteTempF(ZhangID, this.props.username, this.props.token)
    if (DeletedZhang.message === 'successful') {
      this.DisplayMEssage('Successful Deleted Saved 帳', 'Success')
    }
  }

  render() {
    // const ViewData = this.state.ViewZhangs.sort((a, b) => (a.ChequeDate > b.ChequeDate) ? 1 : -1)
    return (
      <div className='FontSize'>
        <Header/>
        <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
          {this.state.RemarkModal && 
          <RemarkModal RemarkModal={this.state.RemarkModal} handleClose={this.handleClose} DisplayItems={this.state.DisplayItems} RamarkType={this.state.RamarkType}/>}
          <div style={{ display: 'flex', padding: 10, justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <h2 style={{ marginRight: 10, color: 'black' }}>📖 TEMP 账本</h2>
              <div style={{ paddingLeft: 5 }}><Form.Control value={this.state.SearchName} placeholder='Search Name' onChange={(e) => this.setState({ SearchName: e.target.value })} onKeyPress={(e) => this.onKeyEnterSearch(e)} /></div>
              {this.state.MessageType !== '' && 
            <div className='dcc' style={{ marginBottom: 0, display: 'flex', flexDirection: 'row', padding: 5, borderRadius: 10 }}>
              {this.state.MessageType === 'Error' && 
            <img src={ErrorDragon} alt="Logo" style={{ width: 80 }}/>
              }
              {this.state.MessageType === 'Success' && 
            <img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/>
              }
              <div style={{ fontWeight: 'bold', fontSize: '20px', color: (this.state.MessageType === 'Error') ? 'red' : '#77B43F' }}>{` ${this.state.DisplayErrorMessage}`}</div>
            </div>
              }
            </div>
            <div style={{ display: 'flex' }}>
              {/* <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150 }} onClick={() => this.UploadAllData()}>{'Upload Selected'}</div> */}
            </div>
          </div>
          <div style={{ overflowY: 'auto', display: 'flex' }}>
            <div style={{ width: '73%', marginBottom: 50, backgroundColor: 'rgba(0, 0, 0, 0.1)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', backgroundColor: 'black', padding: 5 }}>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'ID'}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Name}
                          </div>
                          <div  className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].ResponsiblePerson}
                          </div>
                          {/* {this.state.dateArray.map((item, idx) => 
                            <div key={idx} className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white', display: 'flex', flexDirection: 'row', wordBreak: 'break-word'}}>
                              <div style={{ marginRight: 10 }}>{moment(item, 'YYYYMMDD').format('DD/MM')}</div>
                            </div>)} */}
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Cash'}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Cheque'}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Date(20240101)'}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'Remark'}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%/1.5)', color: 'white'}}>
                            {'SAVE'}
                          </div>
                          <div className="dcc resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Action'}
                            {/* <input style={{ marginLeft: 10 }} type="checkbox" checked={this.state.SelectedAll} onClick={() => this.CheckboxFunc('All')}/> */}
                          </div>
                        </div>
                      </div>
                      {this.state.userArray.length > 0 && 
                  <div style={{ minHeight: '80vh', maxHeight: '80vh', overflowY: 'auto'}}>
                    {this.state.userArray.map((item, index) => {
                      return(
                        <div className='dcc highlight' key={index} style={{ display: 'flex', marginTop: 0, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (item.ID === this.state.HighlightID) ? '#bce3ff' : (index%2) ? '#F1F1F1' : 'white' }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex', marginBottom: 1.5 }}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.ID}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {item.Name}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {this.state.agentObj[item.ResponsibleWorker]}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              <input value={item.PayCash} onChange={(e) => this.ChangeCash(e.target.value, index, 'PayCash' )} style={{ width: 100 }}/>
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              <input value={item.PayCheque} onChange={(e) => this.ChangeCash(e.target.value, index, 'PayCheque' )} style={{ width: 100 }}/>
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* <select style={{ width: 110 }} value={item.PayDate} onChange={(e) => {
                                this.ChangeCash(e.target.value, index, 'PayDate' )
                              }}>
                                <option value=''>{'Select'}</option>
                                {this.state.dateArray.map((item, inde) => 
                                  <option key={inde} value={item}>{moment(item).format('YYYY-MM-DD')}</option>
                                )}
                              </select> */}
                              <input value={item.PayDate} onChange={(e) => this.ChangeCash(e.target.value, index, 'PayDate' )} style={{ width: 100 }}/>
                            </div>
                            <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              {
                                ((item.Remark === null || item.Remark === '') ? '-' : item.Remark === '-')
                                  ? <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].Add}</div> 
                                  : <div className='RemarkButton' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.OpenRemarkModal(item, '')}>{language[this.props.currentLanguage].View}</div>}
                            </div>
                            <div className="dcc resulttable"style={{ flexDirection: 'column', display: 'flex', width:'calc(100%/1.5)', wordBreak: 'break-word' }}>
                              <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.SaveZhang(item)}>{'SAVE'}</div> 
                            </div>
                            <div className="dcc resulttable"style={{ flexDirection: 'row', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                              {/* <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.ViewSavedZhang(item.Name)}>{language[this.props.currentLanguage].View}</div>  */}
                              <div className='RemarkButtonAdd' style={{ border: '1px solid black', borderRadius: 5, paddingLeft: 5, paddingRight: 5, cursor: 'pointer'}} onClick={() => this.UploadData(item)}>{'Upload'}</div> 
                              {/* <input style={{ marginLeft: 10 }} type="checkbox" checked={this.state.SelectedUploads.includes(item.Name)} onClick={() => this.CheckboxFunc(item.Name)}/> */}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                      }
                      {this.state.userArray.length === 0 &&
                  <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                    <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                      {'No Data'}
                    </div>
                  </div>
                      }
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
            <div style={{ marginLeft: 10, width: '25%', border: '1px solid black', marginBottom: 50, padding: 10, display: 'flex', flexDirection: 'column', minHeight: '85vh', maxHeight: '85vh' }}>
              <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ fontSize: '18px', fontWeight: 'bold'}}>{`Details`}</div>
                {/* <div className='dcc' style={{ border: '1px solid black', borderRadius: 5, padding: 2, cursor: 'pointer', backgroundColor: 'white', width: 100 }} onClick={() => this.UploadData(this.state.SelectedViewName)}>Upload</div> */}
              </div>
              <div style={{  overflowY: 'auto', maxHeight: '75vh', display: 'flex', flexDirection: 'column', fontSize: '14px', paddingTop: 10, fontWeight: 'bold' }}>
                <div>{'當前賬單日期'}</div>
                {this.state.dateArray.map((items, idd) => {
                  return(
                    <div key={idd} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 }}>
                      <div>{`${idd+1}. ${items} = (${moment(items).format('YYYY-MM-DD')})`}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    userID: state.userID,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserID, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          userID: UserID,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Temp);
  