import '../App.css';
import React, {Fragment} from 'react';
import { connect } from 'react-redux';
// import Table from 'react-bootstrap/Table';
// import Container from 'react-bootstrap/Container'
import { language } from '../language';
import loading from '../Image/loading.gif';
import Header from '../Component/Header'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { showPayment, getAgentWorkerListFNC,updatePayment, expandShowPayment } from '../Api'
import Modal from 'react-bootstrap/Modal'
import moment from 'moment-timezone';
import Alert from 'react-bootstrap/Alert'
import { exportpaymentExcel } from '../Component/jsonToXlsx'
var timer

class PaymentList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      loadingDetails: false,
      paymentList: [],
      AllDataList: [],
      fromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      toDate: moment().format('YYYY-MM-DD'),
      todayDate: moment().format('YYYY-MM-DD'),
      agentList: [],
      agentObj: {},
      filterAgent: '',
      fromAmount: '0',
      toAmount: '5000',
      ConfirmModal: false,
      SortView: 'none',
      UserIDandDate: [],
      SendID: [],
      notificationMessage: '',
      notificationType: 'success',
      showNotification: false,
      AllSelectedIDs: [],
      page: 1,
      countperpage: 20,
      countpagemax: 1,
      countpagemin: 1,
      OpenDetailsModal: false,
      MultiIDs: '',
      ShowDetailsLists: [],
    }
    this.handleClose = this.handleClose.bind(this);
    this.SendNotifications = this.SendNotifications.bind(this);
  }

  async componentDidMount() {
    // agentList = AllDataList.sort((a, b) => (a.Username > b.Username) ? 1 : -1)
    if (this.props.username === '') {
      this.props.history.push('/')
    } else if (this.props.role !== 'admin' && this.props.role !== 'account') {
      sessionStorage.removeItem('shouzhangtoken');
      sessionStorage.removeItem('shouzhangusername');
      sessionStorage.removeItem('shouzhanguserID');
      sessionStorage.removeItem('shouzhangrole');
      this.props.setLoginStatus('', '', '', '');
      this.props.history.push('/')
    } else {
      const agentList = await getAgentWorkerListFNC(this.props.username, this.props.token)
      const agentObj = {}
      agentList.forEach((item) => {
        agentObj[item.ID] = item.Name
      })
      this.setState({ agentList, agentObj });
      this.initPage()
    }
  }

  async initPage() {
    let getPaymentData;
    if(this.state.filterAgent === ''){
      getPaymentData = await showPayment(this.props.username, 'All', moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.props.token);
    } else {
      getPaymentData = await showPayment(this.props.username, this.state.filterAgent,  moment(this.state.fromDate).format('YYYY-MM-DD'),
        moment(this.state.toDate).format('YYYY-MM-DD'), this.props.token);
    }
    // console.log('getPaymentData', getPaymentData)
    this.setState({
      paymentList: getPaymentData,
      AllDataList: getPaymentData,
      loading: false,
    });
    if (getPaymentData.length > 0) {
      this.setState({
        countpagemax: Math.ceil(
          getPaymentData[0].FullCount / this.state.countperpage,
        ),
      });
    } else {
      this.setState({
        countpagemax: 1,
        page: 1,
      });
    }
  }

  // FilterSamevalye(AllDataList) {
  //   let result = AllDataList.reduce((a, v) => {
  //     let obj = a.find(i => i.Client === v.Client && i.AddedBy === v.AddedBy && i.Type === v.Type);
  //     if (obj) {
  //       obj.ID += `,${v.ID}`
  //       obj.Amount += Number(v.Amount);
  //       obj.Multi = 'yes'
  //     } else {
  //       v.Multi = 'No'
  //       a.push(v);
  //     }
  //     return a;
  //   }, [])
  //   // console.log('result', result)
  //   this.setState({
  //     paymentList: result,
  //     AllDataList: result,
  //   })
  // }

  changeDate(value, key) {
    this.setState({ [key]: value}, ()=>{
      this.initPage()
    })
  }

  changeAmount(value, key) {
    let tempValue = value.replace(/[^0-9.-]/g,'') 
    if (tempValue.startsWith('0')) tempValue = Number(tempValue)
    this.setState({ [key]: tempValue });
  }

  filterAgent(e) {
    // console.log('value', e.target.value)
    this.setState({ filterAgent: e.target.value}, () => this.initPage())
  }

  onKeyEnter(e) {
    if (e.key === 'Enter') {
      this.initPage()
    }
  }

  exportExcel() {
    if (this.state.filterAgent !== '') {
      const filterArray = this.state.paymentList.filter((item) => item.User === this.state.filterAgent)
      exportpaymentExcel(filterArray, this.state.agentObj)
    } else {
      exportpaymentExcel(this.state.paymentList, this.state.agentObj)
    }
  }

  handleClose() {
    this.setState({ ConfirmModal: false, UserIDandDate: [],SendID: [], OpenDetailsModal: false, MultiIDs: '', ShowDetailsLists: [] })
  }

  async handleSubmit() {
    // let SelectedIDs = []
    // for (var i=0; i< this.state.SendID.length; i++) {
    //   SelectedIDs.push({IDs: this.state.SendID[i] })
    // }
    const updatePaymentresult = await updatePayment(this.state.SendID, this.props.username, this.props.token);
    if(updatePaymentresult.message === 'Update Success') {
      this.openNotification(updatePaymentresult.message, 'success')
      this.setState({ ConfirmModal: false, UserIDandDate: [], SendID: [], AllSelectedIDs: [] }, () => {
        this.handleClose();
      })
    } else {
      this.openNotification('Error', 'error')
      this.setState({ ConfirmModal: false, UserIDandDate: [], SendID: [], AllSelectedIDs: [] })
    }
  }

  SortView() {
    const SortView = this.state.SortView
    let paymentList = this.state.paymentList
    let AllDataList = JSON.parse(JSON.stringify(this.state.paymentList))
    if (SortView === 'none') {
      paymentList = AllDataList.sort((a, b) => ( moment(a.DateTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss') >  moment(b.DateTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss')) ? 1 : -1)
      this.setState({ SortView: 'small' })
    }
    if (SortView === 'small') {
      paymentList = AllDataList.sort((a, b) => ( moment(a.DateTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss') >  moment(b.DateTime).tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD HH:mm:ss')) ? -1 : 1)
      this.setState({ SortView: 'big' })
    }
    if (SortView === 'big') {
      paymentList = AllDataList
      this.setState({ SortView: 'none' })
    }
    this.setState({ paymentList })
  }

  openNotification(message, type) {
    this.setState({ notificationMessage: message, notificationType: type }, () => {
      this.initPage();
      this.setState({ showNotification: true, ConfirmModal: false,  UserIDandDate: [], SendID: [] });
    });
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        showNotification: false,
      });
    }, 2000);
  };

  SelectedReAllID(SelectedID, e, Multi) {
    let AllSelectedIDs = JSON.parse(JSON.stringify(this.state.AllSelectedIDs))
    if (SelectedID === 'All') {
      const paymentList = this.state.paymentList
      let ArrayID = []
      if (e.target.checked) {
        paymentList.forEach((items) => {
          ArrayID.push(items.ID)
        })
        this.setState({ SelectedAll: true })
      } else {
        ArrayID = []
        this.setState({ SelectedAll: false })
      }
      this.setState({ AllSelectedIDs: ArrayID })
    } else {
      if(AllSelectedIDs.includes(SelectedID)) {
        AllSelectedIDs = AllSelectedIDs.filter(function (el) {
          return el !== SelectedID
        });
      } else {
        AllSelectedIDs.push(SelectedID)
      }
      this.setState({ AllSelectedIDs })
    }
  }

  SendNotifications(type, SelectedID) {
    const AllSelectedIDs = this.state.AllSelectedIDs
    // console.log('AllSelectedIDs', AllSelectedIDs)
    let finsstorage = []
    if (type === 'Multi') {
      if (AllSelectedIDs.length > 0) {
        for(let i = 0; i < AllSelectedIDs.length; i += 1) {

          const findname = this.state.paymentList.find((item) => {return item.ID === AllSelectedIDs[i]})

          finsstorage.push(AllSelectedIDs.length -1 === i ? ` ID ${findname.ID}`
            : ` ID ${findname.ID},`)
        }
      }
    } 
    // console.log('AllSelectedIDs', AllSelectedIDs)
    let FilterSelectedID = []
    AllSelectedIDs.forEach((items) => {
      if(typeof items !== 'number') {
        items.split(',').forEach((number) => {
          FilterSelectedID.push(Number(number))
        })
      } else {
        FilterSelectedID.push(items)
      }
    })
    this.setState({ UserIDandDate: finsstorage, ConfirmModal: true, SendID: FilterSelectedID})
  }

  SendConfirm(ID, Status, Multi){
    const StatusStorage = []
    let IDStorage = []
    StatusStorage.push(Status);
    if (Multi === 'yes') {
      ID.split(',').forEach((item) => {
        IDStorage.push(Number(item))
      })
    } else {
      IDStorage.push(ID);
    }
    this.setState({ UserIDandDate: StatusStorage, ConfirmModal: true, SendID: IDStorage })
  }

  CalculateTotal(Totaltype) {
    let userArray = this.state.paymentList
    let Totals = {
      TotalNumber: 0,
    }
    userArray.forEach((items) => {
      Totals.TotalNumber += Number(items[Totaltype])
    })
    return Totals.TotalNumber.toLocaleString()
  }

  changePage() {
    this.setState({ paymentList: [], FilterType: 'All', AllDataList: [] }, () => {
      this.initPage();
    });
  }

  FilterTableView(value, type) {
    let AllDataList = this.state.AllDataList
    if (value === 'All') {
      this.setState({ paymentList: AllDataList, [type]: value })
    } else {
      const Filtered = AllDataList.filter((values) => values.Type === value);
      this.setState({ paymentList: Filtered, [type]: value })
    }
  }

  DisplayDetials(IDSs) {
    // console.log('IDSs', IDSs.split(','))
    this.setState({
      OpenDetailsModal: !this.state.OpenDetailsModal,
      MultiIDs: IDSs.split(','),
    }, () => {
      this.callDetails(this.state.MultiIDs);
    })
  }

  async callDetails(MultiIDs) {
    this.setState({ loadingDetails: true })
    const CallDetails = await expandShowPayment(this.props.username, this.props.token, MultiIDs)
    if (CallDetails) {
      this.setState({
        ShowDetailsLists: CallDetails,
        loadingDetails: false,
      })
    }
  }

  render() {
    // console.log('paymentList', this.state.paymentList)
    return (
      <div className='FontSize'>
        <Header/>
        {this.state.loading ? (
          <div className="loadingMessage"> Loading ... Please Wait a moment</div>
        ) : (
          <div className='DashboardBackground' style={{ paddingLeft: 5, paddingRight: 5, minHeight: '100vh', paddingTop: 60 }}>
            <Modal centered show={this.state.ConfirmModal} onHide={this.handleClose}  >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  ALERT
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ marginRight: 10}}>
                  您确定要确认此 
                  {this.state.UserIDandDate.length > 0 && this.state.UserIDandDate.map((item, index) => {
                    return(
                      item
                    );
                  })}
              . Total {this.state.UserIDandDate.length}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
                <Button variant="success" onClick={() => this.handleSubmit()}>{language[this.props.currentLanguage].Submit}</Button>
              </Modal.Footer>
            </Modal>
            {this.state.OpenDetailsModal && 
            <Modal centered show={this.state.OpenDetailsModal} size="xl" onHide={this.handleClose}>
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div style={{ marginRight: 10, maxHeight: '70vh', minHeight: '70vh', justifyContent: 'center', display: 'flex'}}>
                  <div style={{ width: '98%', display: 'flex', flexDirection: 'column', border: '1px solid lightgrey', marginBottom: 0, padding: 0, minHeight: '60vh', maxHeight: '60vh' }}>
                    <div style={{ width: '100%', borderBottom: '1px solid black', padding: 5, backgroundColor: 'black', fontWeight: 'bold', display: 'flex', flexDirection: 'row', color: 'white' }}>
                      <div style={{ width: 'calc(100%)'}}>ID</div>
                      <div style={{ width: 'calc(100%)'}}>{language[this.props.currentLanguage].DateTime}</div>
                      <div style={{ width: 'calc(100%)'}}>{language[this.props.currentLanguage].Guest}</div>
                      <div style={{ width: 'calc(100%)'}}>{language[this.props.currentLanguage].StaffMember}</div>
                      <div style={{ width: 'calc(100%)'}}>{'AddedBy'}</div>
                      <div style={{ width: 'calc(100%)'}}>{language[this.props.currentLanguage].Amount}</div>
                      <div style={{ width: 'calc(100%)'}}>Type</div>
                      <div style={{ width: 'calc(100%)'}}>Status</div>
                      <div style={{ width: 'calc(100%)'}}>Action</div>
                    </div>
                    {this.state.loadingDetails === true && 
                  <div className='dcc'><img src={loading} alt="Logo" className="dcc loadingimages" /></div>}
                    {(this.state.ShowDetailsLists.length > 0 && this.state.loadingDetails === false) && 
                    <>
                      {this.state.ShowDetailsLists.map((items, indexxx) => {
                        return(
                          <div className='scrollhost' key={indexxx} style={{ width: '100%', display: 'flex', flexDirection: 'column', maxHeight: 'auto', overflowX: 'auto'}}>
                            <div style={{ width: '100%', paddingTop: 10, paddingBottom: 10, display: 'flex', flexDirection: 'row' }}>
                              <div style={{ width: 'calc(100%)'}}>{items.ID}</div>
                              <div style={{ width: 'calc(100%)'}}>{moment(items.DateTime).format('YYYY-MM-DD')}</div>
                              <div style={{ width: 'calc(100%)'}}>{items.Client}</div>
                              <div style={{ width: 'calc(100%)'}}>{this.state.agentObj[items.Worker]}</div>
                              <div style={{ width: 'calc(100%)'}}>{items.AddedBy}</div>
                              <div style={{ width: 'calc(100%)'}}>{items.Amount.toLocaleString()}</div>
                              <div style={{ width: 'calc(100%)'}}>{items.Type}</div>
                              <div style={{ width: 'calc(100%)'}}>{items.ApprovalStatus}</div>
                              <div style={{ width: 'calc(100%)', display: 'flex', flexDirection: 'row', color: 'blue'}}>
                                <div style={{ width: 'calc(100%)', cursor: 'pointer' }} onClick={() => this.SendConfirm(items.ID, 
                                  `ID ${items.ID} ${language[this.props.currentLanguage].DateTime} ${moment(items.DateTime).format('YYYY-MM-DD HH:mm:ss')}`, items.Multi)}>{language[this.props.currentLanguage].confirm}</div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </>}
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={() => this.handleClose()}>{language[this.props.currentLanguage].Close}</Button>
              </Modal.Footer>
            </Modal>
            }
            <Alert style={{ zIndex: 99, position: 'fixed', right: 0, top: 80, width: '70%', left: 250}}show={this.state.showNotification} variant={this.state.notificationType === 'error' ? 'danger' : 'success'}>
              <Alert.Heading>{this.state.notificationMessage}</Alert.Heading>
            </Alert>
            <div style={{ display: 'flex', padding: 5, justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ marginRight: 10 }}>交易</h2>
                <div style={{ marginRight: 10 }}>工作人员: </div>
                <Form.Select style={{ width: 150}} onChange={(e) => this.filterAgent(e)}>
                  <option value=''>全部</option>
                  {this.state.agentList.map((item) => 
                    <option value={item.ID}>{item.Name}</option>
                  )}
                </Form.Select>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                  <div style={{ marginRight: 10}}>日期: </div>
                  <Form.Control style={{ width: 200}} value={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'fromDate')} type="date"/>
                  <div style={{ marginRight: 10, marginLeft: 10}}>-</div>
                  <Form.Control style={{ width: 200}} value={this.state.toDate} min={this.state.fromDate} max={this.state.todayDate} onChange={(e) => this.changeDate(e.target.value, 'toDate')} type="date"/>
                </div>
              </div>
            </div>
            <div  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',  marginBottom: 5 }}>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', marginRight: 5, width: 150 }} onClick={() => this.SendNotifications('Multi', '')}>{language[this.props.currentLanguage].MultiConfirm}</div>
              <div className='dcc' style={{ border: '1px solid black', backgroundColor: 'white', color: 'black', padding: 7, borderRadius: 3, cursor: 'pointer', width: 150 }} onClick={() => this.exportExcel()}>{'Excel'}</div>
            </div>
            <div className="dcc" style={{ width: '93%', backgroundColor: 'rgba(185, 185, 185, 0.5)' }}>
              <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '100%'}}>
                <div  style={{ flex: "1", padding: 5}}>
                  <Fragment>
                    <div>
                      <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center'  }}>
                        <div className="headerColorTable" style={{width:'100%' , fontWeight:"bold", position: "relative", flexDirection: 'row', display: 'flex', padding: 5 }}>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'ID'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].DateTime}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Guest}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].StaffMember}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'AddedBy'}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {language[this.props.currentLanguage].Amount}
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            <select style={{ width: 'auto', fontSize: '14px', maxWidth: 80 }} value={this.state.FilterType} onChange={(e) => this.FilterTableView(e.target.value, 'FilterType')}>
                              <option value='All'>{`${language[this.props.currentLanguage].All}${language[this.props.currentLanguage].Type}`}</option>
                              <option value={'Cash'}>{'Cash'}</option>
                              <option value={'cheque'}>{'cheque'}</option>
                            </select>
                          </div>
                          <div  className="resulttableTitle" style={{width:'calc(100%)', color: 'white'}}>
                            {'Status'}
                          </div>
                          <div className="dcc resulttableTitle" style={{display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100, color: 'white'}}>
                            {'Action'}
                            <Form.Check
                              style={{ fontSize: 18, alignItems: 'center', display: 'flex', marginLeft: 5 }}
                              type="checkbox"
                              // checked={this.state.AllSelectedIDs.includes(items.ID)}
                              onChange={(e) => this.SelectedReAllID('All', e)}
                            />
                          </div>
                        </div>
                      </div>
                      <>
                        {this.state.paymentList.length > 0 && this.state.paymentList.map((item, index) => {
                          return(
                            <div className='dcc' key={index} style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 0, backgroundColor: (index%2) ? '#F1F1F1' : 'white' }}>
                              <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Multi === 'yes' && 
                                    <div className='dcc POButton' style={{ borderRadius: 100, backgroundColor: 'black', paddingLeft: 5, paddingRight: 5, width:'50%', cursor: 'pointer' }} onClick={() => this.DisplayDetials(item.ID)}>+</div>
                                  }
                                  {item.Multi !== 'yes' && 
                                  <div style={{ width:'calc(100%)' }}>{item.ID}</div>
                                  }
                                  {/* {item.Multi === '' && 
                                    <div style={{ width:'calc(100%)' }}></div>
                                  } */}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {moment(item.DateTime).format('YYYY-MM-DD')}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Client}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {this.state.agentObj[item.Worker]}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.AddedBy}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Amount.toLocaleString()}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.Type}
                                </div>
                                <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', wordBreak: 'break-word' }}>
                                  {item.ApprovalStatus}
                                </div>
                                <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100}}>
                                  {/* <button disabled={item.ApprovalStatus === 'Pending Confirm'} onClick={() => this.SendConfirm(item.ID, 
                                    `ID ${item.ID} ${language[this.props.currentLanguage].DateTime} ${moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}`,)} 
                                  // SendConfirm
                                  variant="primary">Confirm</button> */}
                                  <div className='dcc POButton'
                                    style={{ borderRadius: 100, backgroundColor: 'black', paddingLeft: 5, paddingRight: 5, width:'50%', cursor: 'pointer' }}
                                    onClick={() => this.SendConfirm(item.ID, 
                                      `ID ${item.ID} ${language[this.props.currentLanguage].DateTime} ${moment(item.DateTime).format('YYYY-MM-DD HH:mm:ss')}`, item.Multi)} >
                                    {language[this.props.currentLanguage].confirm}
                                  </div>
                                  <Form.Check
                                    style={{ fontSize: 18, alignItems: 'center', display: 'flex' }}
                                    type="checkbox"
                                    checked={this.state.AllSelectedIDs.includes(item.ID)}
                                    onChange={(e) => this.SelectedReAllID(item.ID, e, item.Multi)}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                        {this.state.paymentList.length === 0 && 
                        <div style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10, backgroundColor: 'lightgray' }}>
                          <div style={{width:'100%', textAlign: 'center', fontWeight: 'bold' }}>
                            {'No Data'}
                          </div>
                        </div>
                        }
                        {this.state.paymentList.length > 0 && 
                        <div className='dcc headerColorTable' style={{ display: 'flex', marginTop:3, flexWrap: 'wrap', justifyContent: 'center', marginBottom: 10 }}>
                          <div style={{width:'100%' , flexDirection: 'row', display: 'flex'}}>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {'Total'}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}>
                              {this.CalculateTotal('Amount')}
                            </div>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ flexDirection: 'column', display: 'flex', width:'calc(100%)', color: 'white' }}/>
                            <div className="dcc resulttable" style={{ display: 'flex', flexDirection:'row', minWidth: 140, maxWidth: 100 }}/>
                          </div>
                        </div>
                        }
                        <div>
                          <div style={{ marginBottom: 20, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            {this.state.page > this.state.countpagemin ? (
                              <button  style={{ textAlign: 'center',backgroundColor: 'black', color: 'white', width: 100, borderColor: 'black' }} 
                                class="btn my-cusomized-button button2" 
                                onClick={() =>
                                  this.setState(
                                    {
                                      page: this.state.page - 1,
                                    },
                                    () => {
                                      this.changePage(this.state.page);
                                    },
                                  )
                                }>
                                {'<<'}
                              </button>
                            ) : (
                              <div style={{ width: 100 }}/>
                            )}
                            <div style={{ fontWeight: 'bold', marginLeft: 100, marginRight: 100}}>{this.state.page}</div>
                            {this.state.page < this.state.countpagemax ? (
                              <button
                                style={{ textAlign: 'center', backgroundColor: 'black', color: 'white', width: 100, borderColor: 'black' }}
                                class="btn my-cusomized-button button2" 
                                onClick={() =>
                                  this.setState(
                                    {
                                      page: this.state.page + 1,
                                    },
                                    () => {
                                      this.changePage();
                                    },
                                  )
                                }>
                                {'>>'}
                              </button>
                            ) : (
                              <div style={{ width: 100 }} />
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  </Fragment> 
                </div> 
              </div>
            </div>
          </div>
        )} 
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PaymentList);
  