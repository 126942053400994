import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import { language } from '../language';
import React from 'react';
import { connect } from 'react-redux';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      SelectedExample: this.props.currentLanguage,
    }
  }

  setLanguage() {
    if(this.state.SelectedExample === 'cn') {
      this.setState({ SelectedExample: 'en' })
      this.props.setLanguage('en')
    } else {
      this.setState({ SelectedExample: 'cn' })
      this.props.setLanguage('cn')
    }
  }

  render() {
    return (
      <div>
        {this.props.username && <Navbar sticky="top" expand="lg" variant="dark" style={{ backgroundColor: 'rgba(0,0,0,.5)', position: 'fixed', width: '100%' }}>
          <Container fluid>
            <Navbar.Brand href={(this.props.role === 'admin' || this.props.role === 'account') ? "/Admin" : this.props.role === 'obs' ? '/Obs' : this.props.role === 'player' ? '/PlayerPage' : '/Agent'}>账本</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              {this.props.role === 'admin' && 
            <>
              <Nav className="me-auto">
                <Nav.Link href="/PaymentList">交易</Nav.Link>
                <Nav.Link href="/ActionList">活动</Nav.Link>
                <Nav.Link href="/AgentList">{language[this.props.currentLanguage].StaffMember}</Nav.Link>
                <Nav.Link href="/ClientList">{language[this.props.currentLanguage].Guest}</Nav.Link>
                <Nav.Link href="/SimplifiedPaymentList">日结</Nav.Link>
                <Nav.Link href="/HistoryPage">历史记录</Nav.Link>
              </Nav>
            </>}
              {this.props.role === 'account' && 
            <>
              <Nav className="me-auto">
                <Nav.Link href="/PaymentList">交易</Nav.Link>
                <Nav.Link href="/ActionList">活动</Nav.Link>
                <Nav.Link href="/SimplifiedPaymentList">日结</Nav.Link>
                <Nav.Link href="/HistoryPage">历史记录</Nav.Link>
                <Nav.Link href="/ChangePass">更改密碼</Nav.Link>
              </Nav>
            </>}
              {this.props.role === 'obs' && 
            <>
              <Nav className="me-auto">
                {/* <Nav.Link href="/Obs">{language[this.props.currentLanguage].Guest}</Nav.Link> */}
                <Nav.Link href="/SimplifiedPaymentList">日结</Nav.Link>
                <Nav.Link href="/HistoryPage">历史记录</Nav.Link>
                <Nav.Link href="/ChangePass">更改密碼</Nav.Link>
              </Nav>
            </>}
              {this.props.role === 'work' && 
            <>
              <Nav className="me-auto">
                <Nav.Link href="/Temp">Temp賬本</Nav.Link>
                <Nav.Link href="/HistoryPage">历史记录</Nav.Link>
                <Nav.Link href="/ChangePass">更改密碼</Nav.Link>
              </Nav>
            </>}
              {this.props.role === 'agent' && 
            <>
              <Nav className="me-auto"><Nav.Link href="/HistoryPage">历史记录</Nav.Link></Nav>
              <Nav.Link href="/ChangePass">更改密碼</Nav.Link>
            </>}
              {this.props.role === 'player' && 
            <>
              <Nav className="me-auto">
                <Nav.Link href="/ChangePass">更改密碼</Nav.Link>
                {/* <Nav.Link href="/PlayerConfirm">用户确认</Nav.Link> */}
              </Nav>
            </>}
              {/* {this.props.role === 'admin'
                ? <Nav className="me-auto">
                  <Nav.Link href="/AgentList">{language[this.props.currentLanguage].StaffMember}</Nav.Link>
                  <Nav.Link href="/ClientList">{language[this.props.currentLanguage].Guest}</Nav.Link>
                  <Nav.Link href="/PaymentList">交易</Nav.Link>
                  <Nav.Link href="/ActionList">活动</Nav.Link>
                  <Nav.Link href="/SimplifiedPaymentList">日结</Nav.Link>
                </Nav>
                : this.props.role === 'account' ? <Nav className="me-auto">
                  <Nav.Link href="/PaymentList">交易</Nav.Link>
                  <Nav.Link href="/ActionList">活动</Nav.Link>
                  <Nav.Link href="/SimplifiedPaymentList">日结</Nav.Link>
                </Nav>
                  :this.props.role === 'obs' ? <Nav className="me-auto">
                    <Nav.Link href="/Obs">{language[this.props.currentLanguage].Guest}</Nav.Link>
                  </Nav>
                    : this.props.role === 'work' ? <Nav className="me-auto">
                      <Nav.Link href="/Agent">{language[this.props.currentLanguage].Guest}</Nav.Link>
                    </Nav>
                      // : this.props.role === 'agent' ? <Nav className="me-auto">
                      //   <Nav.Link href="/Agent">{language[this.props.currentLanguage].Guest}</Nav.Link>
                      // </Nav>
                      // : this.props.role === 'player' ? <Nav className="me-auto">
                      //   <Nav.Link href="/Agent">{language[this.props.currentLanguage].Guest}</Nav.Link>
                      // </Nav>
                      :<Nav className="me-auto">
                        <Nav.Link href="/AgentPaymentList">日结</Nav.Link>
                      </Nav>} */}
              <Nav>
                {/* <Nav.Link href="#" disabled style={{color: 'white'}}>
                </Nav.Link> */}
                {/* <Button variant="success" value={this.state.SelectedExample} onClick={() => this.setLanguage()}>{language[this.props.currentLanguage][this.state.SelectedExample]}</Button> */}
                <Button value={this.state.SelectedExample} variant='success' onClick={() => this.setLanguage()}>{language[this.props.currentLanguage][this.state.SelectedExample]}</Button>
              </Nav>
              <Nav>
                <Nav.Link href="#" disabled style={{color: 'white', fontWeight: 'bold'}}>登入：{this.props.username}</Nav.Link>
                <Button variant="danger" onClick={() => {
                  sessionStorage.removeItem('shouzhangtoken');
                  sessionStorage.removeItem('shouzhangusername');
                  sessionStorage.removeItem('shouzhangrole');
                  window.location.href = '/';
                }}>登出</Button>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentLanguage: state.currentLanguage,
    username: state.username,
    token: state.token,
    role: state.role,
  };
}
  
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          role: role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
    setLanguage: (lg) => {
      dispatch({ type: 'SET_LANGUAGE', payload: lg })
    },
  };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
