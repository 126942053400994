import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';
import Login from './Page/Login';
import Admin from './Page/Admin';
import Agent from './Page/Agent';
import AgentList from './Page/AgentList';
import ClientList from './Page/ClientList';
import PaymentList from './Page/PaymentList';
import ActionList from './Page/ActionList';
import ObsSimplifiedPaymentList from './Page/ObsSimplifiedPaymentList';
import SimplifiedPaymentList from './Page/SimplifiedPaymentList';
import HistoryPage from './Page/HistoryPage';
import AgentPaymentList from './Page/AgentPaymentList';
import Temp from './Page/Temp';
import Obs from './Page/Obs';
import ObsClientList from './Page/ObsClientList';
import PlayerConfirm from './Page/PlayerConfirm';
import ObsPaymentList from './Page/ObsPaymentList';
import ObsActionList from './Page/ObsActionList';
import 'bootstrap/dist/css/bootstrap.min.css';
import PlayerPage from './Page/PlayerPage';
import ChangePass from './Page/ChangePass'
import Reducers from './reducers/reducer';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
// import Header from './Component/Header'
// import Moment from 'moment-timezone';
function App() {
  const middlewares = applyMiddleware(thunk);
 
  const store = createStore(
    Reducers, {
      username: sessionStorage.getItem('shouzhangusername') || '',
      userID: sessionStorage.getItem('shouzhanguserID') || '',
      role: sessionStorage.getItem('shouzhangrole') || '',
      token: sessionStorage.getItem('shouzhangtoken') || '',
      currentLanguage: sessionStorage.getItem('currentLanguage') || 'cn',
    },
    middlewares,
  )
  return (
    <Provider store={store}>
      <Router style={{ position: 'relative' }}>
        {/* <Header/> */}
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/Agent" component={Agent} />
          <Route exact path="/Temp" component={Temp} />
          <Route exact path="/Admin" component={Admin} />
          <Route exact path="/AgentList" component={AgentList} />
          <Route exact path="/ClientList" component={ClientList} />
          <Route exact path="/PlayerConfirm" component={PlayerConfirm} />
          <Route exact path="/PaymentList" component={PaymentList} />
          <Route exact path="/ActionList" component={ActionList} />
          <Route exact path="/SimplifiedPaymentList" component={SimplifiedPaymentList} />
          <Route exact path="/HistoryPage" component={HistoryPage} />
          <Route exact path="/Obs" component={Obs} />
          <Route exact path="/ObsClientList" component={ObsClientList} />
          <Route exact path="/ObsPaymentList" component={ObsPaymentList} />
          <Route exact path="/ChangePass" component={ChangePass} />
          <Route exact path="/ObsActionList" component={ObsActionList} />
          <Route exact path="/ObsSimplifiedPaymentList" component={ObsSimplifiedPaymentList} />
          <Route exact path="/AgentPaymentList" component={AgentPaymentList} />
          <Route exact path="/PlayerPage" component={PlayerPage} />
        </Switch>
      </Router>
    </Provider>
  );
}
export default App;
