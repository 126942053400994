import '../App.css';
import React from 'react';
import { connect } from 'react-redux';
// import moment from 'moment-timezone';
// import { language } from '../language';
import SuccessLogo from '../Image/Success1.gif';
import { Button, Modal} from 'react-bootstrap';
import { updateRemarkFNC } from '../Api'
var timer;

class RemarkModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Remarks: '',
      Message: '',
      ItemsID: '',
    }
  }

  componentDidMount() {
    console.log(this.props.username, this.props.token)
    const Remark = this.props.DisplayItems
    const RamarkType = this.props.RamarkType
    this.setState({ Remarks: (RamarkType === 'Acc') ? Remark.AccRemark : Remark.Remark, ItemsID: Remark.ID })
  }

  async SubmitRemark() {
    const Remarks = this.state.Remarks
    const UpdateRemark = await updateRemarkFNC(this.state.ItemsID, Remarks, this.props.RamarkType, this.props.username, this.props.token)
    if (UpdateRemark.message === 'Update Success') {
      this.DisplayMEssage('Successful Add Remark')
    }
  }

  DisplayMEssage(Message) {
    this.setState({ Message: Message })
    clearTimeout(timer)
    timer = setTimeout(() => {
      this.setState({ 
        Message: '',
      }, () => {
        this.props.handleClose()
      });
    }, 1000);
  }

  render() {
    return (
      <Modal centered show={this.props.RemarkModal} size="lg" onHide={this.props.handleClose} style={{ fontSize: '12px'}}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {'Remark'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.state.Message !== '' && 
          <div className='dcc' style={{ display: 'flex', flexDirection: 'row', fontSize: '20px' }}><div style={{ color: '#77B43F', fontWeight: 'bold'}}>{this.state.Message}</div><img src={SuccessLogo} alt="Logo" style={{ width: 60, marginRight: 5 }}/></div>}
          <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" style={{ marginBottom: 10 }} value={this.state.Remarks} onChange={(e) => this.setState({ Remarks: e.target.value })}></textarea>
          {this.props.RamarkType === 'Acc' && 
          <>
            {(this.props.role === 'admin' || this.props.role === 'account' || this.props.role === 'obs') && <Button variant="primary" style={{ marginRight: 10 }} onClick={() => this.SubmitRemark()}>{'Submit'}</Button>}
          </>
          }
          {this.props.RamarkType === '' && 
          <>
            <Button variant="primary" style={{ marginRight: 10 }} onClick={() => this.SubmitRemark()}>{'Submit'}</Button>
          </>
          }
          <Button variant="danger" onClick={this.props.handleClose}>{'Close'}</Button>
        </Modal.Body>
      </Modal>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.login,
    username: state.username,
    token: state.token,
    role: state.role,
    currentLanguage: state.currentLanguage,
  };
}
    
function mapDispatchToProps(dispatch) {
  return {
    setLoginStatus: (Username, UserToken, Role) => {
      dispatch({
        type: 'LOGIN_STATUS',
        payload: {
          username: Username,
          token: UserToken,
          Role: Role,
        },
      });
    },
    switchProject: () => {
      dispatch({ type: 'SWITCH_PROJECT' });
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RemarkModal);
